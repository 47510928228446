import React, { useEffect, useRef, useState } from 'react'
import { Menu as Dropdown, Transition } from "@headlessui/react"
import { BurgerMenuIcon, CloseIcon, DownArrowIcon, LeftArrowIcon, LogoNavBarIcon } from "@/Components/Icons"
import { InertiaLink, usePage } from "@inertiajs/inertia-react"
import { Inertia } from '@inertiajs/inertia'
import Button from "@/Components/UI/Button"
import TextInput from "@/Components/Form/TextInput"
import CheckboxInput from "@/Components/Form/CheckboxInput"
import Modal from "react-modal"
import { error, hasErrors } from "@/utils"
import Img from "@/Components/UI/Img"
import AvatarIcon from "@/assets/avatar_icon.png"
import AvatarIcon2x from "@/assets/avatar_icon@2x.png"
import PasswordInput from "@/Components/Form/PasswordInput"
import { useWindowSize } from "@/Hooks/useWindowSize"
import ActionLink from '@/Components/UI/ActionLink'
import SearchVibesetsInput from '@/Components/SearchVibesetsInput'

Modal.setAppElement('#app')

const Menu = () => {
    const {auth, errors} = usePage().props
    const loginPopup = useRef()
    const loginRegisterButton = useRef()
    const windowSize = useWindowSize()
    const [isLoginPopupOpen, setIsLoginPopupOpen] = useState(false)
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
    const [isMobileMenuLoginFormOpen, setIsMobileMenuLoginFormOpen] = useState(false)
    const [values, setValues] = useState({
        email: '',
        password: '',
        remember: true,
    })

    const handleChange = (e) => {
        const key = e.target.id
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value
        setValues(values => ({
            ...values,
            [key]: value,
        }))
    }

    const handleSubmit = e => {
        e.preventDefault()
        Inertia.post('/login', values, {
            preserveState: true, headers: {
                'from': 'login_menu',
            },
            onSuccess: (page) => {
                window.analytics?.identify(page?.props?.auth?.user.email)
            },
        })
    }

    useEffect(() => {
        // Close login popup and mobile menu on visits
        Inertia.on('before', () => {
            setIsLoginPopupOpen(false)
            setIsMobileMenuOpen(false)
            setIsMobileMenuLoginFormOpen(false)
        })

        // Handle click events
        window.addEventListener('click', handleClick)

        // Handle key press events
        window.addEventListener('keydown', handleKeyPressed)

        // Remove event listeners
        return () => {
            window.removeEventListener('keydown', handleKeyPressed)
            window.removeEventListener('click', handleClick)
        }
    }, [])

    // Open popup if there are errors, close it otherwise
    useEffect(() => {
        let isMounted = true
        if (isMounted) {
            // No errors, reset the form
            if (!hasErrors(errors, 'login_menu')) {
                setValues({
                    email: '',
                    password: '',
                    remember: true,
                })
            } else { // There are errors, open desktop popup or mobile / laptop menu + login form
                const isUserOnTablet = window.innerWidth < 1170
                const isUserOnMobile = window.innerWidth < 640
                setIsLoginPopupOpen(!isUserOnTablet)
                setIsMobileMenuOpen(isUserOnTablet)
                setIsMobileMenuLoginFormOpen(isUserOnMobile)
            }
        }

        return () => {
            isMounted = false
        }
    }, [errors])

    // Close loginPopup when user press escape key
    const handleKeyPressed = (e) => {
        // e.keyCode is deprecated but keeping it for old browsers just in case
        if ((e.key && e.key === 27) || (e.keyCode && e.keyCode === 27)) {
            setIsLoginPopupOpen(false)
        }
    }

    // Close loginPopup if click is outside of it
    const handleClick = (e) => {
        if (
            loginPopup.current &&
            loginRegisterButton.current &&
            !e.target.isEqualNode(loginPopup.current) &&
            !e.target.isEqualNode(loginRegisterButton.current) &&
            !loginPopup.current.contains(e.target) &&
            !loginRegisterButton.current.contains(e.target)
        ) {
            setIsLoginPopupOpen(false)
        }
    }

    const MenuLink = ({href, children}) => (
        <InertiaLink href={href}
                     className={`text-base hover:text-black mr-10 mb-7.5 navbar:mb-0 ${typeof window !== 'undefined' ? window.location?.pathname === href ? 'text-black' : 'text-gray-dark' : ''}`}>
            {children}
        </InertiaLink>
    )

    const Links = () => (
        <>
            <MenuLink href="/#browse">Browse rooms</MenuLink>
            <MenuLink href="/how-it-works">How it works</MenuLink>
            <MenuLink href="/about">About us</MenuLink>
            <MenuLink href="/get-in-touch">Get in touch</MenuLink>
        </>
    )

    const LoginForm = () => (
        <form className="flex flex-col laptop:flex-row" onSubmit={handleSubmit}>
            <div
                className="laptop:px-7.5 laptop:border-r-2 laptop:border-l-2 border-gray-light navbar:border-white navbar:border-l-0 navbar:pl-0 navbar:pr-4 pb-5 mb-5 laptop:mb-0 laptop:pb-0 border-b-2 laptop:border-b-0">
                <TextInput
                    key="login-email-input"
                    name="email"
                    type="email"
                    className="mb-2.5 w-62.5"
                    placeholder="Email address*"
                    error={error(errors, 'email', 'login_menu') || error(errors, 'is_email_confirmed', 'login_menu')}
                    onChange={handleChange}
                    value={values.email}
                />
                <PasswordInput
                    name="password"
                    placeholder="Password*"
                    className="mb-2.5 w-62.5"
                    error={error(errors, 'password', 'login_menu')}
                    onChange={handleChange}
                    value={values.password}
                />
                <InertiaLink
                    href="/forgot-password"
                    className="text-sm underline text-black hover:text-teal mb-5 inline-block">
                    Forgotten your password?
                </InertiaLink>
                <CheckboxInput
                    name="remember"
                    label="Remember me"
                    className="mb-5"
                    onChange={handleChange}
                    checked={values.remember}
                />
                <Button type="secondary">Login</Button>
            </div>
            <div className="laptop:pl-4 mb-7.5 laptop:mb-0">
                <p className="text-black leading-normal mb-4 whitespace-nowrap">Don’t have
                    an account?</p>
                <Button as="link" type="secondary" href="/register" preserveState={false}>Register</Button>
            </div>
        </form>
    )

    const AuthenticatedUserMenuItems = () => (
        <Dropdown.Items static={true} as={React.Fragment}>
            <div
                className="z-10 origin-top absolute w-36 border border-black mt-2 left-0 top-full focus:outline-none focus:ring-0">
                <div className="bg-white ring-1 ring-black ring-opacity-5 text-left">
                    <div>
                        <Dropdown.Item>
                            {({active}) => (
                                <InertiaLink
                                    href={windowSize.width > 1023 ? '/account/orders' : '/account'}
                                    className={`${active ? 'bg-gray-200 text-gray-900' : 'text-gray-700'} block w-full text-left px-4 py-2 text-sm leading-5 hover:bg-gray-200 hover:text-gray-900 focus:outline-none focus:bg-gray-200 focus:text-gray-900`}
                                    role="menuitem"
                                >
                                    My account
                                </InertiaLink>
                            )}
                        </Dropdown.Item>
                    </div>
                    <div className="border-t border-gray-100"/>
                    <div>
                        <Dropdown.Item>
                            {({active}) => (
                                <InertiaLink
                                    href="/logout"
                                    method="post"
                                    as="button"
                                    className={`${active ? 'bg-gray-200 text-gray-900' : 'text-gray-700'} block w-full text-left px-4 py-2 text-sm leading-5 hover:bg-gray-200 hover:text-gray-900 focus:outline-none focus:bg-gray-200 focus:text-gray-900`}
                                    role="menuitem"
                                >
                                    Sign out
                                </InertiaLink>
                            )}
                        </Dropdown.Item>
                    </div>
                </div>
            </div>
        </Dropdown.Items>
    )

    const AuthenticatedUserInfo = () => (
        <div className="relative">
            <Dropdown>
                {({open}) => (
                    <>
                        <Dropdown.Button
                            className="group w-auto rounded-md px-3.5 py-2 text-sm leading-5 font-medium text-gray-700 hover:bg-gray-200 hover:text-gray-500 focus:outline-none focus:bg-gray-200 focus:ring-0 active:bg-gray-200 active:text-gray-800 transition ease-in-out duration-150"
                            id="options-menu" aria-haspopup="true" aria-expanded="true">
                            <div className="flex items-center">
                                <Img
                                    src={AvatarIcon}
                                    x2={AvatarIcon2x}
                                    alt="avatar icon"
                                    className="inline-block mr-2.5"
                                />
                                <span
                                    className="text-base font-bold text-teal mr-2 hidden navbar:block">Hi, {auth.user?.first_name}</span>
                                <DownArrowIcon className="text-teal stroke-current w-4 hidden navbar:block"/>
                                <span className="text-base font-bold text-teal mr-2 navbar:hidden">Account</span>
                            </div>
                        </Dropdown.Button>
                        <Transition
                            show={open}
                            enter="transition duration-100 ease-out"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition duration-75 ease-in"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <AuthenticatedUserMenuItems/>
                        </Transition>
                    </>
                )}
            </Dropdown>
        </div>
    )

    return (
        <div id="nav" className="relative max-w-screen-desktop mx-auto w-full sticky top-0 bg-white z-50 px-4 laptop:px-8">
            <nav className="flex items-center border-b border-gray-light h-12 laptop:h-17">
                <InertiaLink href="/">
                    <LogoNavBarIcon className="w-32 h-4.5 laptop:h-auto laptop:w-auto"/>
                </InertiaLink>
                {/*Desktop menu*/}
                <div className="ml-14 hidden navbar:block">
                    <Links/>
                </div>
                <div className="ml-auto items-center hidden navbar:flex">
                    <SearchVibesetsInput/>
                    {auth.user ? (
                        <>
                            {/*Logged in user info*/}
                            <AuthenticatedUserInfo/>
                        </>
                    ) : (
                        <>
                            {/*Register / login popover*/}
                            <div className="relative">
                                <Button type="secondary" onClick={() => setIsLoginPopupOpen(!isLoginPopupOpen)}
                                        ref={loginRegisterButton}>
                                    Register / login
                                </Button>
                                {isLoginPopupOpen && (
                                    <>
                                        <div className="absolute w-0 h-0 border-solid" style={{
                                            top: 45,
                                            right: 50,
                                            borderWidth: '0 20px 20px 20px',
                                            borderColor: 'transparent transparent #ededed transparent',
                                        }}/>
                                        <div className="z-10 absolute right-0 bg-gray-light p-4"
                                             style={{top: 62, boxShadow: '0px 10px 14px #5C5C5C33'}}
                                             ref={loginPopup}>
                                            {LoginForm()}
                                        </div>
                                    </>
                                )}
                            </div>
                        </>
                    )}
                </div>

                {/*Mobile menu burger icon*/}
                <div className="ml-auto navbar:hidden flex items-center">
                    {auth.user && (
                        <AuthenticatedUserInfo/>
                    )}
                    <button
                        className="focus:ring-0 focus:outline-none text-gray-dark hover:text-black ml-5.5"
                        onClick={() => setIsMobileMenuOpen(true)}
                    >
                        <BurgerMenuIcon className="fill-current"/>
                    </button>
                </div>

                {/*Mobile menu*/}
                <Modal
                    id="mobile-menu-modal"
                    overlayClassName="items-start"
                    isOpen={isMobileMenuOpen}
                    onRequestClose={() => setIsMobileMenuOpen(false)}
                    contentLabel="Mobile menu"
                >
                    <div className="h-full px-4 laptop:px-8 flex flex-col">
                        <nav
                            className="flex items-center border-b border-gray-light h-12 laptop:h-17 mb-5 navbar:mb-7.5">
                            <InertiaLink href="/">
                                <LogoNavBarIcon className="w-32 h-4.5 laptop:h-auto laptop:w-min"/>
                            </InertiaLink>

                            <div className="ml-auto flex items-center">
                                {auth.user && (
                                    <AuthenticatedUserInfo/>
                                )}
                                <button
                                    className="focus:ring-0 focus:outline-none text-gray-dark hover:text-black ml-5.5"
                                    onClick={() => {
                                        setIsMobileMenuOpen(false)
                                        setIsMobileMenuLoginFormOpen(false)
                                    }}
                                >
                                    <CloseIcon className="fill-current w-4.5 h-4.5"/>
                                </button>
                            </div>
                        </nav>

                        {/* Login form*/}
                        {isMobileMenuLoginFormOpen ? (
                            <>
                                <ActionLink
                                    as="button"
                                    className="mb-5"
                                    onClick={() => setIsMobileMenuLoginFormOpen(false)}
                                >
                                    <LeftArrowIcon className="mr-2 stroke-current"/>
                                    Back to menu
                                </ActionLink>
                                {LoginForm()}
                            </>
                        ) : (
                            <>
                                <div className="flex">
                                    <div className="w-full tablet:w-1/2 laptop:w-1/3">
                                        <div className="flex flex-col">
                                            <Links/>
                                        </div>
                                        <div className="mt-auto">
                                            <div className="mb-7.5">
                                                <SearchVibesetsInput/>
                                            </div>
                                            <div className="mb-7.5 tablet:hidden">
                                                {!auth.user && (
                                                    <Button
                                                        type="secondary"
                                                        onClick={() => setIsMobileMenuLoginFormOpen(!isMobileMenuLoginFormOpen)}
                                                    >
                                                        Register / login
                                                    </Button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    {!auth.user && (
                                        <div className="hidden tablet:block tablet:w-1/2 laptop:w-1/3">
                                            {LoginForm()}
                                        </div>
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                </Modal>
            </nav>
        </div>
    )
}

export default Menu
