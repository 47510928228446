import React, { forwardRef } from 'react'

const ButtonIcon = forwardRef(({children, className, ...props}, ref) => {
    return (
        <button
            ref={ref}
            {...props}
            className={`flex items-center rounded text-gold hover:text-gold-dark focus-visible:border-gold focus-visible:ring-gold focus-visible:hover:ring-gold-dark focus-visible:ring focus:ring-0 focus:outline-none ${className}`}
        >
            {children}
        </button>
    )
})

export default ButtonIcon
