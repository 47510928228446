import React, { forwardRef } from 'react'
import { getButtonStyles } from "@/Components/UI/Button"

const ButtonGold = forwardRef(({children, className, ...props}, ref) => {
    return (
        <button
            ref={ref}
            {...props}
            className={`${getButtonStyles()} text-white bg-gold hover:bg-gold-dark px-2.5 text-sm h-8 disabled:bg-gold focus:!ring-gold focus:hover:!ring-gold-dark ${className}`}
        >
            {children}
        </button>
    )
})

export default ButtonGold
