import React from 'react'
import Layout from "@/Components/Layouts/Layout"
import H1 from "@/Components/UI/H1"
import Button from "@/Components/UI/Button"
import { Head } from "@inertiajs/inertia-react"

const Error = ({status}) => {
    const title = {
        503: 'Service Unavailable',
        500: 'Server Error',
        404: 'Page Not Found',
        403: 'Forbidden',
    }[status]

    const description = {
        503: 'Sorry, we are doing some maintenance. Please check back soon.',
        500: 'Whoops, something went wrong on our servers.',
        404: 'Sorry, looks like this page doesn’t exist.<br/> But don’t worry, you can find lots of stylish things on our homepage.',
        403: 'Sorry, you are forbidden from accessing this page.',
    }[status]

    return (
        <div className="">
            <Head title={title}/>
            <div className="mt-37 mb-48 text-center">
                <H1 className="mb-10">{status}</H1>
                <p className="text-base mb-10" dangerouslySetInnerHTML={{__html: description}}/>
                <Button as="link" href="/">Back to home</Button>
            </div>
        </div>
    )
}

Error.layout = page => <Layout children={page}/>

export default Error
