import React, { useState } from 'react'
import Layout from "@/Components/Layouts/Layout"
import { usePage } from "@inertiajs/inertia-react"
import { loadStripe } from "@stripe/stripe-js"
import VibesetBill from '@/Components/Vibeset/VibesetBill/VibesetBill'
import FormError from '@/Components/Form/FormError'
import Button from '@/Components/UI/Button'
import { StripeIcon } from '@/Components/Icons'
import { getRemainingPaymentCheckoutSession } from '@/api'
import Accordion from "@/Components/Accordion"

const stripePromise = loadStripe(process.env.MIX_STRIPE_PUBLIC_KEY)

const PayRemainingCheckout = () => {
    const {order, site_settings} = usePage().props
    const [internalError, setInternalError] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            const session_id = await getRemainingPaymentCheckoutSession(
                {
                    'order_number': order.number,
                })
            const stripe = await stripePromise
            const result = await stripe.redirectToCheckout({
                sessionId: session_id,
            })
            if (result.error) {
                console.error(result.error.message)
            }
        } catch (e) {
            setInternalError(true)
        }
    }

    const renderDownPaymentTitle = () => (
        <div className="flex items-center font-bold w-full text-base text-gray-dark">
                        <span className="w-1/2">
                            Down payment
                        </span>
            <span className="ml-4">{order.vibeset.currency} {order.old_prices['partial_cost_paid']}</span>
        </div>
    )

    const renderAfterValidationTitle = () => (
        <div className="flex items-center font-bold w-full text-base">
                        <span className="w-1/2">
                            After validation
                        </span>
            <span className="ml-4">{order.vibeset.currency} {order.remaining_cost_incl_vat}</span>
        </div>
    )

    return (
        <div className="flex flex-col navbar:flex-row">
            <div className="flex-1 mt-5 tablet:mt-10 laptop:px-15 mb-8">
                <div className="w-full">
                    <div className="max-w-131">
                        <h1 className="font-bold text-2xl mb-4">Payment</h1>
                        <p className="text-base mb-10">Here is a breakdown of your payment:</p>
                        <div className="text-base max-w-[360px]">
                            <div className="flex relative">
                                <span className="absolute h-full w-0.5 bg-gray-dark left-[13px] top-6.5"/>
                                <div className="relative mr-2.5">
                                    <span
                                        className="flex items-center justify-center w-6.5 h-6.5 bg-gray-dark text-white font-bold rounded-full">1</span>
                                </div>
                                <Accordion className="w-full">
                                    <Accordion.Item title={renderDownPaymentTitle()} opened={true} className="mb-7">
                                        <div className="-mt-4 text-sm text-gray-dark">
                                            <div
                                                className="mb-2.5">{Math.round(site_settings.partial_cost_upfront_percentage * 100)}% of selected items
                                            </div>
                                        </div>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                            <div className="flex">
                                <div className="relative mr-2.5">
                                    <span
                                        className="flex items-center justify-center w-6.5 h-6.5 bg-gray-dark text-white font-bold rounded-full">2</span>
                                </div>
                                <Accordion className="w-full">
                                    <Accordion.Item title={renderAfterValidationTitle()} opened={true}>
                                        <div className="-mt-4 text-sm">
                                            <div
                                                className="mb-2.5">{100 - Math.round(site_settings.partial_cost_upfront_percentage * 100)}% of selected items and delivery fees
                                            </div>
                                            <div className="mb-2.5 flex items-center w-[calc(100%-33px)]">
                                                <span className="w-1/2">Delivery fees</span>
                                                <span className="ml-4">{order.vibeset.currency} {order.total_delivery_fees}</span>
                                            </div>
                                            <div className="mb-2.5 flex items-center w-[calc(100%-33px)]">
                                                <span className="w-1/2">VAT</span>
                                                <span className="ml-4">{order.vibeset.currency} {order.vat}</span>
                                            </div>
                                            <div className="mb-2.5 flex items-center w-[calc(100%-33px)]">
                                                <span className="w-1/2">Total</span>
                                                <span
                                                    className="ml-4">{order.vibeset.currency} {order.total_cost_incl_vat}</span>
                                            </div>
                                            <span
                                                className="mb-2.5 inline-block bg-teal-dark font-semibold text-xs px-2 py-1.5 text-white">DUE NOW</span>
                                        </div>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>

                        <p className="text-base mt-4 mb-15">
                            Delivery fees include delivering all the ordered items from all the different suppliers.
                        </p>

                        <form onSubmit={handleSubmit}>
                            {internalError && (
                                <div className="mb-4">
                                    <FormError>An internal error has occurred. Please try again later.</FormError>
                                </div>
                            )}

                            <div
                                className="flex items-center flex-wrap mb-4">
                                <Button
                                    className="mb-4 tablet:mb-0"
                                    disabled={order.remaining_cost_incl_vat <= 0}>Pay {order.vibeset.currency} {order.remaining_cost_incl_vat}</Button>
                            </div>
                            <div className="flex items-center text-gray-dark text-sm">
                                <span>Powered by</span>
                                <StripeIcon className="w-12"/>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            {/*Bill*/}
            <div className="hidden navbar:block w-full max-w-[389px] flex-none">
                <div className="sticky top-[70px] h-[calc(100vh-70px)] border-l-2 border-gray-medium">
                    <VibesetBill
                        products={order.products}
                        is_summary={true}
                        is_read_only={true}
                    />
                </div>
            </div>
        </div>
    )
}

PayRemainingCheckout.layout = page => <Layout children={page} showFooter={false}/>

export default PayRemainingCheckout
