import React, { useState } from 'react'
import Layout from "@/Components/Layouts/Layout"
import AccountLayout from "@/Components/Layouts/AccountLayout"
import ProfileForm from "@/Components/Account/ProfileForm"
import ResetPasswordForm from '@/Components/Account/ResetPasswordForm'
import DeliveryAddressForm from '@/Components/Account/DeliveryAddressForm'
import Link from '@/Components/UI/Link'
import SmallModal from '@/Components/Modals/SmallModal'
import Button from '@/Components/UI/Button'
import ActionLink from '@/Components/UI/ActionLink'
import { Head, useForm, usePage } from '@inertiajs/inertia-react';
import { error } from '@/utils'
import { delete_account_reasons } from '@/constants'
import SelectInput from '@/Components/Form/SelectInput'
import PasswordInput from '@/Components/Form/PasswordInput'
import CheckboxInput from '@/Components/Form/CheckboxInput'

const Profile = () => {
    const {can_user_delete_account, auth} = usePage().props
    const [showOrdersWarningModal, setShowOrdersWarningModal] = useState(false)
    const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false)

    const {data, setData, post, errors} = useForm({
        user_id: auth.user.id,
        reason: '',
        password: '',
        terms: false,
    })

    const handleDeleteAccount = () => {
        if (!can_user_delete_account) {
            return setShowOrdersWarningModal(true)
        }

        setShowDeleteAccountModal(true)
    }

    const handleSubmitDeleteAccount = (e) => {
        e.preventDefault()
        post('/account/profile/delete-account')
    }

    return (
        <AccountLayout>
            <Head>
                <title>Profile</title>
            </Head>
            <ProfileForm/>
            <DeliveryAddressForm/>
            <ResetPasswordForm/>

            <Link as="button" onClick={handleDeleteAccount}>I want to delete my account</Link>

            {/*Delete account modal*/}
            <SmallModal
                isModalOpen={showDeleteAccountModal}
                closeModal={() => setShowDeleteAccountModal(false)}
            >
                <form onSubmit={handleSubmitDeleteAccount}>
                    <div className="mb-5 text-base font-bold">
                        Sorry to see you go
                    </div>
                    <p className="mb-6">Please note that once your account has been deleted, all your saved vibesets and
                        favourites will also
                        be deleted. <br/><br/>
                        You will need to create a new account if you wish to use our service again.</p>

                    <SelectInput
                        label="Reason for deleting account*"
                        name="reason"
                        className="w-full max-w-76 mb-6"
                        value={data.reason}
                        error={error(errors, 'reason')}
                        onChange={(e) => setData('reason', e.target.value)}
                        required
                    >
                        <option value="" disabled hidden>Select one</option>
                        {delete_account_reasons.map((reason, index) => (
                            <option value={reason} key={`order-cancel-reason-${index}`}>{reason}</option>
                        ))}
                    </SelectInput>

                    <PasswordInput
                        name="password"
                        label="Enter your password*"
                        error={error(errors, 'password')}
                        className="w-full max-w-76 mb-4"
                        value={data.password}
                        onChange={(e) => setData('password', e.target.value)}
                    />

                    <CheckboxInput
                        name="terms"
                        checked={data.terms}
                        className="mb-7.5"
                        label={<span>I have read and agreed to the <a href="/terms"
                                                                      className="inline-block text-sm underline text-black hover:text-teal"
                                                                      target="_blank">terms and conditions</a></span>}
                        onChange={() => setData('terms', !data.terms)}
                        error={error(errors, 'terms')}
                    />

                    {/*Error if user has active orders*/}
                    {error(errors, 'user_id')}

                    <div className="flex items-center justify-end -mb-4">
                        <ActionLink as="button" onClick={() => setShowDeleteAccountModal(false)}
                                    className="mr-7.5 font-bold mb-4">Cancel</ActionLink>
                        <Button onClick={handleSubmitDeleteAccount} className="mb-4">Delete account</Button>
                    </div>
                </form>
            </SmallModal>

            {/*User has active orders modal*/}
            <SmallModal
                isModalOpen={showOrdersWarningModal}
                closeModal={() => setShowOrdersWarningModal(false)}
            >
                <p className="mb-7.5 text-base">
                    Sorry, you cannot delete your account as you have one or more orders in progress.
                </p>
                <div className="flex items-center justify-end">
                    <Button onClick={() => setShowOrdersWarningModal(false)}>Got it</Button>
                </div>
            </SmallModal>
        </AccountLayout>
    )
}

Profile.layout = page => <Layout children={page}/>

export default Profile
