import React, { useEffect, useState } from 'react'
import { Head } from '@inertiajs/inertia-react'
import Footer from '@/Components/Footer'
import Menu from "@/Components/Menu"
import CookieNotice from "@/Components/CookieNotice"
import { AppContext, AppContextInitialValue } from "@/Contexts/app-context"
import OperateInDubai from "@/Components/OperateInDubai"
import { usePage } from "@inertiajs/inertia-react"

const Layout = ({children, showFooter = true}) => {
    const [appContext, setAppContext] = useState(AppContextInitialValue)
    const { auth } = usePage().props

    useEffect(() => {
        if(!auth.user){
            window.analytics?.reset()
        }
    }, [auth.user])

    useEffect(() => {
        window.analytics?.page(window.location?.pathname)
    })

    let canonicalLink = ''
    if (typeof window !== "undefined") {
        canonicalLink = window.location?.origin + window.location?.pathname
    }

    return (
        <AppContext.Provider value={{appContext, setAppContext}}>
            <div className="flex flex-col min-h-screen">
                <Head>
                    <link rel="canonical" href={canonicalLink}/>
                </Head>
                <OperateInDubai/>
                <Menu/>
                <main
                    className={`relative max-w-screen-desktop mx-auto w-full px-4 tablet:px-8 ${showFooter ? 'pb-4 tablet:pb-8' : ''}`}>
                    {children}
                </main>
                <div
                    className={`relative max-w-screen-desktop mx-auto w-full px-4 mt-auto tablet:px-8 ${showFooter ? 'pb-4 tablet:pb-8' : ''}`}>
                    {showFooter && <Footer/>}
                    <CookieNotice/>
                </div>
            </div>
        </AppContext.Provider>
    )
}

export default Layout
