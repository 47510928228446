import React, { useContext, useState } from 'react'
import Layout from "@/Components/Layouts/Layout"
import AccountLayout from "@/Components/Layouts/AccountLayout"
import { Head, usePage } from "@inertiajs/inertia-react"
import Img from "@/Components/UI/Img"
import Link from "@/Components/UI/Link"
import BillSummaryModal from "@/Components/Vibeset/Modals/BillSummaryModal"
import Button from "@/Components/UI/Button"
import { AppContext } from "@/Contexts/app-context"
import SmallModal from "@/Components/Modals/SmallModal"

const Orders = () => {
    const {orders, flash} = usePage().props
    const {appContext, setAppContext} = useContext(AppContext)
    const [billSummaryVibeset, setBillSummaryVibeset] = useState(null)
    const [isCancelOrderStatusModalOpened, setCancelOrderStatusModalOpened] = useState(Boolean(flash.success))

    const handleViewBillSummary = (order) => {
        setBillSummaryVibeset(order)
        setAppContext({
            ...appContext,
            isMobileBillModalOpened: true,
        })
    }

    const handleCloseBillSummary = () => {
        setAppContext({
            ...appContext,
            isMobileBillModalOpened: false,
        })
        setBillSummaryVibeset(null)
    }

    const getColorByStatus = (status) => {
        return {
            'Placed': 'text-teal',
            'Pending validation': 'text-gold-dark',
            'Pending payment': 'text-red',
            'Missing items': 'text-red',
            'In progress': 'text-teal',
            'Completed': 'text-teal',
            'Cancelled': 'text-red',
        }[status] || 'text-teal'
    }

    return (
        <AccountLayout>
            <Head>
                <title>Orders</title>
            </Head>
            <h1 className="font-bold text-xl text-teal leading-normal mb-2.5">My orders</h1>
            {orders.length === 0 ? (
                <div className="text-center laptop:mt-28 mt-24">
                    <p className="text-gray-dark mb-7.5">You haven't ordered any vibesets yet.</p>
                    <Button as="link" href="/#browse">Search vibesets</Button>
                </div>
            ) : (
                <>
                    {orders.map((order, index) => {
                        const showDispatchTime = order.status === 'Placed' || order.status === 'Pending validation' || order.status === 'Missing items' || order.status === 'In progress' || order.status === 'Pending payment'

                        return (
                            <div
                                key={`order-${index}`}
                                className="bg-gray-light px-4 pt-4 pb-2 mb-2.5"
                                style={{
                                    maxWidth: 858,
                                }}
                            >
                                <div className="flex flex-col tablet:flex-row">

                                    <Img
                                        src={order.vibeset.thumbnail.src}
                                        alt={order.vibeset.thumbnail.alt}
                                        className="w-full tablet:w-44 mb-4 tablet:mb-0 flex-shrink-0"
                                    />
                                    <div className="tablet:ml-7.5 tablet:mr-4 tablet:mb-0 flex-1">
                                        <div
                                            className="flex flex-col items-start justify-start tablet:flex-row tablet:items-start tablet:justify-between tablet:mb-7.5 mb-4">
                                            <div className="flex-1 mb-2.5 tablet:mb-0 mr-4">
                                                <div
                                                    className="font-bold text-base mb-1">{order.vibeset.room?.name || 'Accessory set'} - {order.vibeset.name}</div>
                                                {showDispatchTime && (
                                                    <p className="text-sm">Dispatch time: up
                                                        to {order.max_total_dispatch_time} days</p>
                                                )}
                                            </div>
                                            <Link
                                                as="button"
                                                className="text-base inline-block flex-shrink-0"
                                                onClick={() => handleViewBillSummary(order)}
                                            >
                                                View bill summary
                                            </Link>
                                        </div>
                                        <div className="grid grid-cols-2 laptop:grid-cols-4 gap-2">
                                            <div className="">
                                                <div className="font-bold text-base mb-1.5">Date placed</div>
                                                <div
                                                    className="text-base">{order.created_at}</div>
                                            </div>
                                            <div className="">
                                                <div className="font-bold text-base mb-1.5">Order number</div>
                                                <div
                                                    className="text-base">{order.number}</div>
                                            </div>
                                            <div className="">
                                                <div className="font-bold text-base mb-1.5">Total</div>
                                                <div
                                                    className="text-base">{order.vibeset.currency} {order.total_cost_incl_vat}</div>
                                            </div>
                                            <div className="">
                                                <div className="font-bold text-base mb-1.5">Status</div>
                                                <div
                                                    className={`text-base ${getColorByStatus(order.status)}`}>{order.status}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {order.status === 'Missing items' && (
                                    <div
                                        className="my-2 pt-4 border-t border-t-2 border-white flex flex-col items-start justify-start tablet:flex-row tablet:items-center tablet:justify-between">
                                        <p className="flex-1 w-full tablet:max-w-60% mb-4 tablet:mb-0">
                                            Whilst checking the stock availability of your order, some of the items ran
                                            out of stock.
                                            Our designers worked on alternatives so you can check if they work for you.
                                            If you’re not happy with any of the alternatives, you can remove those
                                            items.
                                        </p>
                                        <Button
                                            as="link"
                                            className="text-base flex-shrink-0"
                                            href={`/order/${order.number}/edit`}
                                        >
                                            View alternatives
                                        </Button>
                                    </div>
                                )}

                                {order.status === 'Pending payment' && (
                                    <div
                                        className="my-2 pt-4 border-t border-t-2 border-white flex flex-col items-start justify-start tablet:flex-row tablet:items-center tablet:justify-between">
                                        <p className="flex-1 w-full tablet:max-w-60% mb-4 tablet:mb-0">
                                            Your order has been validated and is ready to be sealed. Please pay the
                                            remaining amount.
                                        </p>
                                        <Button
                                            as="link"
                                            className="text-base flex-shrink-0"
                                            href={`/order/${order.number}/pay-remaining`}
                                        >
                                            Pay {order.vibeset.currency} {order.remaining_cost_incl_vat}
                                        </Button>
                                    </div>
                                )}
                            </div>
                        )
                    })}
                </>
            )}

            {/*Bill summary*/}
            <BillSummaryModal
                isModalOpen={appContext.isMobileBillModalOpened}
                closeModal={handleCloseBillSummary}
                set={billSummaryVibeset}
            />

            {/*Order cancelled successfully*/}
            <SmallModal
                isModalOpen={isCancelOrderStatusModalOpened}
                closeModal={() => setCancelOrderStatusModalOpened(false)}
            >
                <>
                    <div className="mb-7.5">
                        <div className="text-base">
                            Your order has been cancelled successfully. We will refund any amount paid shortly.
                        </div>
                    </div>
                    <div className="text-right">
                        <Button onClick={() => setCancelOrderStatusModalOpened(false)}>Got it</Button>
                    </div>
                </>
            </SmallModal>
        </AccountLayout>
    )
}

Orders.layout = page => <Layout children={page}/>

export default Orders
