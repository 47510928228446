import React, { useEffect, useRef, useState } from 'react'
import { getParameterByName } from '@/utils'
import { SearchIcon } from '@/Components/Icons'
import { Inertia } from '@inertiajs/inertia'


const SearchVibesetsInput = () => {
    const input = useRef()
    const [query, setQuery] = useState(getParameterByName('q') || '')

    const handleKeyPress = (e) => {
        if(e.code === 'Slash'){
            e.preventDefault()
            input?.current?.focus()
        }
    }

    // Reset query on page change
    useEffect(() => {
        window.addEventListener('keydown', handleKeyPress)
        let isMounted = true

        Inertia.on('start', (event) => {
            if(event?.detail?.visit?.url?.pathname !== '/search' && isMounted){
                setQuery('')
            }
        })

        return () => {
            window.removeEventListener('keydown', handleKeyPress)
            isMounted = false
        }
    }, [])

    const handleChange = (e) => {
        setQuery(e.target.value)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (query.length === 0) {
            return
        }
        Inertia.visit('/search', {
            data: {
                q: query,
            },
        })
    }

    return (
        <form className="relative" onSubmit={handleSubmit}>
            <button className="absolute inset-y-0 left-0 flex items-center z-10 focus:outline-none focus:ring-0" onClick={handleSubmit}>
                <SearchIcon className="pointer-events-none w-6 h-6 text-gray-dark fill-current"/>
            </button>
            <input
                ref={input}
                className="text-base h-auto pl-8 border-0 text-black focus:outline-none focus:ring-0 placeholder-gray-dark py-2 pr-4 block w-full appearance-none"
                type="text"
                placeholder="Search rooms"
                autoComplete="off"
                spellCheck="false"
                role="combobox"
                aria-autocomplete="list"
                aria-expanded="false"
                aria-label="search rooms"
                aria-owns="algolia-autocomplete-listbox-0"
                dir="auto"
                onChange={handleChange}
                value={query}
            />
        </form>
    )
}

export default SearchVibesetsInput
