import React, { useContext, useState } from 'react'
import Img from '@/Components/UI/Img'
import { DEFAULT_USER_DIMENSIONS, getProductPrice, getQuantitySuffix } from '@/Components/Vibeset/utils'
import { AddItemIcon, BillEditIcon, BillRemoveIcon, RemoveItemIcon } from '@/Components/Icons'
import { AppContext } from '@/Contexts/app-context'
import WallpaperModal from '@/Components/Vibeset/Modals/WallpaperModal'
import ButtonIcon from '@/Components/UI/ButtonIcon'
import TextInput from '@/Components/Form/TextInput'
import ButtonGold from '@/Components/UI/ButtonGold'
import WallPaintModal from "@/Components/Vibeset/Modals/WallPaintModal"
import CheckboxInput from "@/Components/Form/CheckboxInput"
import CurtainsModal from "@/Components/Vibeset/Modals/CurtainsModal"
import FloorModal from "@/Components/Vibeset/Modals/FloorModal"

const BillProduct = ({product, is_summary, is_read_only, handleSaveVibeset}) => {
    const {appContext, setAppContext} = useContext(AppContext)
    const [isWallpaperModalOpen, setIsWallpaperModalOpen] = useState(false)
    const [isWallPaintModalOpen, setIsWallPaintModalOpen] = useState(false)
    const [isCurtainsModalOpen, setIsCurtainsModalOpen] = useState(false)
    const [isFloorModalOpen, setIsFloorModalOpen] = useState(false)
    const [quantity, setQuantity] = useState(product.pivot.quantity)
    const [isAssembly, setIsAssembly] = useState(product.pivot.is_assembly)
    const [isEditQuantity, setIsEditQuantity] = useState(false)
    const isWallpaper = product.product_category.name === 'Wallpaper'
    const isWallPaint = product.product_category.name === 'Wall paint'
    const isFloor = product.product_category.name === 'Floors'
    const isCurtainsSoldPerSqm = product.product_category.name === 'Curtains' && product.price_selling_unit === 'sqm'

    const handleEditProduct = () => {
        if (isWallpaper) {
            setIsWallpaperModalOpen(true)
        } else if (isWallPaint) {
            setIsWallPaintModalOpen(true)
        } else if (isCurtainsSoldPerSqm) {
            setIsCurtainsModalOpen(true)
        } else if (isFloor) {
            setIsFloorModalOpen(true)
        } else {
            setQuantity(product.pivot.quantity)
            setIsEditQuantity(true)
        }
    }

    const handleSaveQuantity = () => {
        let products = [...appContext.products]
        products = products.map(p => {
            return p.id === product.id ? {...p, pivot: {...p.pivot, quantity: quantity, is_assembly: isAssembly}} : p
        })

        // Save vibeset on payment page
        if (is_summary && handleSaveVibeset) {
            handleSaveVibeset(products, true)
        } else { // Save products in context
            setAppContext({
                ...appContext,
                products: products,
            })
        }

        setIsEditQuantity(false)
    }

    const handleRemoveProduct = () => {
        let products = [...appContext.products]
        products = products.map(p => {
            return p.id === product.id ? {
                ...p,
                pivot: {...p.pivot, quantity: 0, user_dimensions: {...DEFAULT_USER_DIMENSIONS}, is_assembly: 0},
            } : p
        })

        // Save vibeset on payment page
        if (is_summary && handleSaveVibeset) {
            handleSaveVibeset(products, true)
        } else { // Save products in context
            setAppContext({
                ...appContext,
                products: products,
            })
        }
    }

    const handleToggleAssembly = () => {
        setIsAssembly(isAssembly ? 0 : 1)
    }

    return (
        <>
            <div className="flex items-start py-4">
                <div className="w-28 flex-shrink-0 mr-4">
                    <Img src={product.thumbnail.src} alt={product.thumbnail.alt}/>
                </div>
                <div className="flex-grow text-sm">
                    <div className="flex justify-between font-bold mb-1.5 gap-x-4">
                        <span>{product.name}</span>
                        <span className="whitespace-nowrap">{product.currency} {getProductPrice(product)}</span>
                    </div>
                    <div className="text-gray-dark">{product.product_category.name}</div>
                    <div>
                        {isEditQuantity ? (
                            <div>
                                <div className="flex items-center justify-between gap-x-4 mt-1.5">
                                    <div className="flex items-center">
                                        <ButtonIcon onClick={() => setQuantity(Math.max(quantity - 1, 0))}>
                                            <RemoveItemIcon/>
                                        </ButtonIcon>
                                        <TextInput
                                            type="number"
                                            min={0}
                                            className="w-10 mx-2 appearance-none"
                                            inputClassName="text-center h-8"
                                            value={quantity}
                                            onChange={(e) => setQuantity(Math.max(e.target.value, 0))}
                                        />
                                        <ButtonIcon onClick={() => setQuantity(quantity + 1)}>
                                            <AddItemIcon/>
                                        </ButtonIcon>
                                    </div>
                                    <div className="flex items-center gap-x-5">
                                        <ButtonGold onClick={handleSaveQuantity}>
                                            Save
                                        </ButtonGold>
                                        <ButtonIcon onClick={() => setIsEditQuantity(false)}>
                                            <BillRemoveIcon/>
                                        </ButtonIcon>
                                    </div>
                                </div>

                                {Boolean(product.is_assembly) && (
                                    <div className="flex items-center mt-1.5">
                                        <CheckboxInput
                                            name={`bill-product-assembly-${product.id}`}
                                            checked={isAssembly}
                                            label={`Add assembly (${product.currency} ${product.assembly_price})`}
                                            onChange={handleToggleAssembly}
                                        />
                                    </div>
                                )}
                            </div>
                        ) : (
                            <>
                                <div className="flex items-center justify-between gap-x-4 mt-1.5">
                                    <div className="text-gray-dark">
                                        <div>
                                            Qty: {product.pivot.quantity}
                                            <span
                                                dangerouslySetInnerHTML={{__html: getQuantitySuffix(product.price_selling_unit)}}/>
                                        </div>
                                    </div>
                                    {!is_read_only && (
                                        <div className="flex items-center gap-x-5">
                                            <ButtonIcon onClick={handleEditProduct}>
                                                <BillEditIcon/>
                                            </ButtonIcon>
                                            <ButtonIcon onClick={handleRemoveProduct}>
                                                <BillRemoveIcon/>
                                            </ButtonIcon>
                                        </div>
                                    )}
                                </div>

                                {Boolean(product.pivot.is_assembly) && (
                                    <div className="mt-1.5 text-gray-dark">
                                        Assembly: {product.currency} {product.assembly_price}
                                    </div>
                                )}

                                {product.max_delivery_time > 0 && (
                                    <div className="mt-1.5 text-gray-dark">
                                        Delivery: up to {product.max_delivery_time} day{product.max_delivery_time > 1 ? 's' : ''}
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>

            {isWallpaper && (
                <WallpaperModal
                    isModalOpen={isWallpaperModalOpen}
                    closeModal={() => setIsWallpaperModalOpen(false)}
                    product={product}
                />
            )}

            {isWallPaint && (
                <WallPaintModal
                    isModalOpen={isWallPaintModalOpen}
                    closeModal={() => setIsWallPaintModalOpen(false)}
                    product={product}
                />
            )}

            {isCurtainsSoldPerSqm && (
                <CurtainsModal
                    isModalOpen={isCurtainsModalOpen}
                    closeModal={() => setIsCurtainsModalOpen(false)}
                    product={product}
                />
            )}

            {isFloor && (
                <FloorModal
                    isModalOpen={isFloorModalOpen}
                    closeModal={() => setIsFloorModalOpen(false)}
                    product={product}
                />
            )}

        </>
    )
}

export default BillProduct
