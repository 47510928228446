import React, { useState } from 'react'
import TextInput from "@/Components/Form/TextInput"
import Link from "@/Components/UI/Link"
import H5 from "@/Components/UI/H5"
import ButtonGold from "@/Components/UI/ButtonGold"

const FloorDimensions = ({userDimensions, setUserDimensions}) => {
    const [width, setWidth] = useState('')
    const [length, setLength] = useState('')

    const handleAddFloor = () => {
        setUserDimensions({
            ...userDimensions,
            floors: [
                ...userDimensions.floors,
                {
                    width: Number(width),
                    length: Number(length),
                },
            ],
        })
        setWidth('')
        setLength('')
    }

    const handleRemoveFloor = index => {
        const floorsCopy = userDimensions.floors.slice()
        floorsCopy.splice(index, 1)
        setUserDimensions({
            ...userDimensions,
            floors: floorsCopy,
        })
    }

    return (
        <div className="mb-7.5">
            <H5 className="mb-4">Enter your floor dimensions</H5>
            <p className="text-base mb-5">
                Enter the size of the area that you wish to cover with flooring.
            </p>
            <div className="flex items-end flex-wrap mb-5">
                <TextInput
                    label="Width"
                    type="number"
                    min={1}
                    value={width}
                    className="w-17 mr-8 laptop:mr-13 mb-4"
                    onChange={(e) => {
                        const value = Number(e.target.value) > 0 ? Number(e.target.value) : ''
                        setWidth(value)
                    }}
                    placeholder="cm"
                />
                <TextInput
                    label="Length"
                    type="number"
                    min={1}
                    value={length}
                    className="w-17 mr-8 laptop:mr-13 mb-4"
                    onChange={(e) => {
                        const value = Number(e.target.value) > 0 ? Number(e.target.value) : ''
                        setLength(value)
                    }}
                    placeholder="cm"
                />
                <ButtonGold
                    onClick={handleAddFloor}
                    disabled={Number(width) <= 0 || Number(length) <= 0}
                    className="mb-4"
                >
                    Add floor area
                </ButtonGold>
            </div>
            {userDimensions.floors?.map((floor, i) => (
                <div key={`floor-${i}`} className="flex items-center flex-wrap text-base mb-5">
                    <span className="font-bold mr-10 mb-2 whitespace-no-wrap">Floor area {i + 1}</span>
                    <div className="flex items-center flex-shrink-0">
                        <span className="mr-2 min-w-5.5 mb-2">W: {floor.width} cm</span>
                        <span className="mr-10 min-w-5.5 mb-2">L: {floor.length} cm</span>
                    </div>
                    <Link as="button" onClick={() => handleRemoveFloor(i)} className="mb-2">Remove this floor area</Link>
                </div>
            ))}
        </div>
    )
}

export default FloorDimensions
