import React, { useContext, useState } from 'react'
import Layout from "@/Components/Layouts/Layout"
import { Head, usePage } from "@inertiajs/inertia-react"
import AccountLayout from "@/Components/Layouts/AccountLayout"
import Img from "@/Components/UI/Img"
import Link from "@/Components/UI/Link"
import BillSummaryModal from "@/Components/Vibeset/Modals/BillSummaryModal"
import SmallModal from "@/Components/Modals/SmallModal"
import Button from "@/Components/UI/Button"
import { Inertia } from "@inertiajs/inertia"
import ActionLink from '@/Components/UI/ActionLink'
import { AppContext } from "@/Contexts/app-context"

const SavedVibesets = () => {
    const {saved_vibesets} = usePage().props
    const {appContext, setAppContext} = useContext(AppContext)
    const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState(false)
    const [vibesetIdToDelete, setVibesetIdToDelete] = useState(false)
    const [billSummaryVibeset, setBillSummaryVibeset] = useState(null)

    const handleViewBillSummary = (savedVibeset) => {
        setBillSummaryVibeset(savedVibeset)
        setAppContext({
            ...appContext,
            isMobileBillModalOpened: true,
        })
    }

    const handleCloseBillSummary = () => {
        setAppContext({
            ...appContext,
            isMobileBillModalOpened: false,
        })
        setBillSummaryVibeset(null)
    }

    const handleOpenDeleteModal = (saved_vibeset_id) => {
        setVibesetIdToDelete(saved_vibeset_id)
        setIsConfirmDeleteModalOpen(true)
    }

    const handleCloseDeleteModal = () => {
        setVibesetIdToDelete(false)
        setIsConfirmDeleteModalOpen(false)
    }

    const handleRemoveVibeset = () => {
        if (!vibesetIdToDelete) return
        Inertia.delete(`/saved-vibeset/${vibesetIdToDelete}`, {
            onSuccess: () => handleCloseDeleteModal(),
        })
    }

    return (
        <AccountLayout>
            <Head>
                <title>Saved vibesets</title>
            </Head>
            <h1 className="font-bold text-xl text-teal leading-normal mb-2.5">My saved vibesets</h1>
            {saved_vibesets.length === 0 ? (
                <div className="text-center laptop:mt-28 mt-24">
                    <p className="text-gray-dark mb-7.5">You haven't saved any vibesets yet.</p>
                    <Button as="link" href="/#browse">Search vibesets</Button>
                </div>
            ) : (
                <>
                    {saved_vibesets.map((saved_vibeset, index) => {
                        return (
                            <div
                                key={`saved-vibeset-${index}`}
                                className="bg-gray-light p-4 flex flex-col tablet:flex-row mb-2.5"
                                style={{
                                    maxWidth: 858,
                                }}
                            >
                                <Img
                                    src={saved_vibeset.vibeset.thumbnail.src}
                                    alt={saved_vibeset.vibeset.thumbnail.alt}
                                    className="w-full tablet:w-44 mb-4 tablet:mb-0 flex-shrink-0"
                                />
                                <div className="tablet:ml-7.5 tablet:mr-4 mb-4 tablet:mb-0 flex-1">
                                    <div
                                        className="font-bold text-base tablet:mb-7.5 mb-2.5">{saved_vibeset.vibeset.room?.name || 'Accessory set'} - {saved_vibeset.vibeset.name}</div>
                                    <Link
                                        as="button"
                                        className="text-base mb-4 tablet:hidden"
                                        onClick={() => handleViewBillSummary(saved_vibeset)}
                                    >
                                        View bill summary
                                    </Link>
                                    <div className="font-bold text-base mb-1.5">Current total</div>
                                    <div
                                        className="text-base">{saved_vibeset.vibeset.currency} {saved_vibeset.total_cost_incl_vat}</div>
                                </div>
                                <div
                                    className="flex flex-col items-start tablet:ml-auto tablet:flex tablet:flex-col flex-shrink-0">
                                    <Link
                                        as="button"
                                        className="text-base mb-7.5 hidden tablet:inline-block"
                                        onClick={() => handleViewBillSummary(saved_vibeset)}
                                    >
                                        View bill summary
                                    </Link>
                                    <Button as="link"
                                            href={`/saved-vibeset/${saved_vibeset.id}`}
                                            type="secondary"
                                            className="mb-2.5"
                                    >
                                        Continue order
                                    </Button>
                                    <Link
                                        as="button"
                                        className="text-sm"
                                        onClick={() => handleOpenDeleteModal(saved_vibeset.id)}
                                    >
                                        Remove from saved
                                    </Link>
                                </div>
                            </div>
                        )
                    })}
                </>
            )}

            <BillSummaryModal
                isModalOpen={appContext.isMobileBillModalOpened}
                closeModal={handleCloseBillSummary}
                set={billSummaryVibeset}
            />

            <SmallModal
                isModalOpen={isConfirmDeleteModalOpen}
                closeModal={handleCloseDeleteModal}
            >
                <div className="mb-7.5">
                    <div className="text-base">
                        Are you sure you want to remove this saved vibeset from your account? Your changes will be lost.
                    </div>
                </div>
                <div className="flex items-center justify-end">
                    <ActionLink as="button" className="mr-7.5" onClick={handleCloseDeleteModal}>Keep it</ActionLink>
                    <Button type="secondary" onClick={handleRemoveVibeset}>Remove</Button>
                </div>
            </SmallModal>
        </AccountLayout>
    )
}

SavedVibesets.layout = page => <Layout children={page}/>

export default SavedVibesets
