import React, { useContext, useState } from 'react'
import Img from "@/Components/UI/Img"
import { AddItemIcon, BillEditIcon, GalleryIcon, RemoveItemIcon } from "@/Components/Icons"
import Link from "@/Components/UI/Link"
import { AppContext } from "@/Contexts/app-context"
import ProductImagesModal from "@/Components/Vibeset/Modals/ProductImagesModal"
import ProductSpecificationsModal from "@/Components/Vibeset/Modals/ProductSpecificationsModal"
import Tag from "@/Components/Tag"
import ButtonGold from '@/Components/UI/ButtonGold'
import WallpaperModal from '@/Components/Vibeset/Modals/WallpaperModal'
import { getQuantitySuffix } from '@/Components/Vibeset/utils'
import TextInput from '@/Components/Form/TextInput'
import CheckboxInput from '@/Components/Form/CheckboxInput'
import ButtonIcon from '@/Components/UI/ButtonIcon'
import WallPaintModal from "@/Components/Vibeset/Modals/WallPaintModal"
import CurtainsModal from "@/Components/Vibeset/Modals/CurtainsModal"
import FloorModal from "@/Components/Vibeset/Modals/FloorModal"

const Product = ({product}) => {
    const {appContext, setAppContext} = useContext(AppContext)
    const [isImagesModalOpen, setIsImagesModalOpen] = useState(false)
    const [isSpecModalOpen, setIsSpecModalOpen] = useState(false)
    const [isWallpaperModalOpen, setIsWallpaperModalOpen] = useState(false)
    const [isWallPaintModalOpen, setIsWallPaintModalOpen] = useState(false)
    const [isFloorModalOpen, setIsFloorModalOpen] = useState(false)
    const [isCurtainsModalOpen, setIsCurtainsModalOpen] = useState(false)
    const isWallsAndFloor = product.product_section.name === 'Walls & Floors'
    const isProductSoldPerSqm = product.price_selling_unit === 'sqm'
    const isWallpaper = product.product_category.name === 'Wallpaper'
    const isWallPaint = product.product_category.name === 'Wall paint'
    const isFloor = product.product_category.name === 'Floors'
    const isCurtainSoldPerSqm = product.product_category.name === 'Curtains' && product.price_selling_unit === 'sqm'
    const isProductSelected = product.pivot?.quantity * product.pivot?.is_in_stock > 0
    const isProductOutOfStock = product.pivot?.is_in_stock === 0

    const handleSelectItem = () => {
        if (isWallpaper) {
            setIsWallpaperModalOpen(true)
        } else if (isWallPaint) {
            setIsWallPaintModalOpen(true)
        } else if (isCurtainSoldPerSqm) {
            setIsCurtainsModalOpen(true)
        } else if (isFloor) {
            setIsFloorModalOpen(true)
        } else {
            setAppContext({
                ...appContext,
                products: appContext.products.map(p => {
                    return p.id === product.id ? {...p, pivot: {...p.pivot, quantity: 1}} : p
                }),
            })
        }
    }

    const handleQuantityChange = (value) => {
        setAppContext({
            ...appContext,
            products: appContext.products.map(p => {
                return p.id === product.id ? {...p, pivot: {...p.pivot, quantity: value}} : p
            }),
        })
    }

    const handleToggleAssembly = () => {
        setAppContext({
            ...appContext,
            products: appContext.products.map(p => {
                return p.id === product.id ? {...p, pivot: {...p.pivot, is_assembly: Number(!p.pivot.is_assembly)}} : p
            }),
        })
    }

    return (
        <div className="p-2.5 relative">
            {isProductSelected && (
                <div
                    className={`absolute w-full h-full top-0 left-0 transition duration-150 ease-in-out opacity-30 bg-gold`}/>
            )}
            {isProductOutOfStock && (
                <div
                    className={`absolute w-full h-full top-0 left-0 transition duration-150 ease-in-out opacity-80 bg-gray-light z-20`}/>
            )}
            <div className="relative mb-2.5">
                {isProductOutOfStock && (
                    <div
                        className="py-2 px-2.5 absolute-center bg-transparent border border-black opacity-90 z-30 text-center">
                        <p className="text-xs">OUT OF STOCK</p>
                    </div>
                )}
                <div className={`relative`}>
                    {Boolean(product.pivot.is_vibetray_choice) && (
                        <Tag color="teal-dark" className="absolute top-2.5 left-2.5">VIBETRAY CHOICE</Tag>
                    )}
                    {product.thumbnail ? (
                        <Img alt={product.thumbnail?.alt} src={product.thumbnail?.src}
                             className="w-full object-cover"/>
                    ) : <div className="w-full h-[300px] bg-white"/>}
                </div>
                <button
                    onClick={() => setIsImagesModalOpen(true)}
                    className="flex items-center justify-center p-2 focus:ring-black focus:outline-none focus:ring rounded-full bg-white absolute bottom-4 right-4 w-10 h-10 shadow hover:shadow-md"
                >
                    <GalleryIcon className=""/>
                </button>
            </div>
            <div className="relative">
                <div className="flex items-start mb-2">
                    <span className="max-w-50% font-bold text-base">{product.name}</span>
                    <span className="ml-auto font-bold text-base">
                                    {appContext.vibeset?.currency} {product.price_including_vat}
                        {product.price_suffix && (
                            <>&nbsp;<span dangerouslySetInnerHTML={{__html: product.price_suffix}}/></>
                        )}
                                </span>
                </div>
                <div className={`flex items-start mb-2`}>
                    <span className="max-w-50%">{product.dimensions}</span>
                    <Link className="ml-auto !text-base" as="button" onClick={() => setIsSpecModalOpen(true)}>View
                        specification</Link>
                </div>

                <div className="flex items-center justify-between flex-wrap">
                    <div className="mr-4 flex items-center">
                        {isProductSelected ? (
                            <>
                                {isProductSoldPerSqm ? (
                                    <>
                                        <ButtonGold className="disabled:!bg-gold-dark disabled:!opacity-100 mr-3"
                                                    disabled>
                                            Selected
                                            <span
                                                className="w-[16px] h-[8px] border-b border-white border-l -rotate-45 ml-2 relative top-[-3px]"/>
                                        </ButtonGold>
                                        <button onClick={handleSelectItem}
                                                className="flex items-center text-sm font-bold rounded text-gold-dark p-2 focus:border-gold-dark focus:ring-gold-dark focus:outline-none focus:ring">
                                            <BillEditIcon/>
                                            <span className="ml-2">Edit</span>
                                        </button>
                                    </>
                                ) : (
                                    <div className="flex items-center">
                                        <ButtonIcon onClick={() => handleQuantityChange(product.pivot.quantity - 1)}>
                                            <RemoveItemIcon/>
                                        </ButtonIcon>
                                        <TextInput
                                            type="number"
                                            min={0}
                                            className="w-10 mx-2 appearance-none"
                                            inputClassName="text-center h-8"
                                            value={product.pivot.quantity}
                                            onChange={(e) => handleQuantityChange(e.target.value)}
                                        />
                                        <ButtonIcon onClick={() => handleQuantityChange(product.pivot.quantity + 1)}>
                                            <AddItemIcon/>
                                        </ButtonIcon>
                                    </div>
                                )}

                            </>
                        ) : (
                            <ButtonGold onClick={handleSelectItem}>Select item</ButtonGold>
                        )}
                    </div>
                    {isProductSelected && (
                        <>
                            {isWallsAndFloor && (
                                <div className="text-base">
                                    Qty: {product.pivot.quantity}<span
                                    dangerouslySetInnerHTML={{__html: getQuantitySuffix(product.price_selling_unit)}}/>
                                </div>
                            )}
                            {Boolean(product.is_assembly) && (
                                <div className="flex items-center">
                                    <CheckboxInput
                                        name={`product-assembly-${product.id}`}
                                        checked={product.pivot.is_assembly}
                                        label={`Add assembly (${product.currency} ${product.assembly_price})`}
                                        onChange={handleToggleAssembly}
                                    />
                                </div>
                            )}
                        </>
                    )}

                </div>
            </div>

            {isWallpaper && (
                <WallpaperModal
                    isModalOpen={isWallpaperModalOpen}
                    closeModal={() => setIsWallpaperModalOpen(false)}
                    product={product}
                />
            )}

            {isWallPaint && (
                <WallPaintModal
                    isModalOpen={isWallPaintModalOpen}
                    closeModal={() => setIsWallPaintModalOpen(false)}
                    product={product}
                />
            )}

            {isCurtainSoldPerSqm && (
                <CurtainsModal
                    isModalOpen={isCurtainsModalOpen}
                    closeModal={() => setIsCurtainsModalOpen(false)}
                    product={product}
                />
            )}

            {isFloor && (
                <FloorModal
                    isModalOpen={isFloorModalOpen}
                    closeModal={() => setIsFloorModalOpen(false)}
                    product={product}
                />
            )}

            <ProductImagesModal
                isModalOpen={isImagesModalOpen}
                closeModal={() => setIsImagesModalOpen(false)}
                images={product.images}
            />

            <ProductSpecificationsModal
                isModalOpen={isSpecModalOpen}
                closeModal={() => setIsSpecModalOpen(false)}
                product={product}
            />
        </div>
    )
}

export default Product
