import React from 'react'
import { InertiaLink } from "@inertiajs/inertia-react"
import PropTypes from 'prop-types'

const ActionLink = ({children, className, href, as, ...props}) => {
    const classes = `inline-flex items-center focus:ring focus:outline-none focus:ring-gray-dark focus:hover:ring-black p-2 font-semibold text-gray-dark hover:text-black`
    return (
        as === 'button' ? (
            <button
                className={`${classes} ${className}`}
                {...props}
            >
                {children}
            </button>
        ) : (
            <InertiaLink
                href={href}
                className={`${classes} ${className}`}
                {...props}
            >
                {children}
            </InertiaLink>
        )
    )
}

export default ActionLink

ActionLink.defaultProps = {
    as: 'link',
}

ActionLink.propTypes = {
    as: PropTypes.oneOf(['link', 'button']),
    className: PropTypes.string,
}
