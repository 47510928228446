import React from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import VibesetBill from "@/Components/Vibeset/VibesetBill/VibesetBill"

Modal.setAppElement('#app')

const MobileVibesetBillModal = (props) => {
    const {isModalOpen, closeModal, products, is_read_only, is_summary} = props

    return (
        <Modal
            id="mobile-vibeset-bill-modal"
            isOpen={isModalOpen}
            onRequestClose={closeModal}
            contentLabel="Bill"
        >
            <div className="relative h-full">
                <VibesetBill
                    products={products}
                    is_summary={is_summary}
                    is_read_only={is_read_only}
                />
            </div>
        </Modal>
    )
}


MobileVibesetBillModal.propTypes = {
    isModalOpen: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    products: PropTypes.array.isRequired,
    is_summary: PropTypes.bool.isRequired,
    is_read_only: PropTypes.bool.isRequired,
}

export default MobileVibesetBillModal
