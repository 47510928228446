import React from 'react'
import PropTypes from 'prop-types'

const Img = ({className, src, x2, webp, webp2x, alt}) => {
    return (
        <picture>
            {webp && (
                <source srcSet={`${webp}${webp2x ? `, ${webp2x} 2x` : ''}`} type="image/webp"/>
            )}
            <source srcSet={`${src}${x2 ? `, ${x2} 2x` : ''}`}/>
            <img src={src} srcSet={`${x2 ? `${x2} 2x` : ''}`} alt={alt} className={className}/>
        </picture>
    )
}

Img.propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
}

export default Img
