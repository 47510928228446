import React, { useRef, useState } from 'react'
import { filesize } from '@/utils'
import Label from '@/Components/Form/Label'
import { CloseIcon } from "@/Components/Icons"
import FormError from "@/Components/Form/FormError"
import ButtonGold from "@/Components/UI/ButtonGold"

const FileInput = ({
                       className,
                       name,
                       files,
                       setFiles,
                       label,
                       accept,
                       error,
                       multiple = false,
                       max_mb = 5,
                       max_nb_files = 5,
                       buttonText = 'Browse...',
                       handleDeleteFile,
                   }) => {
    const MAX_FILE_SIZE = 1048576 * max_mb
    const fileInput = useRef()
    const [fileSizeError, setFileSizeError] = useState(false)
    const [fileNumberError, setFileNumberError] = useState(false)

    function browse(e) {
        e.preventDefault()
        fileInput.current.click()
    }

    function remove(fileIndex) {
        if (fileIndex < 0) {
            return
        }
        let newFiles = [...files]
        newFiles.splice(fileIndex, 1)
        setFileNumberError(false)
        setFileSizeError(false)
        setFiles(newFiles)
    }

    function handleFileChange(e) {
        // File number error
        if ((files.length + e.target.files.length) > max_nb_files) {
            setFileNumberError(true)
            return
        }

        // File size error
        for (let i = 0; i < e.target.files.length; i++) {
            if (e.target.files[i].size > MAX_FILE_SIZE) {
                setFileSizeError(true)
                return
            }
        }

        setFileSizeError(false)
        setFileNumberError(false)
        const newFiles = multiple ? Array.from([...files, ...e.target.files]) : Array.from(e.target.files[0])
        setFiles(newFiles)
    }

    return (
        <div className={className}>
            {label && (
                <Label name={name}>
                    {label}:
                </Label>
            )}
            <div className="text-sm mb-4">Max file size: {max_mb}MB each</div>
            <div className={`appearance-none relative`}>
                <input
                    id={name}
                    ref={fileInput}
                    accept={accept}
                    type="file"
                    className="hidden"
                    onChange={handleFileChange}
                    multiple={multiple}
                />
                <ButtonGold onClick={browse} className="mb-2">{buttonText}</ButtonGold>

                {files.length > 0 && files.map((file, index) => (
                    <div className="flex items-center justify-between py-2" key={`file-${index}`}>
                        <div className="flex-1 pr-1 text-sm break-words w-full">
                            {file.name}
                            <span className="text-gray-600 text-xs ml-1">({filesize(file.size)})</span>
                        </div>
                        <button onClick={(e) => {
                            e.preventDefault()
                            remove(index)
                            if (handleDeleteFile) {
                                handleDeleteFile(file.id)
                            }
                        }} className="text-gray-dark hover:text-black">
                            <CloseIcon className="w-4 stroke-current"/>
                        </button>
                    </div>
                ))}
            </div>
            {error}
            {fileNumberError && (
                <FormError>
                    {`Oops, you can upload a maximum of ${max_nb_files} files.`}
                </FormError>
            )}
            {fileSizeError && (
                <FormError>
                    {`Oops, the files that you can upload must be ${max_mb}MB or less each.`}
                </FormError>
            )}
        </div>
    )
}

export default FileInput
