import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import Tag from "@/Components/Tag"
import { LeftArrowIcon, RightArrowIcon } from "@/Components/Icons"
import { InertiaLink } from "@inertiajs/inertia-react"

const LeftArrow = ({onClick, className}) => (
    <button
        onClick={onClick}
        className={`hidden laptop:flex bg-white text-gray-dark z-10 rounded-full shadow hover:shadow-md focus:ring-0 focus:outline-none flex items-center justify-center absolute ${className}`}
        style={{
            width: 40,
            height: 40,
            top: 80,
            left: -20,
        }}
    >
        <LeftArrowIcon className="stroke-current"/>
    </button>
)

const RightArrow = ({onClick, className}) => (
    <button
        onClick={onClick}
        className={`hidden laptop:flex bg-white text-gray-dark z-10 rounded-full shadow hover:shadow-md focus:ring-0 focus:outline-none flex items-center justify-center absolute ${className}`}
        style={{
            width: 40,
            height: 40,
            top: 80,
            right: -20,
        }}
    >
        <RightArrowIcon className="stroke-current"/>
    </button>
)

const VibesetsCarousel = ({items}) => {
    const ITEM_MAX_WIDTH = typeof window !== 'undefined' ? window.innerWidth > 1023 ? 303 : 252 : 0
    const ITEM_MARGIN = typeof window !== 'undefined' ? window.innerWidth > 1365 ? 30 : 25 : 0
    const container = useRef()
    const carousel = useRef()
    const [scrollAmount, setScrollAmount] = useState(0)
    const [carouselScrollPosition, setCarouselScrollPosition] = useState(0)
    const [maxScrollAmount, setMaxScrollAmount] = useState(0)

    useEffect(() => {
        setupCarousel()
        window.addEventListener('resize', setupCarousel)
        return () => {
            window.removeEventListener('resize', setupCarousel)
        }
    }, [])

    const setupCarousel = () => {
        if (!container.current || !carousel.current) {
            return
        }
        const ITEM_WIDTH = Math.min(container.current.clientWidth, ITEM_MAX_WIDTH)
        const ITEM_TOTAL_WIDTH = ITEM_WIDTH + ITEM_MARGIN
        const SCROLLBAR_WIDTH = window.innerWidth - document.documentElement.clientWidth
        const CONTAINER_WIDTH = container.current.clientWidth + SCROLLBAR_WIDTH
        // Calculate number of visible items
        const nbItemVisible = Math.max(Math.floor((CONTAINER_WIDTH + ITEM_MARGIN) / ITEM_TOTAL_WIDTH), 1)
        // Calculate scroll amount
        setScrollAmount(nbItemVisible * ITEM_TOTAL_WIDTH)
        // Calculate max scroll amount
        setMaxScrollAmount(carousel.current.scrollWidth - carousel.current.clientWidth)
    }

    const handleClickRightArrow = () => {
        if (!container.current || !carousel.current) {
            return
        }
        const newScrollPosition = Math.min(carouselScrollPosition + scrollAmount, maxScrollAmount)
        carousel.current.scrollTo({
            top: 0,
            left: newScrollPosition,
            behavior: 'smooth',
        })
        setCarouselScrollPosition(newScrollPosition)
    }

    const handleClickLeftArrow = () => {
        if (!container.current || !carousel.current) {
            return
        }
        const newScrollPosition = Math.max(carouselScrollPosition - scrollAmount, 0)
        carousel.current.scrollTo({
            top: 0,
            left: newScrollPosition,
            behavior: 'smooth',
        })
        setCarouselScrollPosition(newScrollPosition)
    }

    const handleScroll = (e) => {
        if (!carousel.current) {
            return
        }
        setCarouselScrollPosition(carousel.current.scrollLeft)
    }

    return (
        <div className="relative" ref={container}>
            {carouselScrollPosition < maxScrollAmount && (
                <RightArrow onClick={handleClickRightArrow}/>
            )}

            {carouselScrollPosition > 0 && (
                <LeftArrow onClick={handleClickLeftArrow}/>
            )}
            <div className="overflow-x-hidden whitespace-nowrap">
                <div
                    ref={carousel}
                    className="hide-scrollbar overflow-x-auto overflow-y-hidden transition ease-in-out duration-200"
                    onScroll={handleScroll}
                >
                    {items.map((item, index) => (
                        <InertiaLink
                            key={`carousel-vibeset-item-${index}`}
                            className="inline-block cursor-pointer hover:shadow-xl mb-7.5 transition duration-200 ease-in-out"
                            href={`/vibeset/${item.slug}`}
                            style={{
                                width: '100%',
                                maxWidth: ITEM_MAX_WIDTH,
                                marginRight: index === items.length - 1 ? 0 : ITEM_MARGIN,
                            }}
                        >
                            <div className="relative mb-2">
                                <img src={item.thumbnail.src} alt={item.thumbnail.alt}
                                     className="max-w-full w-full object-cover"
                                     style={{height: 200}}/>
                                <div className="absolute" style={{left: 10, top: 7}}>
                                    <Tag>{item.style.name}</Tag>
                                </div>
                            </div>
                            <div className="flex items-center justify-between pb-2.5 px-2.5">
                                <div className="text-base font-bold">{item.name}</div>
                                <RightArrowIcon className="w-4 h-4"/>
                            </div>
                        </InertiaLink>
                    ))}
                </div>
            </div>
        </div>
    )
}

VibesetsCarousel.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
        thumbnail: PropTypes.shape({
            src: PropTypes.string.isRequired,
            alt: PropTypes.string.isRequired,
        }),
        name: PropTypes.string.isRequired,
        style: PropTypes.shape({
            name: PropTypes.string.isRequired,
        }),
    })).isRequired,
}

export default VibesetsCarousel
