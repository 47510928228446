import React from 'react'
import { usePage } from "@inertiajs/inertia-react"
import CheckboxInput from "@/Components/Form/CheckboxInput"
import TextInput from "@/Components/Form/TextInput"
import TextAreaInput from "@/Components/Form/TextAreaInput"
import ActionLink from "@/Components/UI/ActionLink"
import Button from "@/Components/UI/Button"
import { error } from "@/utils"
import SelectInput from "@/Components/Form/SelectInput"
import { emirates } from "@/constants"

const DeliveryStep = ({data, setData, errors, post, setStep}) => {
    const {auth, savedVibeset} = usePage().props
    const isVibeset = savedVibeset.products.some(p => p.product_section.name !== 'Accessories')
    const dispatchTime = savedVibeset.min_total_dispatch_time !== savedVibeset.max_total_dispatch_time && savedVibeset.min_total_dispatch_time !== 0 ? `${savedVibeset.min_total_dispatch_time} to ${savedVibeset.max_total_dispatch_time}` : savedVibeset.max_total_dispatch_time;

    const toggleUseAddressOnAccount = () => {
        setData({
            ...data,
            use_delivery_address_on_account: !data.use_delivery_address_on_account,
            delivery_address: data.use_delivery_address_on_account ? {
                line1: '',
                line2: '',
                town: '',
                emirates: emirates.find(e => e === 'Dubai'),
            } : {
                ...auth.user.delivery_address,
            },
        })
    }

    const handleSubmit = () => {
        post('/order/validate-delivery', {
            preserveScroll: (page) => Object.keys(page.props.errors).length > 0,
            onSuccess: () => {
                setStep(2)
            },
        })
    }

    return (
        <div className="max-w-131">
            <h1 className="font-bold text-2xl mb-4">Delivery</h1>
            {isVibeset ? (
                <div className="mb-7.5">
                    <p className="text-base mb-15">Our team will review the details of your order and will validate it
                        once all stock availability has been checked.<br/><br/>
                        If any item(s) are unavailable, we will suggest alternatives for you to pick from. If you’re not
                        happy with the alternatives, you can remove them from your order and your bill will be adjusted
                        accordingly.<br/><br/>
                        <span className="font-bold text-teal">Note:</span> Terms and conditions apply per product.
                        Please make sure you read the product’s spec sheet before placing your order.</p>

                    <h2 className="text-teal font-bold text-xl mb-4">Enter your delivery address</h2>
                    <p className="text-base mb-5">
                        <span
                            className="text-red">Delivery notice: we only deliver to Dubai at the moment.</span><br/><br/>
                        The estimated delivery time for your items is {dispatchTime} business
                        days. This may
                        change after
                        validation. <br/><br/>
                        Our suppliers & partners will contact you to arrange suitable times for the delivery. They may
                        send you text updates where possible or call if they need more information.
                    </p>
                    <CheckboxInput
                        name="use_delivery_address_on_account"
                        checked={data.use_delivery_address_on_account}
                        label="Use address saved in my account"
                        onChange={toggleUseAddressOnAccount}
                        disabled={auth?.user?.delivery_address === null}
                    />
                </div>
            ) : (
                <div className="mb-7.5">
                    <p className="text-base mb-15">The estimated delivery time for your items is {dispatchTime} business days.<br/><br/>
                        Please note that the mix of items in our accessory sets may vary slightly upon stock
                        availability while keeping the same mood, quality and price.<br/><br/>

                        <span className="font-bold text-teal">Note:</span> please check your bill,
                        making sure you have selected the right items before you proceed with your order.</p>
                    <h2 className="text-teal font-bold text-xl mb-4">Enter your delivery address</h2>
                    <p className="text-base mb-5">
                        <span
                            className="text-red">Delivery notice: we only deliver to Dubai at the moment.</span><br/><br/>
                        We’ll send text updates where possible and call if we need
                        more delivery information.</p>
                    <CheckboxInput
                        name="use_delivery_address_on_account"
                        checked={data.use_delivery_address_on_account}
                        label="Use address saved in my account"
                        onChange={toggleUseAddressOnAccount}
                        disabled={auth?.user?.delivery_address === null}
                    />
                </div>
            )}

            <TextInput
                name="delivery_address.line1"
                type="text"
                label="Address*"
                error={error(errors, 'delivery_address.line1')}
                className="mb-7.5 tablet:mr-7.5 w-full tablet:max-w-76 desktop:max-w-76"
                value={data.delivery_address.line1}
                onChange={(e) => setData('delivery_address', {
                    ...data.delivery_address,
                    line1: e.target.value,
                })}
            />
            <TextInput
                name="delivery_address.line2"
                type="text"
                label="Apartment, suite, etc"
                error={error(errors, 'delivery_address.line2')}
                className="mb-7.5 w-full tablet:max-w-76 desktop:max-w-76"
                value={data.delivery_address.line2}
                onChange={(e) => setData('delivery_address', {
                    ...data.delivery_address,
                    line2: e.target.value,
                })}
            />
            <TextInput
                name="delivery_address.town"
                type="text"
                label="City*"
                error={error(errors, 'delivery_address.town')}
                className="mb-7.5 tablet:mr-7.5 w-full tablet:max-w-76 desktop:max-w-76"
                value={data.delivery_address.town}
                onChange={(e) => setData('delivery_address', {
                    ...data.delivery_address,
                    town: e.target.value,
                })}
            />
            <SelectInput
                name="emirate"
                type="text"
                label="Emirate"
                error={error(errors, 'delivery_address.emirate')}
                className="mb-7.5 w-full tablet:max-w-76 desktop:max-w-76"
                value={data.delivery_address.emirate}
                disabled={true}
                onChange={(e) => setData('delivery_address', {
                    ...data.delivery_address,
                    emirate: e.target.value,
                })}
            >
                <option value="" disabled hidden>Select one</option>
                {emirates.map((emirate, index) => (
                    <option value={emirate} key={index}>{emirate}</option>
                ))}
            </SelectInput>

            <TextInput
                name="phone"
                type="text"
                label="Phone number*"
                error={error(errors, 'phone')}
                className="mb-7.5 desktop:max-w-xs"
                value={data.phone}
                onChange={(e) => setData('phone', e.target.value)}
            />
            <TextAreaInput
                name="notes"
                label="Do you have any notes regarding your order? (optional)"
                className="mb-7.5"
                textAreaClassName="desktop:max-w-full laptop:max-w-full"
                value={data.notes}
                onChange={(e) => setData('notes', e.target.value)}
            />
            <div
                className="flex items-center flex-wrap laptop:justify-end max-w-full laptop:max-w-full">
                <ActionLink href={`/saved-vibeset/${savedVibeset.id}`} className="mr-7.5 mb-4 tablet:mb-0">Go
                    back</ActionLink>
                <Button onClick={handleSubmit} className="mb-4 tablet:mb-0">Continue to payment</Button>
            </div>
        </div>
    )
}

export default DeliveryStep
