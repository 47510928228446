import React from 'react'
import Layout from "@/Components/Layouts/Layout"
import { usePage } from "@inertiajs/inertia-react"
import VibesetTemplate from "@/Components/Vibeset/VibesetTemplate"

const Vibeset = () => {
    const {vibeset, products} = usePage().props

    return (
        <VibesetTemplate
            vibeset={vibeset}
            products={products}
        />
    )
}

Vibeset.layout = page => <Layout children={page} showFooter={false}/>

export default Vibeset
