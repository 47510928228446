import React, { useContext, useEffect, useState } from "react";
import Modal from "react-modal";
import { CloseIcon } from "@/Components/Icons";
import H3 from "@/Components/UI/H3";
import { AppContext } from "@/Contexts/app-context";
import Button from "@/Components/UI/Button";
import SelectRoomSize from "@/Components/Vibeset/Modals/ModalContent/SelectRoomSize";
import { DEFAULT_USER_DIMENSIONS, getFloorQuantity } from "@/Components/Vibeset/utils";
import FloorDimensions from "@/Components/Vibeset/Modals/ModalContent/FloorDimensions";

Modal.setAppElement('#app')


const FloorModal = (props) => {
    const {appContext, setAppContext} = useContext(AppContext)
    const {isModalOpen, closeModal, product} = props
    const [userDimensions, setUserDimensions] = useState(product.pivot?.user_dimensions || DEFAULT_USER_DIMENSIONS)

    const hideFloorDimensions = userDimensions.room !== ''
    const hideSelectRoomSize = userDimensions.floors?.length > 0

    // Update local state userDimensions when product user dimensions are updated and modal is open
    useEffect(() => {
        if (isModalOpen && product.pivot?.user_dimensions) {
            setUserDimensions({
                ...product.pivot?.user_dimensions,
            })
        }
    }, [isModalOpen, product.pivot?.user_dimensions])

    const handleSubmit = () => {
        const floorQuantity = getFloorQuantity(product, userDimensions)
        setAppContext({
            ...appContext,
            products: appContext.products.map(p => {
                return p.id === product.id ? {
                    ...p,
                    pivot: {...p.pivot, quantity: floorQuantity, user_dimensions: userDimensions},
                } : p
            }),
        })
        closeModal()
    }

    return (
        <Modal
            className="room-size-modal"
            isOpen={isModalOpen}
            onRequestClose={closeModal}
        >
            <div className="py-7.5 px-7.5 laptop:px-15 laptop:pb-15">
                <div className="flex items-start flex-wrap justify-between mb-5">
                    <H3 className="inline-block">
                        Calculate room size
                    </H3>
                    <div className="w-full tablet:w-auto order-first tablet:order-last mb-4 tablet:mb-0 text-right">
                        <button
                            className="inline-block text-black hover:text-gray-dark focus:outline-none focus:ring-0"
                            onClick={closeModal}>
                            <CloseIcon className="stroke-current"/>
                        </button>
                    </div>
                </div>
                <p className="text-base mb-7.5">
                    To calculate how much flooring you’ll need, tell us how big your space is with one of the options below. These measurements will be used to calculate an estimated price, but one of our partners will do a site visit to confirm the conditions and dimensions of your space. The final price will be adjusted accordingly.
                </p>

                <div className={`${hideSelectRoomSize ? 'opacity-40 pointer-events-none' : ''}`}>
                    <SelectRoomSize
                        userDimensions={userDimensions}
                        setUserDimensions={setUserDimensions}
                    />
                </div>

                <p className={`text-center text-gray-dark font-bold text-xl relative mb-7.5 ${hideFloorDimensions || hideSelectRoomSize ? 'opacity-40' : ''}`}>
                    <span className="absolute left-0 h-[1px] w-full bg-black top-1/2 -translate-y-1/2"/>
                    <span className="px-5 bg-white relative top-[-2px]">or</span>
                </p>

                <div className={`${hideFloorDimensions ? 'opacity-40 pointer-events-none' : ''}`}>
                    <FloorDimensions
                        userDimensions={userDimensions}
                        setUserDimensions={setUserDimensions}
                    />
                </div>

                <div className="flex items-center justify-end">
                    <button
                        onClick={closeModal}
                        className={`mr-7.5 font-bold text-base text-gray-dark p-2`}
                    >
                        Cancel
                    </button>
                    <Button
                        onClick={handleSubmit}
                        disabled={(!hideFloorDimensions && !hideSelectRoomSize)}
                    >
                        Submit
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export default FloorModal
