import React, { useState } from 'react'
import TextInput from "@/Components/Form/TextInput"
import { error } from "@/utils"
import Button from "@/Components/UI/Button"
import { useForm, usePage } from "@inertiajs/inertia-react"
import ActionLink from '@/Components/UI/ActionLink'
import SmallModal from '@/Components/Modals/SmallModal'

const ProfileForm = () => {
    const {auth} = usePage().props
    const [isSuccess, setIsSuccess] = useState(false)

    const INITIAL_VALUES = {
        first_name: auth.user.first_name,
        last_name: auth.user.last_name,
        phone: auth.user.phone,
        email: auth.user.email,
    }

    const {data, setData, post, errors, clearErrors} = useForm({...INITIAL_VALUES})

    const handleSubmit = (e) => {
        e.preventDefault()
        post('/account/profile', {
            preserveScroll: (page) => Object.keys(page.props.errors).length > 0,
            onSuccess: () => {
                setIsSuccess(true)
            }
        })
    }

    const reset = () => {
        setData({...INITIAL_VALUES})
        clearErrors()
    }

    const isFormEdited = data.first_name !== auth.user.first_name || data.last_name !== auth.user.last_name || data.email !== auth.user.email || data.phone !== auth.user.phone

    return (
        <div className="mb-7.5">
            <h1 className="font-bold text-xl text-teal leading-normal mb-5">My profile</h1>
            <form className="max-w-160" onSubmit={handleSubmit}>
                <div className="flex items-start flex-wrap justify-start w-full">
                    <TextInput
                        name="first_name"
                        type="text"
                        label="First name"
                        error={error(errors, 'first_name')}
                        className="mb-7.5 tablet:mr-7.5 w-full tablet:max-w-76 desktop:max-w-76"
                        value={data.first_name}
                        onChange={(e) => setData('first_name', e.target.value)}
                    />
                    <TextInput
                        name="last_name"
                        type="text"
                        label="Last name"
                        error={error(errors, 'last_name')}
                        className="mb-7.5 w-full tablet:max-w-76 desktop:max-w-76"
                        value={data.last_name}
                        onChange={(e) => setData('last_name', e.target.value)}
                    />
                    <TextInput
                        name="email"
                        type="email"
                        label="Email address"
                        error={error(errors, 'email')}
                        className="mb-7.5 tablet:mr-7.5 w-full tablet:max-w-76 desktop:max-w-76"
                        value={data.email}
                        onChange={(e) => setData('email', e.target.value)}
                    />
                    <TextInput
                        name="phone"
                        type="text"
                        label="Phone number"
                        error={error(errors, 'phone')}
                        className="mb-7.5 w-full tablet:max-w-76 desktop:max-w-76"
                        value={data.phone}
                        onChange={(e) => setData('phone', e.target.value)}
                    />
                </div>
                <div className="flex items-center justify-end">
                    {isFormEdited && (
                        <ActionLink as="button" className="mr-7.5" onClick={reset} type="button">Cancel</ActionLink>
                    )}
                    <Button
                        type="secondary"
                        buttonType="submit"
                        onClick={handleSubmit}
                        disabled={!isFormEdited}
                    >
                        Save changes
                    </Button>
                </div>
            </form>

            {/*Success modal*/}
            <SmallModal
                isModalOpen={isSuccess}
                closeModal={() => setIsSuccess(false)}
            >
                <>
                    <div className="mb-7.5">
                        <div className="text-base">
                            Your profile has been updated successfully.
                        </div>
                    </div>
                    <div className="flex items-center justify-end flex-wrap -mb-4">
                        <Button onClick={() => setIsSuccess(false)} className="mb-4">Got it</Button>
                    </div>
                </>
            </SmallModal>
        </div>
    )
}

export default ProfileForm
