import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { LeftArrowIcon, RightArrowIcon } from "@/Components/Icons"

const LeftArrow = ({onClick, className}) => (
    <button
        onClick={onClick}
        className={`z-10 text-gray-dark focus:ring-0 focus:outline-none flex items-center justify-center absolute -left-4.5 tablet:-left-6.25 ${className}`}
        style={{
            width: 20,
            height: 20,
            top: 15,
        }}
    >
        <LeftArrowIcon className="stroke-current"/>
    </button>
)

const RightArrow = ({onClick, className}) => (
    <button
        onClick={onClick}
        className={`z-10 text-gray-dark focus:ring-0 focus:outline-none flex items-center justify-center absolute -right-4.5 tablet:-right-6.25 ${className}`}
        style={{
            width: 20,
            height: 20,
            top: 15,
        }}
    >
        <RightArrowIcon className="stroke-current"/>
    </button>
)

const Tab = () => {

}

Tab.propTypes = {
    title: PropTypes.string.isRequired,
}

const Tabs = ({activeTabIndex, setActiveTabIndex, children}) => {
    const container = useRef()
    const [maxScroll, setMaxScroll] = useState(0)
    const [scrollPosition, setScrollPosition] = useState(0)

    // Get tabs from children
    let tabs = []
    for (const child in children) {
        if (Array.isArray(children[child])) {
            tabs.push(...children[child].filter(c => c?.type === Tab))
        } else if (children[child] === Tab) {
            tabs.push(children)
        } else if (children[child]?.type === Tab) {
            tabs.push(children[child])
        }
    }
    if (tabs.length === 0) return null
    const [selectedTabIndex, setSelectedTabIndex] = useState(activeTabIndex || 0)
    const selectedTab = tabs[selectedTabIndex]

    useEffect(() => {
        setupTabsContainer()
        window.addEventListener('resize', setupTabsContainer)
        return () => {
            window.removeEventListener('resize', setupTabsContainer)
        }
    }, [])

    // Updated active tab if index is passed in the props
    useEffect(() => {
        if (Number.isInteger(activeTabIndex) && activeTabIndex >= 0 && activeTabIndex < tabs.length) {
            setSelectedTabIndex(activeTabIndex)
        }
    }, [activeTabIndex])

    const setupTabsContainer = () => {
        if (!container.current) {
            return
        }
        setMaxScroll(container.current.scrollWidth - container.current.clientWidth)
    }

    const TabButton = ({title, index}) => (
        <button
            className={`whitespace-nowrap py-2.5 px-5  font-semibold text-base border-gray-light border-2 focus:outline-none focus:ring-0 transition duration-150 ease-in-out mx-1 ${index === selectedTabIndex ? 'text-black bg-gray-light' : 'text-gray-dark bg-white'}`}
            onClick={() => {
                if (setActiveTabIndex) {
                    setActiveTabIndex(index)
                } else {
                    setSelectedTabIndex(index)
                }
            }}
        >
            {title}
        </button>
    )

    const handleScrollLeft = () => {
        if (!container.current) {
            return
        }
        container.current.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        })
    }

    const handleScrollRight = () => {
        if (!container.current) {
            return
        }
        container.current.scrollTo({
            top: 0,
            left: maxScroll,
            behavior: 'smooth',
        })
    }

    const handleScroll = () => {
        setMaxScroll(container.current.scrollWidth - container.current.clientWidth)
        setScrollPosition(container.current.scrollLeft)
    }

    return (
        <div className="relative">
            {maxScroll > 0 && (
                <>
                    {scrollPosition > 0 && (
                        <LeftArrow onClick={handleScrollLeft}/>
                    )}
                    {scrollPosition < maxScroll && (
                        <RightArrow onClick={handleScrollRight}/>
                    )}
                </>
            )}
            <div className="">
                <nav
                    ref={container}
                    className="-mx-1 hide-scrollbar overflow-x-auto overflow-y-hidden whitespace-nowrap"
                    onScroll={handleScroll}
                >
                    {tabs.map((tab, index) => (
                        <TabButton index={index} title={tab?.props.title} key={`button-tab-${index}`}/>
                    ))}
                </nav>
            </div>
            <div className="bg-gray-light px-5 pt-4 pb-7.5">
                {selectedTab?.props.children}
            </div>
        </div>
    )
}

Tabs.Tab = Tab

Tabs.propTypes = {
    activeTabIndex: PropTypes.number,
    setActiveTabIndex: PropTypes.func,
}

Tabs.defaultProps = {
    activeTabIndex: undefined,
    setActiveTabIndex: undefined,
}

export default Tabs
