import React, { useContext, useState } from 'react'
import Layout from "@/Components/Layouts/Layout"
import H1 from "@/Components/UI/H1"
import TextInput from "@/Components/Form/TextInput"
import Button from "@/Components/UI/Button"
import { Head, InertiaLink, usePage } from "@inertiajs/inertia-react"
import { error, getParameterByName } from "@/utils"
import { Inertia } from '@inertiajs/inertia'
import Img from "@/Components/UI/Img"
import registerImg from "@/assets/register_page/register_page.jpg"
import registerImg2x from "@/assets/register_page/register_page@2x.jpg"
import registerImgWebp from "@/assets/register_page/register_page.webp"
import registerImgWebp2x from "@/assets/register_page/register_page@2x.webp"
import PasswordInput from "@/Components/Form/PasswordInput"
import { AppContext } from "@/Contexts/app-context"
import { saveVibeset } from "@/api"

const Login = () => {
    const {errors} = usePage().props
    const {appContext} = useContext(AppContext)

    const [values, setValues] = useState({
        email: '',
        password: '',
    })

    const handleChange = (e) => {
        const key = e.target.id
        const value = e.target.value
        setValues(values => ({
            ...values,
            [key]: value,
        }))
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        let saved_vibeset_id

        // Save vibeset and redirect if necessary
        if (getParameterByName('redirect') && appContext.products.length > 0 && appContext.vibeset?.id) {
            saved_vibeset_id = await saveVibeset(appContext.vibeset?.id, appContext.products)
        }

        // Prepare headers
        let headers = {'from': 'login_page'}
        if (saved_vibeset_id) {
            headers = {
                ...headers,
                'sv': saved_vibeset_id,
                'redirect': getParameterByName('redirect'),
            }
        }

        // Login
        Inertia.post('/login', values, {
            onSuccess: (page) => {
                window.analytics?.identify(page?.props?.auth?.user.email)
            },
            headers,
        })
    }

    return (
        <>
            <Head>
                <title>Login</title>
            </Head>
            <div className="text-center desktop:text-left py-13">
                <H1>Login</H1>
            </div>

            <div className="w-screen laptop:w-full flex flex-wrap -mx-4 laptop:mx-0">
                <div
                    className="bg-gray-light py-7.5 px-4 w-full laptop:w-1/2 laptop:py-8 desktop:py-15 flex items-center">
                    <form className="mx-auto tablet:max-w-xs desktop:max-w-input w-full" onSubmit={handleSubmit}>
                        <TextInput
                            label="Email address*"
                            name="email"
                            type="email"
                            className="mb-7.5"
                            error={error(errors, 'email', 'login_page') || error(errors, 'is_email_confirmed', 'login_page')}
                            onChange={handleChange}
                        />
                        <PasswordInput
                            label="Password*"
                            name="password"
                            className="mb-2.5"
                            error={error(errors, 'password', 'login_page')}
                            onChange={handleChange}
                        />
                        <InertiaLink
                            href="/forgot-password"
                            className="text-sm underline text-black hover:text-teal mb-7.5 block">
                            Forgotten your password?
                        </InertiaLink>
                        <Button className="">Login</Button>
                    </form>
                </div>
                <div className="w-full laptop:w-1/2">
                    <Img
                        src={registerImg}
                        x2={registerImg2x}
                        webp={registerImgWebp}
                        webp2x={registerImgWebp2x}
                        alt="cushions"
                        className="w-full desktop:max-w-full object-cover h-full"
                    />
                </div>
            </div>
        </>

    )
}

Login.layout = page => <Layout children={page}/>

export default Login
