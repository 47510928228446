import React, { useState } from 'react'
import { error } from "@/utils"
import Button from "@/Components/UI/Button"
import { useForm } from "@inertiajs/inertia-react"
import ActionLink from '@/Components/UI/ActionLink'
import PasswordInput from '@/Components/Form/PasswordInput'
import SmallModal from '@/Components/Modals/SmallModal'

const ResetPasswordForm = () => {
    const [isSuccess, setIsSuccess] = useState(false)
    const INITIAL_VALUES = {
        current_password: '',
        new_password: '',
        new_password_confirmation: '',
    }

    const {data, setData, post, errors, clearErrors} = useForm({...INITIAL_VALUES})

    const handleSubmit = (e) => {
        e.preventDefault()
        post('/account/profile/reset-password', {
            preserveScroll: (page) => Object.keys(page.props.errors).length > 0,
            onSuccess: () => {
                setIsSuccess(true)
                reset()
            }
        })
    }

    const reset = () => {
        setData({...INITIAL_VALUES})
        clearErrors()
    }

    const isFormEdited = data.current_password !== '' || data.new_password !== '' || data.new_password_confirmation !== ''

    return (
        <div className="mb-7.5">
            <h1 className="font-bold text-xl text-teal leading-normal mb-5">Reset my password</h1>
            <form className="max-w-160" onSubmit={handleSubmit}>
                <PasswordInput
                    name="current_password"
                    label="Current password"
                    error={error(errors, 'current_password')}
                    className="mb-7.5 w-full tablet:max-w-76 desktop:max-w-76"
                    value={data.current_password}
                    onChange={(e) => setData('current_password', e.target.value)}
                />
                <div className="flex items-start flex-wrap justify-start w-full">
                    <PasswordInput
                        name="new_password"
                        label="New password"
                        error={error(errors, 'new_password')}
                        className="mb-7.5 tablet:mr-7.5 w-full tablet:max-w-76 desktop:max-w-76"
                        value={data.new_password}
                        onChange={(e) => setData('new_password', e.target.value)}
                    />
                    <PasswordInput
                        name="new_password_confirmation"
                        label="Confirm new password"
                        className="mb-7.5 w-full tablet:max-w-76 desktop:max-w-76"
                        value={data.new_password_confirmation}
                        onChange={(e) => setData('new_password_confirmation', e.target.value)}
                    />
                </div>
                <div className="flex items-center justify-end">
                    {isFormEdited && (
                        <ActionLink as="button" className="mr-7.5" onClick={reset} type="button">Cancel</ActionLink>
                    )}
                    <Button
                        type="secondary"
                        buttonType="submit"
                        onClick={handleSubmit}
                        disabled={!isFormEdited}
                    >
                        Save changes
                    </Button>
                </div>
            </form>

            {/*Success modal*/}
            <SmallModal
                isModalOpen={isSuccess}
                closeModal={() => setIsSuccess(false)}
            >
                <>
                    <div className="mb-7.5">
                        <div className="text-base">
                            Your password has been updated successfully.
                        </div>
                    </div>
                    <div className="flex items-center justify-end flex-wrap -mb-4">
                        <Button onClick={() => setIsSuccess(false)} className="mb-4">Got it</Button>
                    </div>
                </>
            </SmallModal>
        </div>
    )
}

export default ResetPasswordForm
