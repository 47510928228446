import React, { useState } from 'react'
import TextInput from "@/Components/Form/TextInput"
import Link from "@/Components/UI/Link"
import H5 from "@/Components/UI/H5"
import ButtonGold from "@/Components/UI/ButtonGold"

const WallPaintDimensions = ({userDimensions, setUserDimensions}) => {
    const [width, setWidth] = useState('')
    const [height, setHeight] = useState('')

    const handleAddWall = () => {
        setUserDimensions({
            ...userDimensions,
            walls_painted: [
                ...userDimensions.walls_painted,
                {
                    width: Number(width),
                    height: Number(height),
                },
            ],
        })
        setWidth('')
        setHeight('')
    }

    const handleRemoveWall = index => {
        const wallsPaintedCopy = userDimensions.walls_painted.slice()
        wallsPaintedCopy.splice(index, 1)
        setUserDimensions({
            ...userDimensions,
            walls_painted: wallsPaintedCopy,
        })
    }

    return (
        <div className="mb-7.5">
            <H5 className="mb-4">Enter your wall dimensions</H5>
            <p className="text-base mb-5">
                Enter the size of the wall(s) that you wish to cover with wall paint.<br/> The amount and cost calculated
                offer a guide to maximum coverage (excluding any additional alterations required based on site
                conditions). Coverage is dependent on thickness of application and surface type, and figures are based
                on only 2 coats.
            </p>
            <div className="flex items-end flex-wrap mb-5">
                <TextInput
                    label="Width"
                    type="number"
                    min={1}
                    value={width}
                    className="w-17 mr-8 laptop:mr-13 mb-4"
                    onChange={(e) => {
                        const value = Number(e.target.value) > 0 ? Number(e.target.value) : ''
                        setWidth(value)
                    }}
                    placeholder="cm"
                />
                <TextInput
                    label="Height"
                    type="number"
                    min={1}
                    value={height}
                    className="w-17 mr-8 laptop:mr-13 mb-4"
                    onChange={(e) => {
                        const value = Number(e.target.value) > 0 ? Number(e.target.value) : ''
                        setHeight(value)
                    }}
                    placeholder="cm"
                />
                <ButtonGold
                    onClick={handleAddWall}
                    disabled={Number(width) <= 0 || Number(height) <= 0}
                    className="mb-4"
                >
                    Add wall
                </ButtonGold>
            </div>
            {userDimensions.walls_painted.map((wall, i) => (
                <div key={`wall-${i}`} className="flex items-center flex-wrap text-base mb-5">
                    <span className="font-bold mr-10 mb-2 whitespace-no-wrap">Wall {i + 1}</span>
                    <div className="flex items-center flex-shrink-0">
                        <span className="mr-2 min-w-5.5 mb-2">W: {wall.width} cm</span>
                        <span className="mr-10 min-w-5.5 mb-2">H: {wall.height} cm</span>
                    </div>
                    <Link as="button" onClick={() => handleRemoveWall(i)} className="mb-2">Remove this wall</Link>
                </div>
            ))}
        </div>
    )
}

export default WallPaintDimensions
