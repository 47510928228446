import React from 'react'
import { ConfirmationIcon, CustomisationIcon, DeliveryIcon, PaymentIcon } from "@/Components/Icons"

const CheckoutSteps = ({currentStep}) => {
    return (
        <div>
            <div
                className="hidden tablet:flex w-full flex items-center text-gold justify-between relative overflow-hidden">
                <span className="absolute w-full h-0.5 bg-gold left-0 opacity-50 z-0" style={{top: 49}}/>
                <div className="relative flex flex-col items-center">
                    <CustomisationIcon
                        className={`stroke-current mb-2.5 ${currentStep === 0 ? 'opacity-100' : 'opacity-50'}`}/>
                    <div className={`relative mb-2.5 w-2.5 h-2.5 rounded-full overflow-visible`}>
                        <span className="absolute h-0.5 bg-white z-10 left-0"
                              style={{top: 4, transform: 'translateX(-100%)', width: '20vw'}}/>
                        <span
                            className="absolute top-0 left-0 w-2.5 h-2.5 rounded-full bg-white z-10 border-2 border-white"/>
                        <span
                            className={`absolute top-0 left-0 w-2.5 h-2.5 rounded-full bg-gold z-20 border-2 border-white ${currentStep === 0 ? 'opacity-100' : 'opacity-50'}`}/>
                    </div>
                    <span
                        className={`text-base font-semibold ${currentStep === 0 ? 'opacity-100' : 'opacity-50'}`}>Customisation</span>
                </div>
                <div className="relative flex flex-col items-center">
                    <DeliveryIcon
                        className={`stroke-current mb-2.5 ${currentStep === 1 ? 'opacity-100' : 'opacity-50'}`}/>
                    <div className={`relative mb-2.5 w-2.5 h-2.5 rounded-full overflow-visible`}>
                        <span
                            className="absolute top-0 left-0 w-2.5 h-2.5 rounded-full bg-white z-10 border-2 border-white"/>
                        <span
                            className={`absolute top-0 left-0 w-2.5 h-2.5 rounded-full bg-gold z-20 border-2 border-white ${currentStep === 1 ? 'opacity-100' : 'opacity-50'}`}/>
                    </div>
                    <span
                        className={`text-base font-semibold ${currentStep === 1 ? 'opacity-100' : 'opacity-50'}`}>Delivery</span>
                </div>
                <div className="relative flex flex-col items-center">
                    <PaymentIcon
                        className={`stroke-current mb-2.5 ${currentStep === 2 ? 'opacity-100' : 'opacity-50'}`}/>
                    <div className={`relative mb-2.5 w-2.5 h-2.5 rounded-full overflow-visible`}>
                        <span
                            className="absolute top-0 left-0 w-2.5 h-2.5 rounded-full bg-white z-10 border-2 border-white"/>
                        <span
                            className={`absolute top-0 left-0 w-2.5 h-2.5 rounded-full bg-gold z-20 border-2 border-white ${currentStep === 2 ? 'opacity-100' : 'opacity-50'}`}/>
                    </div>
                    <span
                        className={`text-base font-semibold ${currentStep === 2 ? 'opacity-100' : 'opacity-50'}`}>Payment</span>
                </div>
                <div className="relative flex flex-col items-center">
                    <ConfirmationIcon
                        className={`stroke-current mb-2.5 ${currentStep === 3 ? 'opacity-100' : 'opacity-50'}`}/>
                    <div className={`relative mb-2.5 w-2.5 h-2.5 rounded-full overflow-visible`}>
                        <span className="absolute h-0.5 bg-white z-10 right-0"
                              style={{top: 4, transform: 'translateX(100%)', width: '20vw'}}/>
                        <span
                            className="absolute top-0 left-0 w-2.5 h-2.5 rounded-full bg-white z-10 border-2 border-white"/>
                        <span
                            className={`absolute top-0 left-0 w-2.5 h-2.5 rounded-full bg-gold z-20 border-2 border-white ${currentStep === 3 ? 'opacity-100' : 'opacity-50'}`}/>
                    </div>
                    <span
                        className={`text-base font-semibold ${currentStep === 3 ? 'opacity-100' : 'opacity-50'}`}>Confirmation</span>
                </div>
            </div>
        </div>
    )
}

export default CheckoutSteps
