import React, { useEffect, useRef, useState } from 'react'
import Modal from "react-modal"
import { CloseIcon, LeftArrowIcon, RightArrowIcon } from "@/Components/Icons"
import Img from "@/Components/UI/Img"

Modal.setAppElement('#app')

const LeftArrow = ({onClick, className}) => (
    <button
        onClick={onClick}
        className={`flex z-10 top-1/2 bg-white text-gray-dark z-10 rounded-full shadow hover:shadow-md focus:ring-0 focus:outline-none flex items-center justify-center fixed ${className}`}
        style={{
            width: 40,
            height: 40,
            transform: 'translateY(-50%)',
            left: 20,
        }}
    >
        <LeftArrowIcon className="stroke-current"/>
    </button>
)

const RightArrow = ({onClick, className}) => (
    <button
        onClick={onClick}
        className={`flex z-10 top-1/2 bg-white text-gray-dark z-10 rounded-full shadow hover:shadow-md focus:ring-0 focus:outline-none flex items-center justify-center fixed ${className}`}
        style={{
            width: 40,
            height: 40,
            transform: 'translateY(-50%)',
            right: 20,
        }}
    >
        <RightArrowIcon className="stroke-current"/>
    </button>
)

const ProductImagesModal = (props) => {
    const container = useRef()
    const carousel = useRef()
    const {isModalOpen, closeModal, images} = props
    const [activeImageIndex, setActiveImageIndex] = useState(0)
    const [containerWidth, setContainerWidth] = useState(0)

    useEffect(() => {
        setupCarousel()
        window.addEventListener('resize', setupCarousel)
        return () => {
            window.removeEventListener('resize', setupCarousel)
        }
    }, [])

    const setupCarousel = () => {
        setContainerWidth(window.innerWidth * images.length)
    }

    const handleClickRightArrow = () => {
        const nextIndex = (activeImageIndex + 1) % images.length
        setActiveImageIndex(nextIndex)
    }

    const handleClickLeftArrow = () => {
        const prevIndex = (activeImageIndex - 1 + images.length) % images.length
        setActiveImageIndex(prevIndex)
    }

    // Close modal when clicking outside of images
    const handleClick = (e) => {
        if (e.target.tagName !== 'IMG') {
            closeModal()
        }
    }

    return (
        <Modal
            id="product-images-modal"
            isOpen={isModalOpen}
            onRequestClose={closeModal}
            contentLabel="Vibeset images"
        >
            <button className="text-white focus:outline-none focus:ring-0 fixed" onClick={closeModal} style={{
                top: 20,
                right: 20,
            }}>
                <CloseIcon className="stroke-current"/>
            </button>
            {images.length > 1 && (
                <>
                    <LeftArrow onClick={handleClickLeftArrow}/>
                    <RightArrow onClick={handleClickRightArrow}/>
                </>
            )}
            <div className="overflow-x-hidden whitespace-nowrap h-full flex items-center" ref={container}
                 onClick={handleClick}>
                <div
                    ref={carousel}
                    className="transition ease-in-out duration-500"
                    style={{
                        width: `${containerWidth}px`,
                        transform: `translateX(${activeImageIndex * window.innerWidth * -1}px)`,
                    }}
                >
                    {images.map((item, index) => (
                        <div
                            key={`image-item-${index}`}
                            className="inline-block text-center"
                            style={{
                                width: window.innerWidth,
                            }}
                        >
                            <Img src={item.src} alt={item.alt} className="w-auto max-w-full object-cover mx-auto"/>
                        </div>
                    ))}
                </div>
            </div>
        </Modal>
    )
}

export default ProductImagesModal
