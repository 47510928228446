import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { InertiaLink } from "@inertiajs/inertia-react"

export function getButtonStyles(type) {
    return `inline-flex
            items-center
            justify-center
            border-0
            focus:outline-none
            focus:ring
            focus:ring-black
            focus:ring-offset-2
            focus:ring-offset-white
            transition
            duration-150
            ease-in-out
            font-bold
            disabled:opacity-50
            disabled:cursor-not-allowed
            whitespace-no-wrap
            ${type === 'primary' ? 'text-white bg-black hover:bg-black px-5 text-base-big h-13' : ''}
            ${type === 'secondary' ? 'text-white bg-black hover:bg-black px-4 text-base-small h-10' : ''}
            ${type === 'tertiary' ? 'text-black bg-white hover:bg-black hover:text-white px-5 text-base-big h-13' : ''}
           `
}

const Button = forwardRef(({children, type, className, as, buttonType, ...props}, ref) => {
    return (
        as === 'button' ? (
            <button
                ref={ref}
                type={buttonType}
                {...props}
                className={`${getButtonStyles(type)} ${className}`}
            >
                {children}
            </button>
        ) : (
            <InertiaLink
                ref={ref}
                {...props}
                className={`${getButtonStyles(type)} ${className}`}
            >
                {children}
            </InertiaLink>
        )
    )
})

Button.defaultProps = {
    type: 'primary',
    as: 'button',
}

Button.propTypes = {
    type: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
    as: PropTypes.oneOf(['link', 'button']),
    className: PropTypes.string,
}

export default Button
