import React, { useContext } from 'react'
import { Inertia } from '@inertiajs/inertia'
import PropTypes from 'prop-types'
import { usePage } from "@inertiajs/inertia-react"
import { AppContext, AppContextInitialValue } from "@/Contexts/app-context"
import {
    getCustomisationPanelContainerTopOffset,
    getProductSectionsAndCategories,
    getTotalData,
} from "@/Components/Vibeset/utils"
import Button from '@/Components/UI/Button'
import { CartIcon, CloseIcon, InfoBubbleIcon } from '@/Components/Icons'
import H5 from '@/Components/UI/H5'
import Link from '@/Components/UI/Link'
import BillProductSection from '@/Components/Vibeset/VibesetBill/BillProductSection'
import Tooltip from "@/Components/Tooltip"

const VibesetBill = ({
                         products,
                         is_summary = false,
                         is_read_only = false,
                     }) => {
    const {site_settings, auth, savedVibeset} = usePage().props
    const {appContext, setAppContext} = useContext(AppContext)
    const currency = appContext.vibeset?.currency ?? 'AED'

    // Construct products object grouped by categories grouped by sections
    let product_sections = getProductSectionsAndCategories(products)

    // Product sections with products selected
    const selectedProductSections = product_sections.filter(product_section => {
        return product_section.product_categories.some(product_category => {
            return product_category.products.some(p => p.pivot.quantity > 0 && p.pivot.is_in_stock)
        })
    })

    // Product sections without products selected
    const unselectedProductSections = product_sections.filter(product_section => {
        const unselectedCategories = product_section.product_categories.filter(product_category => {
            return !product_category.products.some(p => p.pivot.quantity > 0)
        })
        return unselectedCategories.length === product_section.product_categories.length
    })

    // Number of items selected
    const nbItemsSelected = products.reduce((sum, p) => {
        const productSelectionCount = p.pivot?.quantity * p.pivot?.is_in_stock > 0 ? 1 : 0
        return sum + productSelectionCount
    }, 0)

    // Total cost
    const {
        totalSelectedItems,
        totalDeliveryFees,
        totalDispatchTime,
        totalCost,
    } = getTotalData(product_sections, site_settings)


    const handlePickItems = () => {
        setAppContext({
            ...appContext,
            activeProductSectionIndex: AppContextInitialValue.activeProductSectionIndex,
            activeProductCategoryIndex: AppContextInitialValue.activeProductCategoryIndex,
            isMobileBillModalOpened: false,
        })

        window.scrollTo({
            top: getCustomisationPanelContainerTopOffset(),
            left: 0,
            behavior: 'smooth',
        })
    }

    const handleCheckout = () => {
        // Show guest modal for guests
        if (!auth?.user) {
            return setAppContext({
                ...appContext,
                isGuestModalOpen: true,
                redirect: 'checkout',
                isMobileBillModalOpened: false,
            })
        }

        // Save vibeset and redirect to checkout
        handleSaveVibeset(appContext.products, true)
    }

    // Create new saved vibeset or update current one
    const handleSaveVibeset = (products, checkout = false) => {
        // Show guest modal for guests
        if (!auth?.user) {
            return setAppContext(
                {
                    ...appContext,
                    isGuestModalOpen: true,
                    isMobileBillModalOpened: false,
                    redirect: 'saved-vibeset',
                },
            )
        }

        const headers = checkout ? {'checkout': true} : {}

        // Update current saved vibeset
        if (savedVibeset?.id > 0) {
            Inertia.post('/saved-vibeset/update', {
                saved_vibeset_id: savedVibeset.id,
                products: products,
            }, {
                onError: () => {
                    setAppContext({...appContext, isErrorModalOpen: true, isMobileBillModalOpened: false})
                },
                onSuccess: (page) => {
                    if (checkout) {
                        Inertia.visit(`/saved-vibeset/${page.props.savedVibeset?.id}/checkout`)
                    }
                },
                headers,
            })
        } else { // Create new saved vibeset
            Inertia.post('/saved-vibeset', {
                vibeset_id: appContext.vibeset.id,
                products: products,
            }, {
                onError: () => {
                    setAppContext({...appContext, isErrorModalOpen: true, isMobileBillModalOpened: false})
                },
                onSuccess: (page) => {
                    if (checkout) {
                        Inertia.visit(`/saved-vibeset/${page.props.savedVibeset?.id}/checkout`)
                    }
                },
                headers,
            })
        }
    }

    // Select product section
    const handleSelectProductSection = (product_section_id) => {
        const product_section_index = product_sections.findIndex(product_section => product_section.id === product_section_id)

        setAppContext({
            ...appContext,
            activeProductSectionIndex: product_section_index,
            activeProductCategoryIndex: 0,
            isMobileBillModalOpened: false,
        })

        window.scrollTo({
            top: getCustomisationPanelContainerTopOffset(),
            left: 0,
            behavior: 'smooth',
        })
    }

    return (
        <>
            <div className={`bg-gray-light min-h-full navbar:h-full flex flex-col relative`}>
                <div className="navbar:hidden p-4 flex justify-end bg-white">
                    <button
                        className="text-black hover:text-gray-dark focus:outline-none focus:ring"
                        onClick={() => setAppContext({...appContext, isMobileBillModalOpened: false})}>
                        <CloseIcon className="stroke-current"/>
                    </button>
                </div>
                {/* Empty bill */}
                {totalSelectedItems <= 0 ? (
                    <div className="h-full flex-grow flex flex-col items-center relative">
                        <div className="flex flex-grow flex-col items-center justify-center">
                            <CartIcon className="mb-5"/>
                            <p className="text-gray-dark mb-7.5">You haven’t selected any items yet.</p>
                            <Button onClick={handlePickItems}>Pick
                                your items</Button>
                        </div>
                        <div className="mt-auto w-full">
                            {product_sections.map((product_section) => {
                                return (
                                    <div
                                        className="flex items-center justify-between p-4 border-t-2 border-gray-medium bg-teal-dark"
                                        key={`production-section-${product_section.id}`}>
                                            <span className="text-lg font-bold text-white">
                                                {product_section.name}
                                            </span>
                                        <Link as="button"
                                              className="text-white hover:text-white"
                                              onClick={() => handleSelectProductSection(product_section.id)}>Select
                                            items</Link>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                ) : (
                    <div className="px-4 pt-5 flex-grow navbar:h-full flex flex-col">
                        <div className="flex justify-between mb-5">
                            <H5>Your selection</H5>
                            {!is_summary && (
                                <Link as="button" onClick={() => handleSaveVibeset(appContext.products)}>Save and come
                                    back later</Link>
                            )}
                        </div>
                        <div className="-mx-4 px-4 flex flex-col flex-grow overflow-y-auto">
                            {selectedProductSections.map((product_section, index) => {
                                return (
                                    <BillProductSection
                                        key={`production-section-${index}`}
                                        product_section={product_section}
                                        products={products}
                                        is_summary={is_summary}
                                        is_read_only={is_read_only}
                                        handleSaveVibeset={handleSaveVibeset}
                                    />
                                )
                            })}
                        </div>
                        {!is_summary && (
                            <div className="mt-auto">
                                {unselectedProductSections.map((product_section) => {
                                    return (
                                        <div
                                            className="flex items-center justify-between -mx-4 p-4 border-t-2 border-gray-medium bg-teal-dark"
                                            key={`production-section-${product_section.id}`}>
                                            <span className="text-lg font-bold text-white">
                                                {product_section.name}
                                            </span>
                                            <Link as="button"
                                                  className="text-white hover:text-white"
                                                  onClick={() => handleSelectProductSection(product_section.id)}>Select
                                                items</Link>
                                        </div>
                                    )
                                })}
                            </div>
                        )}
                        {(is_summary) ? (
                            <div className="bg-white -mx-4 px-4 py-2.5 border-t-2 border-gray-medium">
                                <div className="flex items-center justify-between gap-x-4 text-sm mb-1.5">
                                    <span>Selected items</span>
                                    <span>{currency} {totalSelectedItems}</span>
                                </div>
                                <div className="flex items-center justify-between gap-x-4 text-sm mb-1.5">
                                    <span>Delivery fees</span>
                                    <span>{currency} {totalDeliveryFees}</span>
                                </div>
                                <div className="flex items-center justify-between gap-x-4 text-sm mb-4">
                                    <span>Dispatch time</span>
                                    <span>Up to {totalDispatchTime} days</span>
                                </div>
                                <div className="flex items-center justify-between gap-x-4 text-base">
                                    <span><span className="font-bold">Total cost</span> <span className="text-sm">(incl. VAT)</span> </span>
                                    <span
                                        className="font-bold">{currency} {totalCost}</span>
                                </div>
                            </div>
                        ) : (
                            <div
                                className="sticky bottom-0 w-[calc(100%+32px)] navbar:relative bg-white text-sm -mx-4 px-4 py-2.5 border-t-2 border-gray-medium">
                                <div className="flex items-center justify-between gap-2">
                                    <div className="flex-grow">
                                        <div className="flex items-center gap-x-4">
                                            <span className="inline-block">{nbItemsSelected} items selected</span>
                                            <span
                                                className="font-bold inline-block">{currency} {totalSelectedItems}</span>
                                        </div>
                                        <div className="flex items-center text-sm">
                                            <span className="mr-2">Only 10% is due at checkout</span>
                                            <Tooltip content="Remaining balance will be fulfilled in due course">
                                                <InfoBubbleIcon className="w-4 h-4 text-black"/>
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <Button type="secondary" onClick={handleCheckout}>Checkout</Button>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </>
    )
}

VibesetBill.propTypes = {
    products: PropTypes.array.isRequired,
    is_summary: PropTypes.bool,
    is_read_only: PropTypes.bool,
}

export default VibesetBill
