import React, { useContext } from 'react'
import { AppContext } from "@/Contexts/app-context"
import Tabs from "@/Components/Tabs"
import CategoryTab from "@/Components/Vibeset/CustomisationTabs/CategoryTab"
import VibesetBill from "@/Components/Vibeset/VibesetBill/VibesetBill"
import MobileVibesetBill from "@/Components/Vibeset/VibesetBill/MobileVibesetBill"
import H2 from "@/Components/UI/H2";
import { PhoneIcon } from "@/Components/Icons";

const CustomisationTabs = ({product_section, index}) => {
    const {appContext, setAppContext} = useContext(AppContext)

    // Update active tab
    const handleUpdateActiveTabIndex = (index) => {
        setAppContext({
            ...appContext,
            activeProductCategoryIndex: index,
        })
    }

    // Only show selected customisation tabs
    if (appContext?.activeProductSectionIndex !== index) {
        return null
    }

    return (
        <div>
            <div className="flex items-center flex-wrap mb-11 laptop:mb-16">
              <a href="tel:0559532920"
                 className="w-full border border-gold p-4 flex items-center text-teal-dark text-2xl font-bold">
                <PhoneIcon/>
                <span>Get a free consultation</span>
              </a>
            </div>

          <div className="navbar:hidden">
            <MobileVibesetBill products={appContext.products} is_summary={false} is_read_only={false}/>
          </div>

          <Tabs
            activeTabIndex={appContext.activeProductCategoryIndex}
            setActiveTabIndex={handleUpdateActiveTabIndex}
          >
            {product_section.product_categories.map((product_category, index) => (
              <Tabs.Tab
                key={`product-category-tab-${index}`}
                title={product_category.name}
              >
                <CategoryTab
                  key={`vibeset-customisation-category-${index}`}
                  product_category={product_category}
                  isLastTab={index === product_section.product_categories.length - 1}
                />
              </Tabs.Tab>
            ))}
          </Tabs>
        </div>
    )
}

export default CustomisationTabs
