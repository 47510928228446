import React, { useEffect, useRef, useState } from 'react'
import { LeftArrowIcon, RightArrowIcon } from "@/Components/Icons"
import Img from "@/Components/UI/Img"

const LeftArrow = ({onClick, className}) => (
    <button
        onClick={onClick}
        className={`flex z-10 left-[10px] laptop:left-[-20px] top-1/2 bg-white text-gray-dark z-10 rounded-full shadow hover:shadow-md focus:ring-0 focus:outline-none flex items-center justify-center absolute ${className}`}
        style={{
            width: 40,
            height: 40,
            transform: 'translateY(-50%)',
        }}
    >
        <LeftArrowIcon className="stroke-current"/>
    </button>
)

const RightArrow = ({onClick, className}) => (
    <button
        onClick={onClick}
        className={`flex z-10 right-[10px] laptop:right-[-20px] top-1/2 bg-white text-gray-dark z-10 rounded-full shadow hover:shadow-md focus:ring-0 focus:outline-none flex items-center justify-center absolute ${className}`}
        style={{
            width: 40,
            height: 40,
            transform: 'translateY(-50%)',
        }}
    >
        <RightArrowIcon className="stroke-current"/>
    </button>
)

const ImageCarousel = ({images}) => {
    const container = useRef()
    const carousel = useRef()
    const [activeImageIndex, setActiveImageIndex] = useState(0)
    const [itemWidth, setItemWidth] = useState(0)

    useEffect(() => {
        setupCarousel()
        window.addEventListener('resize', setupCarousel)
        return () => {
            window.removeEventListener('resize', setupCarousel)
        }
    }, [])

    const setupCarousel = () => {
        if (!container.current) {
            return
        }
        setItemWidth(container.current.clientWidth)
    }


    const handleClickRightArrow = () => {
        const nextIndex = (activeImageIndex + 1) % images.length
        setActiveImageIndex(nextIndex)
    }

    const handleClickLeftArrow = () => {
        const prevIndex = (activeImageIndex - 1 + images.length) % images.length
        setActiveImageIndex(prevIndex)
    }

    return (
        <>
            {/*Carousel*/}
            <div className="relative mb-1">
                {images.length > 1 && (
                    <>
                        <LeftArrow onClick={handleClickLeftArrow}/>
                        <RightArrow onClick={handleClickRightArrow}/>
                    </>
                )}

                <div className="overflow-hidden whitespace-nowrap" ref={container}>
                    <div
                        ref={carousel}
                        className="transition ease-in-out duration-500"
                        style={{
                            width: `${images.length * itemWidth}`,
                            transform: `translateX(${activeImageIndex * itemWidth * -1}px)`,
                        }}
                    >
                        {images.map((item, index) => (
                            <div
                                key={`image-item-${index}`}
                                className="inline-block"
                                style={{
                                    width: itemWidth,
                                }}
                            >
                                <Img src={item.src} alt={item.alt} className="w-full object-cover"/>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/*Thumbnail*/}
            {images.length > 1 && (
                <div className="overflow-x-hidden whitespace-nowrap">
                    <div className="hide-scrollbar overflow-x-auto overflow-y-hidden">
                        {images.map((item, index) => (
                            <button
                                key={`image-thumbnail-item-${index}`}
                                className={`inline-block focus:outline-none focus:ring-0 transition ease-in-out duration-200`}
                                onClick={() => setActiveImageIndex(index)}
                                style={{
                                    width: 64,
                                    marginRight: index !== images.length - 1 ? 5 : 0,
                                    opacity: index === activeImageIndex ? '1' : '0.4',
                                }}
                            >
                                <Img src={item.src} alt={item.alt} className="w-full object-cover"/>
                            </button>
                        ))}
                    </div>
                </div>
            )}
        </>
    )
}

export default ImageCarousel
