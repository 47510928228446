import React from 'react'
import { InertiaLink } from "@inertiajs/inertia-react"
import PropTypes from 'prop-types'

const Link = ({children, className, href, as, onClick, disabled, ...props}) => {
    return (
        as === 'button' ? (
            <button
                className={`inline-block text-sm underline text-black hover:text-teal focus:outline-none focus-visible:ring focus:ring-0 focus-visible:ring-black ${className}`}
                onClick={onClick} disabled={disabled} {...props}>{children}</button>
        ) : (
            <InertiaLink href={href}
                         className={`inline-block text-sm underline text-black hover:text-teal ${className}`} {...props}>{children}</InertiaLink>
        )
    )
}

Link.propTypes = {
    as: PropTypes.oneOf(['link', 'button']),
    className: PropTypes.string,
}

export default Link
