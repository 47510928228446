import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { AppContext, AppContextInitialValue } from "@/Contexts/app-context"
import ImageCarousel from "@/Components/ImageCarousel"
import VibesetBill from "@/Components/Vibeset/VibesetBill/VibesetBill"
import CustomisationTabs from "@/Components/Vibeset/CustomisationTabs/CustomisationTabs"
import { getProductCategories, getProductSectionsAndCategories } from "@/Components/Vibeset/utils"
import EditOrderCustomisationTabs from '@/Components/Vibeset/CustomisationTabs/EditOrderCustomisationTabs'
import { useWindowSize } from "@/Hooks/useWindowSize"
import SmallModal from "@/Components/Modals/SmallModal"
import ActionLink from "@/Components/UI/ActionLink"
import Button from "@/Components/UI/Button"
import FormError from "@/Components/Form/FormError"
import { Head, usePage } from "@inertiajs/inertia-react"

const VibesetTemplate = ({vibeset, products, is_edit_order = false}) => {
    const {flash} = usePage().props
    const {appContext, setAppContext} = useContext(AppContext)
    const windowSize = useWindowSize()

    // Load vibeset and products into context + open saved vibeset success modal on success
    useEffect(() => {
        setAppContext({
            ...appContext,
            activeProductSectionIndex: AppContextInitialValue.activeProductSectionIndex,
            activeProductCategoryIndex: AppContextInitialValue.activeProductCategoryIndex,
            isMobileBillModalOpened: AppContextInitialValue.isMobileBillModalOpened,
            isGuestModalOpen: AppContextInitialValue.isGuestModalOpen,
            isErrorModalOpen: AppContextInitialValue.isErrorModalOpen,
            isSavedVibesetModalOpen: Boolean(flash.success),
            vibeset,
            products,
        })
    }, [products, vibeset, flash])

    // Construct products object grouped by categories grouped by sections
    const product_sections = getProductSectionsAndCategories(appContext.products)

    const outOfStockAndAlternativesProducts = appContext.products?.filter(p => p.pivot.is_alternative || !p.pivot.is_in_stock)
    const editOrderCategories = getProductCategories(outOfStockAndAlternativesProducts)

    if (!appContext.products) return null

    return (
        <>
            <Head>
                <title>{vibeset.name}</title>
                <meta name="description" content={vibeset.description.substring(0, 158)}/>
                <meta property="og:url" content={typeof window !== 'undefined' ? window.location.href : ''}/>
                <meta property="og:type" content="website"/>
                <meta property="og:title" content={vibeset.name}/>
                <meta property="og:description" content={vibeset.description.substring(0, 55)}/>
                <meta property="og:image" content={vibeset.thumbnail.src}/>
            </Head>

            {/*Product description + bill*/}
            <div className="flex flex-col navbar:flex-row">
                <div
                    className={`w-full ${is_edit_order ? 'navbar:w-full' : 'navbar:w-[calc(100%-389px)]'} navbar:pr-8 mt-4.5 navbar:mt-5.5`}>
                    <h1 className="text-black text-h3 leading-h3 font-abril mb-2.5">{vibeset.name}</h1>
                    <p className="max-w-160 mb-4" dangerouslySetInnerHTML={{__html: vibeset.description}}/>
                    <div>
                        <ImageCarousel images={vibeset.images}/>
                    </div>

                    <div id="customisationPanelContainer">
                        {/*Customisation tabs by product section*/}
                        {!is_edit_order && (
                            <div className="pt-11 laptop:pt-16 mb-32">
                                {product_sections.map((product_section, index) => (
                                    <CustomisationTabs
                                        key={`vibeset-customisation-panel-${index}`}
                                        index={index}
                                        product_section={product_section}
                                    />
                                ))}
                            </div>
                        )}

                        {/*Edit order tabs*/}
                        {is_edit_order && (
                            <div className="pt-11 laptop:pt-27 mb-25">
                                <EditOrderCustomisationTabs product_categories={editOrderCategories}/>
                            </div>
                        )}
                    </div>
                </div>

                {/*Bill*/}
                {(windowSize.width >= 1170 && !is_edit_order) && (
                    <div className="hidden navbar:block w-[389px]">
                        <div className="sticky top-[70px] h-[calc(100vh-70px)] border-l-2 border-gray-medium">
                            <VibesetBill
                                products={appContext.products}
                                is_summary={is_edit_order}
                                is_read_only={is_edit_order}
                            />
                        </div>
                    </div>
                )}
            </div>

            {/*Guest modal*/}
            <SmallModal
                isModalOpen={appContext.isGuestModalOpen}
                closeModal={() => setAppContext({...appContext, isGuestModalOpen: false})}
            >
                <div className="mb-7.5">
                    <div className="text-base">
                        You will need to register or login to use this feature.
                    </div>
                </div>
                <div className="flex items-center justify-end">
                    <ActionLink href={`/login`} className="mr-7.5" data={{redirect: appContext.redirect}}>Log
                        in</ActionLink>
                    <Button as="link" href={`/register`} data={{redirect: appContext.redirect}}>Register</Button>
                </div>
            </SmallModal>

            {/*Errors modal*/}
            <SmallModal
                isModalOpen={appContext.isErrorModalOpen}
                closeModal={() => setAppContext({...appContext, isErrorModalOpen: false})}
            >
                <div className="mb-7.5">
                    <FormError>Something went wrong but not to worry our service will resume
                        shortly.</FormError>
                </div>
                <div className="text-right">
                    <Button onClick={() => setAppContext({...appContext, isErrorModalOpen: false})}>Take me
                        back</Button>
                </div>
            </SmallModal>

            {/*Saved vibeset success modal*/}
            <SmallModal
                isModalOpen={appContext.isSavedVibesetModalOpen}
                closeModal={() => setAppContext({...appContext, isSavedVibesetModalOpen: false})}
            >
                <>
                    <div className="mb-7.5">
                        <div className="text-base">
                            Your vibeset is saved! You can find it in your account. You’re only a few clicks away
                            from
                            making your house feel like home.
                        </div>
                    </div>
                    <div className="text-right">
                        <Button onClick={() => setAppContext({...appContext, isSavedVibesetModalOpen: false})}>Got
                            it</Button>
                    </div>
                </>
            </SmallModal>
        </>
    )
}

VibesetTemplate.propTypes = {
    vibeset: PropTypes.object.isRequired,
    products: PropTypes.array.isRequired,
    is_edit_order: PropTypes.bool,
}

export default VibesetTemplate
