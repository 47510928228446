import React, { useState } from 'react'
import Modal from "react-modal"
import { CloseIcon } from "@/Components/Icons"
import TextAreaInput from "@/Components/Form/TextAreaInput"
import { useForm } from "@inertiajs/inertia-react"
import FormError from "@/Components/Form/FormError"
import { error } from "@/utils"
import ActionLink from "@/Components/UI/ActionLink"
import Button from "@/Components/UI/Button"
import CheckboxInput from "@/Components/Form/CheckboxInput"

Modal.setAppElement('#app')

const CancelOrderModal = (props) => {
    const {isModalOpen, closeModal, order, id} = props
    const [internalError, setInternalError] = useState(false)
    const {data, setData, post, errors, reset} = useForm({
        'order_id': order.id,
        // reason: '',
        details: '',
        terms: false,
    })

    const handleSubmit = (e) => {
        e.preventDefault()
        post('/order/cancel')
    }

    const handleCancel = () => {
        reset()
        closeModal()
    }

    return (
        <Modal
            id={id ? id : 'small-modal'}
            isOpen={isModalOpen}
            onRequestClose={closeModal}
            contentLabel="Small modal"
        >
            {closeModal && (
                <button
                    className="inline-block text-black hover:text-gray-dark  focus:ring-0 absolute"
                    onClick={closeModal}
                    style={{
                        top: 15,
                        right: 15,
                    }}
                >
                    <CloseIcon className="stroke-current"/>
                </button>
            )}
            <div className="px-7.5 pb-7.5 pt-10">
                <p className="text-base font-bold mb-5">We are going to cancel your order</p>
                <p className="text-base mb-6">
                    We're sorry your items were out of stock and the alternatives didn't work for you. We are going to
                    cancel your order and refund you the prepaid amount.
                </p>

                {/*<SelectInput*/}
                {/*    label="Reason for deleting account*"*/}
                {/*    name="reason"*/}
                {/*    className="w-full max-w-76 mb-6"*/}
                {/*    value={data.reason}*/}
                {/*    error={error(errors, 'reason')}*/}
                {/*    onChange={(e) => setData('reason', e.target.value)}*/}
                {/*    required*/}
                {/*>*/}
                {/*    <option value="" disabled hidden>Select one</option>*/}
                {/*    {cancel_order_reasons.map((reason, index) => (*/}
                {/*        <option value={reason} key={`order-cancel-reason-${index}`}>{reason}</option>*/}
                {/*    ))}*/}
                {/*</SelectInput>*/}
                {/*<TextAreaInput*/}
                {/*    name="details"*/}
                {/*    label="Please give more details (optional)"*/}
                {/*    className="mb-7.5"*/}
                {/*    textAreaClassName="desktop:max-w-full laptop:max-w-full"*/}
                {/*    value={data.details}*/}
                {/*    onChange={(e) => setData('details', e.target.value)}*/}
                {/*/>*/}

                {/*<CheckboxInput*/}
                {/*    name="terms"*/}
                {/*    checked={data.terms}*/}
                {/*    className="mb-7.5"*/}
                {/*    label="I have read and agreed to the terms and conditions"*/}
                {/*    onChange={() => setData('terms', !data.terms)}*/}
                {/*    error={error(errors, 'terms')}*/}
                {/*/>*/}

                {internalError && (
                    <div className="mb-4">
                        <FormError>An internal error has occurred. Please try again later.</FormError>
                    </div>
                )}

                <div className="flex items-center justify-end flex-wrap mb-4 gap-4">
                    <ActionLink as="button" onClick={handleCancel} className="font-bold">
                        Select an alternative
                    </ActionLink>
                    <Button
                        onClick={handleSubmit}
                        className=""
                    >
                        Cancel order
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export default CancelOrderModal
