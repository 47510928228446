export const quantities = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

export const cancel_order_reasons = [
    'I changed my mind',
    'Too expensive',
    'Doesn\'t suit my need',
    'Find buying interior online difficult',
    'Different from expectation',
    'No longer need it',
    'Other',
]

export const delete_account_reasons = [
    'I changed my mind',
    'Too expensive',
    'Doesn\'t suit my needs',
    'Find buying interior online difficult',
    'Different from expectation',
    'No longer need it',
    'Other',
]


export const emirates = [
    'Abu Dhabi',
    'Ajman',
    'Dubai',
    'Fujairah',
    'Ras Al Khaimah',
    'Sharjah',
    'Umm Al Quwain',
]
