import React from 'react'
import H5 from "@/Components/UI/H5"
import ButtonGold from "@/Components/UI/ButtonGold"
import Img from '@/Components/UI/Img'
import smallRoom from '@/assets/select_room/small_room_image.jpg'
import smallRoom2x from '@/assets/select_room/small_room_image@2x.jpg'
import mediumRoom from '@/assets/select_room/medium_room_image.jpg'
import mediumRoom2x from '@/assets/select_room/medium_room_image@2x.jpg'
import largeRoom from '@/assets/select_room/large_room_image.jpg'
import largeRoom2x from '@/assets/select_room/large_room_image@2x.jpg'
import { RemoveItemIcon } from '@/Components/Icons'

const SelectRoomSize = ({userDimensions, setUserDimensions}) => {
    const handleSelect = (room) => {
        setUserDimensions({
            ...userDimensions,
            room: room,
        })
    }

    const handleUnselect = () => {
        setUserDimensions({
            ...userDimensions,
            room: '',
        })
    }

    return (
        <div className="">
            <H5 className="mb-4">Select a room size</H5>
            <div className="grid grid-cols-1 tablet:grid-cols-2 laptop:grid-cols-3 gap-8 mb-10">
                <div className="p-2.5 relative">
                    <Img className="mb-2.5" src={smallRoom} alt="small room" x2={smallRoom2x}/>
                    <p className="text-base font-bold mb-2.5">Small room</p>
                    <p className="text-base mb-2.5">
                        Up to 10m<sup>2</sup><br/>
                        E.g. single room
                    </p>
                    {userDimensions.room === 'small' ? (
                        <>
                            <div className="absolute top-0 left-0 z-[-1] w-full h-full bg-gold opacity-[0.3]"/>
                            <div className="flex items-center">
                                <ButtonGold className="disabled:!bg-gold-dark disabled:!opacity-100" disabled>
                                    Selected
                                    <span className="w-[16px] h-[8px] border-b border-white border-l -rotate-45 ml-2 relative top-[-3px]"/>
                                </ButtonGold>
                                <button
                                    className="ml-5 hover:opacity-50 rounded-full text-gold focus:ring-gold focus:outline-none focus:ring focus:ring-offset-2 focus:ring-offset-white"
                                    onClick={handleUnselect}
                                >
                                    <RemoveItemIcon/>
                                </button>
                            </div>
                        </>
                    ) : (
                        <ButtonGold onClick={() => handleSelect('small')}>Select</ButtonGold>
                    )}
                </div>
                <div className="p-2.5 relative">
                    <Img className="mb-2.5" src={mediumRoom} alt="medium room" x2={mediumRoom2x}/>
                    <p className="text-base font-bold mb-2.5">Medium room</p>
                    <p className="text-base mb-2.5">
                        Up to 15m<sup>2</sup><br/>
                        E.g. bedroom, dining room
                    </p>
                    {userDimensions.room === 'medium' ? (
                        <>
                            <div className="absolute top-0 left-0 z-[-1] w-full h-full bg-gold opacity-[0.3]"/>
                            <div className="flex items-center">
                                <ButtonGold className="disabled:!bg-gold-dark disabled:!opacity-100" disabled>
                                    Selected
                                    <span className="w-[16px] h-[8px] border-b border-white border-l -rotate-45 ml-2 relative top-[-3px]"/>
                                </ButtonGold>
                                <button
                                    className="ml-5 hover:opacity-50 rounded-full text-gold focus:ring-gold focus:outline-none focus:ring focus:ring-offset-2 focus:ring-offset-white"
                                    onClick={handleUnselect}
                                >
                                    <RemoveItemIcon/>
                                </button>
                            </div>
                        </>
                    ) : (
                        <ButtonGold onClick={() => handleSelect('medium')}>Select</ButtonGold>
                    )}
                </div>
                <div className="p-2.5 relative">
                    <Img className="mb-2.5" src={largeRoom} alt="large room" x2={largeRoom2x}/>
                    <p className="text-base font-bold mb-2.5">Large room</p>
                    <p className="text-base mb-2.5">
                        Up to 25m<sup>2</sup><br/>
                        E.g. living room, master suite
                    </p>
                    {userDimensions.room === 'large' ? (
                        <>
                            <div className="absolute top-0 left-0 z-[-1] w-full h-full bg-gold opacity-[0.3]"/>
                            <div className="flex items-center">
                                <ButtonGold className="disabled:!bg-gold-dark disabled:!opacity-100" disabled>
                                    Selected
                                    <span className="w-[16px] h-[8px] border-b border-white border-l -rotate-45 ml-2 relative top-[-3px]"/>
                                </ButtonGold>
                                <button
                                    className="ml-5 hover:opacity-50 rounded-full text-gold focus:ring-gold focus:outline-none focus:ring focus:ring-offset-2 focus:ring-offset-white"
                                    onClick={handleUnselect}
                                >
                                    <RemoveItemIcon/>
                                </button>
                            </div>
                        </>
                    ) : (
                        <ButtonGold onClick={() => handleSelect('large')}>Select</ButtonGold>
                    )}
                </div>
            </div>
        </div>
    )
}

export default SelectRoomSize
