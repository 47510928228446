import React from 'react'
import FormError from '@/Components/Form/FormError'

export function filesize(size) {
    const i = Math.floor(Math.log(size) / Math.log(1024))
    return (
        (size / Math.pow(1024, i)).toFixed(2) * 1 +
        ' ' +
        ['B', 'kB', 'MB', 'GB', 'TB'][i]
    )
}

// Transforms key/value pairs to FormData() object
export function toFormData(values = {}, method = 'POST') {
    const formData = new FormData()
    for (const field of Object.keys(values)) {
        formData.append(field, values[field])
    }

    // NOTE: When working with Laravel PUT/PATCH requests and FormData
    // you SHOULD send POST request and fake the PUT request like this.
    // More info: http://stackoverflow.com/q/50691938
    if (method.toUpperCase() === 'PUT') {
        formData.append('_method', 'PUT')
    }

    return formData
}

export function translate(language, key, html = false) {
    let translation = language[key]
        ? language[key]
        : ''

    return html ? <span dangerouslySetInnerHTML={{__html: translation}}/> : translation
}

export function kFormatter(num) {
    return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'k' : Math.sign(num) * Math.abs(num)
}

// Given a string representing a date readable for humans in english or french (il y a 3 mois / 3 months ago), removes leading "il y a" or ending "ago"
export function trimDateForHumans(dateForHumans) {
    return dateForHumans.replace(/(^il\sy\sa\s)|(\sago$)/g, '')
}

// Display error message for given error field in given error bag
export function error(errors, field, errorBag = 'default') {
    if (!errors.hasOwnProperty(errorBag)) {
        return null
    }

    if (errors[errorBag].hasOwnProperty(field)) {
        return (
            <FormError>
                {errors[errorBag][field][0]}
            </FormError>
        )
    }

    return null
}

// Check if given error bag is in errors and it has some errors
export function hasErrors(errors, errorBag = 'default') {
    return errors.hasOwnProperty(errorBag) && Object.keys(errors[errorBag]).length > 0
}

export function getUrlParams(search) {
    const hashes = search.slice(search.indexOf('?') + 1).split('&')
    return hashes.reduce((params, hash) => {
        const [key, val] = hash.split('=')
        return Object.assign(params, {[key]: decodeURIComponent(val)})
    }, {})
}

export function getParameterByName(name, uri) {
    if(!uri && typeof window !== 'undefined'){
        uri = window.location.search
    }
    const match = RegExp('[?&]' + name + '=([^&]*)').exec(uri)
    return match && decodeURIComponent(match[1].replace(/\+/g, ' '))
}

// Get the pixel value corresponding to the given rem value assuming root font-size is 16px
export function remToPx(rem) {
    return Number(rem) * 16
}

export function setCookie(name, value, days) {
    var expires = ""
    if (days) {
        var date = new Date()
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000))
        expires = "; expires=" + date.toUTCString()
    }
    if (typeof document !== 'undefined'){
        document.cookie = name + "=" + (value || "") + expires + "; path=/"
    }
}

export function getCookie(name) {
    var nameEQ = name + "="
    var ca = typeof document !== 'undefined' ? document.cookie.split(';') : ''
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i]
        while (c.charAt(0) == ' ') c = c.substring(1, c.length)
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length)
    }
    return null
}
