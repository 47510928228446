import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { usePopperTooltip } from 'react-popper-tooltip'

const Tooltip = ({content = '', placement, children, onSetVisible, onSetInvisible, size, ...props}) => {
    if (typeof window === 'undefined') return null
    const offset = {
        'top-end': [30, 22],
        'top': [0, 22],
    }

    const arrowPosition = {
        'top': {
            normal: {
                bottom: -12,
                right: 95,
            },
            small: {
                bottom: -12,
                right: 50,
            },
        },
        'top-end': {
            normal: {
                bottom: -12,
                right: 24,
            },
            small: {
                bottom: -12,
                right: 24,
            },
        },
    }

    let {
        getTooltipProps,
        setTooltipRef,
        setTriggerRef,
        visible,
    } = usePopperTooltip({
        placement,
        offset: offset[placement],
    })

    useEffect(() => {
        if (visible && typeof onSetVisible === 'function') {
            onSetVisible()
        }

        if (!visible && typeof onSetInvisible === 'function') {
            onSetInvisible()
        }
    }, [visible])

    return (
        <div className={`relative inline-flex`}>
            <button ref={setTriggerRef} {...props}>
                {children}
            </button>
            {visible && (
                <div
                    ref={setTooltipRef}
                    {...getTooltipProps({className: `bg-white p-2 font-normal leading-4 shadow-tooltip transition-opacity ease-in-out duration-100 z-50 text-sm ${size === 'normal' ? 'w-56' : ''} ${size === 'small' ? 'w-34' : ''}`})}
                >
                    <div className="absolute w-0 h-0" style={{
                        bottom: arrowPosition[placement][size]['bottom'],
                        right: arrowPosition[placement][size]['right'],
                        borderStyle: 'solid',
                        borderWidth: '18px 17px 0px',
                        borderColor: 'white transparent transparent',
                    }}/>
                    {content}
                </div>
            )}
        </div>
    )
}

Tooltip.propTypes = {
    children: PropTypes.element.isRequired,
    content: PropTypes.string,
    placement: PropTypes.oneOf(['top', 'top-end']),
    onSetVisible: PropTypes.func,
    onSetInvisible: PropTypes.func,
    size: PropTypes.oneOf(['small', 'normal']),
}

Tooltip.defaultProps = {
    size: 'normal',
    placement: 'top',
}

export default Tooltip
