import React, { useContext, useEffect } from 'react'
import Layout from "@/Components/Layouts/Layout"
import { Head, useForm, usePage } from "@inertiajs/inertia-react"
import CheckoutSteps from "@/Components/Checkout/CheckoutSteps"
import { AppContext } from "@/Contexts/app-context"
import VibesetBill from "@/Components/Vibeset/VibesetBill/VibesetBill"
import UpdatedOrderPaymentStep from '@/Components/Checkout/UpdatedOrderPaymentStep'

const UpdatedOrderCheckout = () => {
    const {vibeset, order} = usePage().props
    const {appContext, setAppContext} = useContext(AppContext)
    const {data, setData, post, errors} = useForm({
        order_number: order.number,
        terms: false,
        is_update: true,
    })

    // The bill uses the context to need to load it with vibeset, products and room
    useEffect(() => {
        setAppContext({
            ...appContext,
            vibeset,
            products: order.products,
        })
    }, [order, vibeset])

    return (
        <>
            <Head>
                <title>Order {order.number} checkout</title>
            </Head>

            <div className="flex flex-col navbar:flex-row">
                <div className="flex-1 mt-5 tablet:mt-10 laptop:px-15 mb-8">
                    <div className="mb-7.5 tablet:mb-15">
                        <CheckoutSteps currentStep={2}/>
                    </div>
                    <div className="flex flex-wrap laptop:px-13">
                        <div className="">
                            <UpdatedOrderPaymentStep
                                data={data}
                                setData={setData}
                                errors={errors}
                                post={post}
                            />
                        </div>
                    </div>
                </div>

                {/*Bill*/}
                <div className="hidden navbar:block w-full max-w-[389px] flex-none">
                    <div className="sticky top-[70px] h-[calc(100vh-70px)] border-l-2 border-gray-medium">
                        <VibesetBill
                            products={order.products}
                            is_summary={true}
                            is_read_only={true}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

UpdatedOrderCheckout.layout = page => <Layout children={page} showFooter={false}/>

export default UpdatedOrderCheckout
