import React, { useState } from 'react'
import { CloseIcon } from "@/Components/Icons"

const OperateInDubai = () => {
    const [showNotice, setShowNotice] = useState(typeof sessionStorage !== 'undefined' ? sessionStorage.getItem('operate-dubai') !== 'seen' : false)

    const handleClose = () => {
        sessionStorage?.setItem('operate-dubai', 'seen');
        setShowNotice(false)
    }

    if (!showNotice) {
        return null
    }

    return (
        <div className="bg-gray-light py-3 text-center text-xs text-black font-medium">
            <div className="max-w-screen-desktop mx-auto w-full text-center relative px-14 laptop:px-8">
                We only operate in Dubai at the moment, but we will be expanding soon!

                <button className="text-gray-dark hover:text-black absolute right-4 laptop:right-8 top-0 translate-y-1/2 tablet:translate-y-0" onClick={handleClose}>
                    <CloseIcon className="w-4 h-4 stroke-current"/>
                </button>
            </div>
        </div>
    )
}

export default OperateInDubai
