import React, { useState } from 'react'
import { DownArrowIcon } from "@/Components/Icons"

const Item = ({title, children, className = '', opened = false}) => {
    const [isOpen, setIsOpen] = useState(opened)

    return (
        <div className={`overflow-hidden relative ${className}`}>
            <button
                className="w-full focus:outline-none focus:ring-0 text-base leading-normal flex items-center relative z-10"
                onClick={() => setIsOpen(!isOpen)}
            >
                <span className={`text-left mr-4 w-full`}>{title}</span>
                <div className="ml-auto transition ease-in-out duration-200 origin-center" style={{
                    transform: `${isOpen ? 'rotate(180deg)' : 'rotate(0)'}`,
                }}>
                    <DownArrowIcon className="text-gray-dark stroke-current"/>
                </div>
            </button>

            <div
                className={`w-full transition-all ease-in-out duration-200 h-auto ${isOpen ? 'max-h-126 opacity-100' : 'max-h-0 opacity-0'}`}>
                <br/>
                {children}
            </div>
        </div>
    )
}

const Accordion = ({className = '', children}) => {
    return (
        <div className={className}>
            {children}
        </div>
    )
}

Accordion.Item = Item

export default Accordion
