import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { AppContext } from "@/Contexts/app-context"
import {
    getCustomisationPanelContainerTopOffset,
    getProductSectionsAndCategories,
    getTotalData,
} from "@/Components/Vibeset/utils"
import Button from '@/Components/UI/Button'
import Link from '@/Components/UI/Link'
import { usePage } from "@inertiajs/inertia-react"
import { Inertia } from "@inertiajs/inertia"
import MobileVibesetBillModal from "@/Components/Vibeset/Modals/MobileVibesetBillModal"
import Tooltip from "@/Components/Tooltip"
import { InfoBubbleIcon } from "@/Components/Icons"

const MobileVibesetBill = ({products, is_read_only, is_summary}) => {
    const {site_settings, auth, savedVibeset} = usePage().props
    const {appContext, setAppContext} = useContext(AppContext)

    const currency = appContext.vibeset?.currency ?? 'AED'

    // Construct products object grouped by categories grouped by sections
    let product_sections = getProductSectionsAndCategories(products)

    // Total cost
    const {
        totalCost,
    } = getTotalData(product_sections, site_settings)

    const handleCheckout = () => {
        // Show guest modal for guests
        if (!auth?.user) {
            return setAppContext({...appContext, isGuestModalOpen: true, redirect: 'checkout'})
        }

        // Save vibeset and redirect to checkout
        handleSaveVibeset(appContext.products, true)
    }

    // Create new saved vibeset or update current one
    const handleSaveVibeset = (products, checkout = false) => {
        // Show guest modal for guests
        if (!auth?.user) {
            return setAppContext({...appContext, isGuestModalOpen: true, redirect: 'saved-vibeset'})
        }

        const headers = checkout ? {'checkout': true} : {}

        // Update current saved vibeset
        if (savedVibeset?.id > 0) {
            Inertia.post('/saved-vibeset/update', {
                saved_vibeset_id: savedVibeset.id,
                products: products,
            }, {
                onError: () => {
                    setAppContext({...appContext, isErrorModalOpen: true})
                },
                onSuccess: (page) => {
                    if (checkout) {
                        Inertia.visit(`/saved-vibeset/${page.props.savedVibeset?.id}/checkout`)
                    }
                },
                headers,
            })
        } else { // Create new saved vibeset
            Inertia.post('/saved-vibeset', {
                vibeset_id: appContext.vibeset.id,
                products: products,
            }, {
                onError: () => {
                    setAppContext({...appContext, isErrorModalOpen: true})
                },
                onSuccess: (page) => {
                    if (checkout) {
                        Inertia.visit(`/saved-vibeset/${page.props.savedVibeset?.id}/checkout`)
                    }
                },
                headers,
            })
        }
    }

    const handleSelectProductSection = (index) => {
        setAppContext({
            ...appContext,
            activeProductSectionIndex: index,
            activeProductCategoryIndex: 0,
            isMobileBillModalOpened: false,
        })

        window.scrollTo({
            top: getCustomisationPanelContainerTopOffset(),
            left: 0,
            behavior: 'smooth',
        })
    }

    return (
        <>
            <div className="navbar:hidden fixed bottom-0 w-full left-0 z-20 bg-white">
                {!is_summary && (
                    <div className="border-t border-gray-medium w-full flex">
                        {product_sections.map((product_section, index) => {
                            const isProductSectionActive = appContext.activeProductSectionIndex === index

                            return (
                                <button
                                    key={index}
                                    className={`flex-grow text-sm py-2.5 px-3.5 text-center ${isProductSectionActive ? 'bg-white text-teal-dark font-bold' : 'bg-teal-dark text-white underline'} ${index !== product_sections.length -1 ? 'border-r border-gray-medium' : ''}`}
                                    onClick={() => handleSelectProductSection(index)}
                                >
                                    {product_section.name}
                                </button>
                            )
                        })}
                    </div>
                )}
                <div
                    className={`p-2.5 flex justify-between items-center gap-4 border-t border-gray-medium flex-wrap`}>
                    <div className={`flex gap-2 ${is_summary ? 'flex items-center justify-between flex-grow' : 'flex-col items-start'}`}>
                        <div className="flex items-center justify-between gap-x-4 text-base">
                            <span><span className="font-bold">Total cost</span> <span className="text-sm">(incl. VAT)</span> </span>
                            <span
                                className="font-bold">{currency} {totalCost}</span>
                        </div>
                        {!is_summary && (
                            <div className="flex items-center text-sm">
                                <span className="mr-2">Only 10% is due at checkout</span>
                                <Tooltip content="Remaining balance will be fulfilled in due course">
                                    <InfoBubbleIcon className="w-4 h-4 text-black"/>
                                </Tooltip>
                            </div>
                        )}
                        <Link className="" as="button" onClick={() => setAppContext({...appContext, isMobileBillModalOpened: true})}>
                            View selected items
                        </Link>
                    </div>
                    {!is_summary && (
                        <Button
                            type="secondary"
                            onClick={handleCheckout}
                            disabled={totalCost === 0}
                        >Checkout</Button>
                    )}
                </div>
            </div>

            <MobileVibesetBillModal
                isModalOpen={appContext.isMobileBillModalOpened}
                closeModal={() => setAppContext({...appContext, isMobileBillModalOpened: false})}
                products={products}
                is_read_only={is_read_only}
                is_summary={is_summary}
            />
        </>
    )
}

MobileVibesetBill.propTypes = {
    products: PropTypes.array.isRequired,
    is_summary: PropTypes.bool,
    is_read_only: PropTypes.bool,
}

export default MobileVibesetBill
