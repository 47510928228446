import React from 'react'
import Layout from "@/Components/Layouts/Layout"
import H1 from "@/Components/UI/H1"
import H5 from "@/Components/UI/H5"
import Link from "@/Components/UI/Link"
import { Head } from "@inertiajs/inertia-react"

export const TERMS = (
    <>
        <p className="mb-8">
            Vibetray is not a furniture shop or a reseller, we are an interior styling platform that helps you
            decorate your room
            by sourcing curated items from different brands and artisans. Please make sure you read the terms
            and conditions
            of each product you’re buying so you can make an informed decision prior to ordering.
        </p>
        <div className="mb-15">
            <H5 className="mb-5">Preamble</H5>
            <p className="mb-4">
                This site is operated by VIBETRAY having its registered office at Downtown Dubai, AL SAAHA
                CWing-W202 and registered with the government of Dubai under number 1075113.
            </p>
            <ul>
                <li>
                    We are not a furniture shop or a reseller, we are an interior styling platform that helps
                    you decorate your room
                    by sourcing curated items.
                </li>
                <li>
                    Some items are custom made, some are local, and some are imported.
                </li>
                <li>
                    Each product has its own Terms and Conditions which are applied by the original seller. The
                    customer must
                    make sure they check the specs of each product they want to order so they can be well
                    informed prior to
                    making a decision.
                </li>
                <li>
                    The present general conditions of sale apply to all orders placed with Vibetray for the
                    whole of the articles and
                    services proposed by www.vibetray.com. Consequently, any person ordering a product offered
                    on sale on the
                    Internet site of Vibetray implies full and complete acceptance of these general conditions
                    of sale that the
                    Customer recognizes having been made aware of those conditions before placing the order.
                </li>
                <li>
                    Vibetray reserves the right to modify at any moment the present general sales conditions.
                    Nevertheless, the
                    General Sales Conditions applicable to the order placed by a Customer on the Site of
                    Vibetray are those
                    accepted by the Customer at the time of placing the order.
                </li>
            </ul>
        </div>

        <div className="mb-15">
            <H5 className="mb-5">1. Orders</H5>
            <ul>
                <li>
                    Once the customer selects their items and completed their purchase they will only pay 10% of
                    their bill amount
                    at that point. Within a 24-48h window Vibetray will confirm the stock availability of all
                    items ordered and
                    should an item be out of stock we will propose alternatives by email.
                </li>
                <li>While rechecking the availability and finetuning the cost, if the supplier puts the item
                    picked on sale during that
                    period, we will notify the customer and show the discount on the bill, similarly if there is
                    a price adjustment, we
                    will also reflect this.
                </li>
                <li> If the customer have requested paint, curtains or flooring, a representative of the related
                    supplier will contact
                    the customer for a visit to confirm the dimensions and to take the customer’s confirmation
                    on the color sample.
                </li>
                <li> If curtains, wall painting or flooring is ordered. When finetuning the dimensions downward
                    or upward, the bill
                    will be adjusted accordingly and the customer will be able to pay the remaining 90% to kick
                    off the order.
                </li>
                <li> The customer will receive an order confirmation to the email address they have provided
                    assuming that the
                    email address indicated in the inscription form does not contain an error. The order
                    confirmation includes a
                    summary of the items that purchased, a price breakdown, and invoice and the delivery
                    address. The Customer
                    must check the completeness and conformity of the information they provide to Vibetray. The
                    latter cannot be
                    held responsible for possible errors of data entry and the consequences in terms of delay or
                    delivery error. In
                    this context, all the expenses engaged for resending will be the responsibility of the
                    Customer.
                </li>
                <li> The Customer can at any moment check the status of their order by consulting the
                    heading &quot;My account&quot;
                    on the www.vibetray.com site. The Customer can at any moment contact the customer service
                    department
                    of Vibetray on <a
                        href="mailto:customerservice@vibetray.com">customerservice@vibetray.com</a> in order to
                    be informed of the stage of their
                    order.
                </li>
                <li> We don’t user the customer’s personal details for anything other than for the purpose of
                    completing their
                    order. The customer has the right to request all information stored about them. For more
                    information
                    regarding how we handle customers’ personal information, please refer to our <Link
                        href="/privacy" className="!text-base">Privacy Policy</Link>.
                </li>
            </ul>
        </div>
        <div className="mb-15">
            <H5 className="mb-5">
                2. Delivery information
            </H5>
            <ul>
                <li> Considering the products ordered are in stock, the suppliers will normally ship the items
                    as soon as
                    possible,
                    within the delays mentioned for each product in the specs sheet.
                </li>
                <li> If the supplier is local, they will send it to the customer directly or contact them to
                    arrange a
                    delivery.
                </li>
                <li> If the supplier is international, the product will be shipped to the customer through
                    Vibetray via
                    Aramex.
                </li>
                <li> Please drop us a line at <a
                    href="mailto:customerservice@vibetray.com">customerservice@vibetray.com</a> regarding
                    delayed shipments so that
                    we can help you as soon as possible. Delays caused by the customer not being present to
                    receive the shipment is out of our control and not subject to a refund.
                    Orders made during weekends and holidays are processed the following working day.
                </li>
            </ul>
        </div>
        <div className="mb-15">
            <H5 className="mb-5">
                3. Prices
            </H5>
            <ul>
                <li> All prices have been translated into AED and are inclusive of shipping, taxes, custom
                    charges and VAT.
                </li>
                <li>
                    For everything that requires contracting like walls, floors and curtains, the prices are
                    inclusive of site visits, installation and fixing, paint however needs an extra charge for
                    colour sampling and site inspection that is added as delivery fees in the bill. Eventually
                    any alteration to the scope of works in the form of an addition, substitution or omission
                    based on site conditions shall be considered on top.
                </li>
                <li> Since the prices charged by Vibetray’s suppliers are in constant evolution, the prices
                    posted on the
                    site are also
                    likely to be modified constantly. We may update our prices at any time. Despite our best
                    effort, a small
                    number
                    of our products might be mispriced from time to time. If this happens, we will contact the
                    customer and
                    ask
                    them whether they wish to continue with the order at the correct price. We will then either
                    cancel the
                    order
                    and refund the money that was paid or correct the price. If we are unable to contact the
                    customer we
                    will
                    cancel the item and process a refund.
                </li>
                <li> Prices at the supplier side might be subject to reviews or sale at any point in time.
                    Vibetray will
                    reflect those
                    changes to the prices on the bill at the time of placing the order so that the customer can
                    benefit from
                    the
                    offers and discounts. Please note that this might impact the exchange and refund policy, as
                    items on
                    sale may
                    not allow for exchanges or refunds. The customer must read the supplier’s T&amp;Cs on the
                    product’s spec
                    sheet.
                </li>
            </ul>
        </div>
        <div className="mb-15">
            <H5 className="mb-5">
                4. Payment
            </H5>
            <ul>
                <li> The customer can place their order with any major credit card (Visa or Mastercard). They
                    can choose
                    their
                    payment method at checkout.
                </li>
                <li> We use the payment gate Stripe that allows the customer to check out with their credit or
                    debit card.
                    Stripe
                    applies 3D Secure/verified by Visa which is a global security standard that we use to ensure
                    the
                    security of our
                    customers. All transactions are protected by SSL-encryption. This means that nobody other
                    than the
                    customer
                    will be able to access the customer’s card details.
                </li>
                <li> As detailed in section 2 – Orders, Vibetray conducts a stock availability check and
                    provides the
                    customer with
                    alternatives if something is out of stock. We also finetune the totals related to
                    contracting for the
                    walls,
                    curtains and flooring based on the visit of our partner to the customer’s premises to make
                    sure the
                    customer is
                    not being charged more than they should. This is why we only charge 10% when placing the
                    order with the
                    remaining 90% being charged when everything is confirmed, and the order is ready to be
                    kicked off.
                </li>
            </ul>
        </div>
        <div className="mb-15">
            <H5 className="mb-5">
                5. Imagery &amp; colors
            </H5>
            <ul>
                <li> Product imagery may vary from the finished product, and we can’t guarantee that the image
                    matches the
                    exact
                    color. Colors on imagery can also vary between different electronic devices such as mac, pc,
                    tablets and
                    mobiles. We try our best to reflect the real view of the product through different
                    images/angles, but we
                    cannot
                    guarantee the view the human eye procures. We may, if needed, change the product description
                    and imagery
                    from time to time and will do so without given notice.
                </li>
                <li> For whatever is related to paint, curtains fabric and flooring colors, our partner will be
                    visiting
                    the customer to
                    show him the samples and adjust the dimensions – so the customer won’t need to worry on this
                    front as
                    Vibetray makes sure he sees the colors and samples live since the colors shown on the screen
                    might not
                    be
                    reflecting the reality and are only indicative.
                </li>
                <li> Please note that all images on Vibetray’s website are subject to copyrights. They are
                    either the
                    property of the
                    different suppliers &amp; partners we deal with or the property of Vibetray like the design
                    of the room.
                </li>
            </ul>
        </div>
        <div className="mb-15">
            <H5 className="mb-5">
                6. Returns and reclamations
            </H5>
            <ul>
                <li> After sales services are handled with the suppliers directly. If the customer is not
                    satisfied with
                    the product,
                    they must contact us at customerservice@vibetray.com by mentioning their order number and
                    the product
                    they wish to return and we will provide them with what they need to handle the case with the
                    supplier
                    direct.
                </li>
                <li> In general, suppliers refund if the item has a defect or is sent wrong and most suppliers
                    accept
                    returns if the
                    item is still in its original condition/packaging and with the original receipt. Shipping
                    costs,
                    delivery costs and
                    taxes however won’t be refundable. For all items that are tailor made or made to fit the
                    customer’s
                    space like
                    the curtains, wallpaper and flooring, returns eventually are not accepted.
                </li>
                <li> Depending on the supplier, refund payouts might be paid back to the customer’s card when
                    they visit
                    them in
                    the shop or processed back to Vibetray. If the refund is paid back to Vibetray the customer
                    must send an
                    email
                    to customerservice@vibetray.com with a proof of return and we will process the refund back
                    once we have
                    received the amount from the supplier.
                </li>
            </ul>
        </div>
        <div className="mb-15">
            <H5 className="mb-5">
                7. Force Majeure
            </H5>
            <ul>
                <li> Neither of the parties will have failed its contractual obligations, insofar as their
                    execution is
                    delayed, blocked
                    or prevented by circumstances beyond their control.
                    Force majeure or circumstances beyond control will be regarded as that which are external to
                    the
                    parties,
                    unforeseeable, inevitable, independent of the will of the parties, and which could not be
                    prevented by
                    them,
                    despite all reasonably possible efforts.
                    When such circumstances happen, Vibetray will warn the customer when possible to examine the
                    situation
                    and
                    reach agreement on the conditions under which the execution of any pending works will be
                    continued.
                </li>
            </ul>
        </div>
    </>
)

const Terms = () => {
    return (
        <>
            <Head>
                <title>Terms and conditions</title>
            </Head>
            <div className="tablet:text-center my-13">
                <H1>Terms & conditions</H1>
            </div>

            <div className="max-w-160 mx-auto">
                {TERMS}
            </div>
        </>
    )
}

Terms.layout = page => <Layout children={page}/>

export default Terms
