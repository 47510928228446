import React, { useContext, useState } from 'react'
import { AppContext } from "@/Contexts/app-context"
import Link from "@/Components/UI/Link"
import Tabs from "@/Components/Tabs"
import CategoryTab from "@/Components/Vibeset/CustomisationTabs/CategoryTab"
import { usePage } from '@inertiajs/inertia-react'
import { Inertia } from '@inertiajs/inertia'
import SmallModal from '@/Components/Modals/SmallModal'
import FormError from '@/Components/Form/FormError'
import Button from '@/Components/UI/Button'
import CancelOrderModal from "@/Components/Modals/CancelOrderModal"
import { getProductSectionsAndCategories, getTotalData } from "@/Components/Vibeset/utils"

const EditOrderCustomisationTabs = ({product_categories}) => {
    const {appContext, setAppContext} = useContext(AppContext)
    const {order, site_settings} = usePage().props
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false)
    const [isCancelOrderModalOpen, setIsCancelOrderModalOpen] = useState(false)
    let product_sections = getProductSectionsAndCategories(appContext.products)
    const {totalCost} = getTotalData(product_sections, site_settings)

    const handleUpdateActiveTabIndex = (index) => {
        setAppContext({
            ...appContext,
            activeProductCategoryIndex: index,
        })
    }

    const handleUpdateOrder = () => {
        if (totalCost === 0) {
            setIsCancelOrderModalOpen(true)
        } else {
            Inertia.post(`/order/edit`, {
                order_id: order.id,
                products: appContext.products,
            }, {
                onError: () => {
                    setIsErrorModalOpen(true)
                },
            })
        }
    }

    if (product_categories.length === 0) return null

    return (
        <div>
            <div className="flex items-center flex-wrap mb-4 laptop:mb-5">
                <h1 className="text-black text-h3 leading-h3 font-abril mr-4 mb-4 tablet:mb-2">Select your
                    alternatives</h1>
                <p className="laptop:ml-auto w-full laptop:w-auto text-sm mb-4 tablet:mb-2 laptop:order-3">
                    For any questions, please visit our <Link href="/faq">FAQs</Link> page or <Link
                    href="/get-in-touch">contact us</Link>.
                </p>
            </div>

            <Tabs
                activeTabIndex={appContext.activeProductCategoryIndex}
                setActiveTabIndex={handleUpdateActiveTabIndex}
            >
                {product_categories.map((product_category, index) => (
                    <Tabs.Tab
                        key={`product-category-tab-${index}`}
                        title={product_category.name}
                    >
                        <CategoryTab
                            key={`vibeset-customisation-category-${index}`}
                            product_category={product_category}
                            isLastTab={index === product_categories.length - 1}
                            handleUpdateOrder={handleUpdateOrder}
                        />
                    </Tabs.Tab>
                ))}
            </Tabs>

            <p className="font-bold text-black text-right mt-4">
                If non of the alternatives work for you, proceed with the update.
            </p>

            {/*Errors modal*/}
            <SmallModal
                isModalOpen={isErrorModalOpen}
                closeModal={() => setIsErrorModalOpen(false)}
            >
                <div className="mb-7.5">
                    <FormError>Something went wrong but not to worry our service will resume
                        shortly.</FormError>
                </div>
                <div className="text-right">
                    <Button onClick={() => setIsErrorModalOpen(false)}>Take me back</Button>
                </div>
            </SmallModal>

            <CancelOrderModal
                isModalOpen={isCancelOrderModalOpen}
                closeModal={() => setIsCancelOrderModalOpen(false)}
                order={order}
            />

        </div>
    )
}

export default EditOrderCustomisationTabs
