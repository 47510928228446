const axios = require('axios')

// Save vibeset and return created vibeset id
export async function saveVibeset(vibeset_id, products) {
    try {
        const res = await axios.post('/saved-vibeset', {
            vibeset_id: vibeset_id,
            products: products,
        })

        return res.data
    } catch (e) {
        console.error(e)
        return false
    }
}

export async function saveVibesetFiles(saved_vibeset_id, files) {
    try {
        const data = new FormData()
        data.append('saved_vibeset_id', saved_vibeset_id)
        for (let x = 0; x < files.length; x++) {
            data.append("files[]", files[x])
        }
        const res = await axios.post('/saved-vibeset/files', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })

        return res.data
    } catch (e) {
        console.error(e)
        return false
    }
}

export async function deleteVibesetFile(saved_vibeset_id, file_id) {
    try {
        const res = await axios.post('/saved-vibeset/deleteFile', {
            saved_vibeset_id: saved_vibeset_id,
            file_id: file_id,
        })
        return res.data
    } catch (e) {
        console.error(e)
        return false
    }
}

export async function getPartialPaymentCheckoutSession(data) {
    try {
        const res = await axios.post('/order/partial-payment/checkout/session', data)

        return res.data
    } catch (e) {
        console.error(e)
        return false
    }
}

export async function getRemainingPaymentCheckoutSession(data) {
    try {
        const res = await axios.post('/order/remaining-payment/checkout/session', data)

        return res.data
    } catch (e) {
        console.error(e)
        return false
    }
}
