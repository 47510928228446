import React, { useState } from 'react'
import Layout from "@/Components/Layouts/Layout"
import H1 from "@/Components/UI/H1"
import TextInput from "@/Components/Form/TextInput"
import Button from "@/Components/UI/Button"
import { Head, usePage } from "@inertiajs/inertia-react"
import { error } from "@/utils"
import { Inertia } from '@inertiajs/inertia'
import Img from "@/Components/UI/Img"
import registerImg from "@/assets/register_page/register_page.jpg"
import registerImg2x from "@/assets/register_page/register_page@2x.jpg"
import registerImgWebp from "@/assets/register_page/register_page.webp"
import registerImgWebp2x from "@/assets/register_page/register_page@2x.webp"
import PasswordInput from "@/Components/Form/PasswordInput"

const ResetPassword = () => {
    const {errors, flash, email, token} = usePage().props

    const [values, setValues] = useState({
        email: email,
        token: token,
        password: '',
    })

    const handleChange = (e) => {
        const key = e.target.id
        const value = e.target.value
        setValues(values => ({
            ...values,
            [key]: value,
        }))
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        Inertia.post('/password/reset', values)
    }

    return (
        <>
            <Head>
                <title>Reset your password</title>
            </Head>
            <div className="text-center desktop:text-left py-13">
                <H1>Reset your password</H1>
            </div>

            <div className="w-screen laptop:w-full flex flex-wrap -mx-4 laptop:mx-0">
                <div
                    className="bg-gray-light py-7.5 px-4 w-full laptop:w-1/2 laptop:py-8 desktop:py-15 flex items-center">
                    {flash.success ? (
                        <div className="max-w-88 mx-auto h-full flex flex-col justify-center">
                            <p>Your password has been changed successfully.</p>
                        </div>
                    ) : (
                        <form className="mx-auto tablet:max-w-xs desktop:max-w-input w-full" onSubmit={handleSubmit}>
                            <TextInput
                                label="Email address"
                                name="email"
                                type="email"
                                className="mb-7.5"
                                error={error(errors, 'email')}
                                value={values.email}
                                onChange={handleChange}
                                disabled
                            />
                            <PasswordInput
                                label="New password*"
                                name="password"
                                className="mb-7.5"
                                error={error(errors, 'password')}
                                onChange={handleChange}
                            />
                            <Button className="">Reset your password</Button>
                        </form>
                    )}
                </div>
                <div className="w-full laptop:w-1/2">
                    <Img
                        src={registerImg}
                        x2={registerImg2x}
                        webp={registerImgWebp}
                        webp2x={registerImgWebp2x}
                        alt="cushions"
                        className="w-full desktop:max-w-full object-cover h-full"
                    />
                </div>
            </div>
        </>

    )
}

ResetPassword.layout = page => <Layout children={page}/>

export default ResetPassword
