import React from 'react'
import H3 from "@/Components/UI/H3";


const FreeConsultation = () => {
    return (
      <div className="flex gap-6 bg-teal-dark p-12 justify-around">
          <H3 className="text-white">Get a free consultation</H3>
          <div className="flex items-center justify-center">
              <a href="tel:0559532920"
                 className="whitespace-nowrap py-4 px-6 text-black bg-white font-bold">
                  Call now
              </a>
          </div>
      </div>
    )
}

export default FreeConsultation
