import React, { useState } from 'react'
import { CookieIcon } from "@/Components/Icons"
import { getCookie, setCookie } from "@/utils"

const CookieNotice = () => {
    const [showCookieNotice, setShowCookieNotice] = useState(getCookie('cookie-accepted') !== 'true')

    const handleClose = () => {
        setCookie('cookie-accepted', true, 365)
        setShowCookieNotice(false)
    }

    if (!showCookieNotice) {
        return null
    }

    return (
        <div className="fixed bottom-0 laptop:bottom-4 z-20 w-full laptop:w-auto">
            <div className="laptop:max-w-screen-desktop laptop:mx-auto w-full -mx-4 tablet:-mx-8 laptop:px-0">
                <div className="bg-teal-dark px-4 pt-5.5 pb-4 flex flex-col items-center w-full laptop:max-w-88">
                    <CookieIcon className="mb-4"/>
                    <p className="text-white text-base leading-normal mb-5 text-center">
                        This website uses cookies. By continuing to use this site, you accept our use of cookies.
                    </p>
                    <button
                        className="text-black bg-white hover:bg-white px-4 text-base-small h-10 inline-flex items-center justify-center border-0 focus:outline-none focus:ring-0 transition duration-150 ease-in-out font-bold whitespace-no-wrap"
                        onClick={handleClose}
                    >
                        Accept & close
                    </button>
                </div>
            </div>
        </div>
    )
}

export default CookieNotice
