import React from 'react'
import Layout from "@/Components/Layouts/Layout"
import { Head, usePage } from "@inertiajs/inertia-react"
import CheckoutSteps from "@/Components/Checkout/CheckoutSteps"
import H2 from "@/Components/UI/H2"
import Img from "@/Components/UI/Img"
import { FacebookIcon, LogoNavBarIcon, PinterestIcon } from "@/Components/Icons"
import H3 from "@/Components/UI/H3"
import Button from "@/Components/UI/Button"

const OrderConfirmation = () => {
    const {order_number, vibeset, auth, is_update} = usePage().props

    const title = vibeset.is_accessory_set ? 'Your accessories are on their way' : 'Your room is on its way'
    const boxText = is_update ? `Your order ${order_number} has been updated` : `Your order number is ${order_number}`
    let description = vibeset.is_accessory_set ? 'Please note items can be returned and refunded within 10 days if kept in their original packaging.' : 'Meanwhile, our team will review your order details, and will validate it once all stock availability has been checked. We will contact you if any change is required.'
    const shareTitle = vibeset.is_accessory_set ? 'Share your new accessory set with your friends' : 'Share your new room with your friends'

    const vibesetUri = encodeURI(`${window.location.origin}/${vibeset.slug}`)

    return (
        <>
            <Head>
                <title>Order confirmed</title>
            </Head>
            <div className="mt-10 laptop:px-15">
                <div className="mb-15">
                    <CheckoutSteps currentStep={3}/>
                </div>
                <div className="text-center mb-13">
                    <H2 className="mb-7.5">{title}</H2>
                    <div
                        className="bg-gray-light w-full max-w-input mx-auto text-center font-bold text-base mb-7.5 py-6">
                        {boxText}
                    </div>
                    <p className="text-base text-center w-full max-w-131 mx-auto mb-7.5">
                        {is_update ? 'Your order has been updated accordingly. ' : ''}
                        We have sent you a confirmation email
                        to <span className="font-bold">{auth.user.email}</span>
                        {!is_update && (
                            <>
                                <br/><br/>
                                {description}
                            </>
                        )}
                    </p>
                    <div className="text-center">
                        <Button as="link" href="/account/orders">To my account</Button>
                    </div>
                </div>
            </div>
            <div className="bg-gray-light p-8 laptop:p-15 flex flex-wrap items-center justify-center">
                <div className="w-full laptop:w-1/2 mb-7.5 laptop:mb-0">
                    <div className="max-w-131 laptop:mr-16 mx-auto">
                        <Img src={vibeset.thumbnail.src} alt={vibeset.thumbnail.alt}
                             className="w-full max-h-76 object-cover"/>
                        <div className="bg-white p-3">
                            <LogoNavBarIcon className="w-23 h-auto"/>
                        </div>
                    </div>
                </div>
                <div className="w-full laptop:w-1/2">
                    <div className="max-w-input laptop:ml-16 mx-auto">
                        <H3 className="mb-7.5 text-center">{shareTitle}</H3>
                        <div className="flex items-center justify-center">
                            <button className="mr-7.5 focus:outline-none focus:ring-0" style={{
                                color: '#4267B2',
                            }}
                                    onClick={() => {
                                        window.open(`https://www.facebook.com/sharer/sharer.php?u=${vibesetUri}`, '', "height=555,width=560")
                                    }}>
                                <FacebookIcon className="fill-current w-13"/>
                            </button>
                            <button className="focus:outline-none focus:ring-0" style={{color: '#E60023'}}
                                    onClick={() => {
                                        window.open(`https://pinterest.com/pin/create/button/?url=${vibesetUri}&media=${vibeset.thumbnail.src}`, '', "height=555,width=560")
                                    }}>
                                <PinterestIcon className="fill-current w-13"/>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

OrderConfirmation.layout = page => <Layout children={page}/>

export default OrderConfirmation
