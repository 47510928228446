import React from 'react'
import Modal from "react-modal"
import { CloseIcon } from "@/Components/Icons"

Modal.setAppElement('#app')

const SmallModal = (props) => {
    const {isModalOpen, closeModal, children, id} = props

    return (
        <Modal
            id={id ? id : 'small-modal'}
            isOpen={isModalOpen}
            onRequestClose={closeModal}
            contentLabel="Small modal"
        >
            {closeModal && (
                <button
                    className="inline-block text-black hover:text-gray-dark  focus:ring-0 absolute"
                    onClick={closeModal}
                    style={{
                        top: 15,
                        right: 15,
                    }}
                >
                    <CloseIcon className="stroke-current"/>
                </button>
            )}
            <div className="px-7.5 pb-7.5 pt-10">
                {children}
            </div>
        </Modal>
    )
}

export default SmallModal
