import React from 'react'
import H2 from "@/Components/UI/H2"
import amtico from "@/assets/brands_carousel/amtico.png"
import lorena from "@/assets/brands_carousel/lorena.png"
import loveme from "@/assets/brands_carousel/loveme.png"
import n74 from "@/assets/brands_carousel/n74.png"
import sandberg from "@/assets/brands_carousel/sandberg.png"
import sol from "@/assets/brands_carousel/sol.png"

import Img from "@/Components/UI/Img"

const BrandsCarousel = () => {
    return (
        <div className="text-center">
            <H2 className="mb-4">Some brands we work with</H2>
            <p className="max-w-input mx-auto mb-7.5">We carefully select every item in each room, working with a
                variety of brands to suit all styles and budgets.</p>

            {/* Animated */}
            <div className="overflow-x-hidden whitespace-nowrap relative">
                <span className="absolute left-0 h-full w-24 laptop:w-80 z-10" style={{
                    background: 'linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0))',
                }}/>
                <span className="absolute right-0 h-full w-24 laptop:w-80 z-10" style={{
                    background: 'linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1))',
                }}/>
                <div className="animate-scroll flex" style={{
                    width: 16 * 168,
                }}>
                    <div className="text-center" style={{
                        width: 168,
                    }}>
                        <Img src={amtico} alt="brand logo"/>
                    </div>
                    <div className="text-center" style={{
                        width: 168,
                    }}>
                        <Img src={lorena} alt="brand logo"/>
                    </div>
                    <div className="text-center" style={{
                        width: 168,
                    }}>
                        <Img src={loveme} alt="brand logo"/>
                    </div>
                    <div className="text-center" style={{
                        width: 168,
                    }}>
                        <Img src={n74} alt="brand logo"/>
                    </div>
                    <div className="text-center" style={{
                        width: 168,
                    }}>
                        <Img src={sandberg} alt="brand logo"/>
                    </div>
                    <div className="text-center" style={{
                        width: 168,
                    }}>
                        <Img src={sol} alt="brand logo"/>
                    </div>

                    <div className="text-center" style={{
                        width: 168,
                    }}>
                        <Img src={amtico} alt="brand logo"/>
                    </div>
                    <div className="text-center" style={{
                        width: 168,
                    }}>
                        <Img src={lorena} alt="brand logo"/>
                    </div>
                    <div className="text-center" style={{
                        width: 168,
                    }}>
                        <Img src={loveme} alt="brand logo"/>
                    </div>
                    <div className="text-center" style={{
                        width: 168,
                    }}>
                        <Img src={n74} alt="brand logo"/>
                    </div>
                    <div className="text-center" style={{
                        width: 168,
                    }}>
                        <Img src={sandberg} alt="brand logo"/>
                    </div>
                    <div className="text-center" style={{
                        width: 168,
                    }}>
                        <Img src={sol} alt="brand logo"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BrandsCarousel
