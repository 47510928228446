import React, { useContext, useEffect, useState } from 'react'
import Modal from "react-modal"
import { CloseIcon } from "@/Components/Icons"
import H3 from "@/Components/UI/H3"
import { AppContext } from "@/Contexts/app-context"
import Button from '@/Components/UI/Button'
import { DEFAULT_USER_DIMENSIONS, getCurtainsQuantity } from '@/Components/Vibeset/utils'
import TextInput from "@/Components/Form/TextInput"
import ButtonGold from "@/Components/UI/ButtonGold"
import Link from "@/Components/UI/Link"

Modal.setAppElement('#app')

const CurtainsModal = (props) => {
    const {appContext, setAppContext} = useContext(AppContext)
    const {isModalOpen, closeModal, product} = props
    const [userDimensions, setUserDimensions] = useState(product.pivot?.user_dimensions || DEFAULT_USER_DIMENSIONS)
    const [width, setWidth] = useState('')
    const [height, setHeight] = useState('')
    const [wallHeight, setWallHeight] = useState('')
    const isAddOpeningDisabled = width === '' || height === '' || wallHeight === ''

    // Update local state userDimensions when product user dimensions are updated and modal is open
    useEffect(() => {
        if(isModalOpen && product.pivot?.user_dimensions){
            setUserDimensions({
                ...product.pivot?.user_dimensions
            })
        }
    }, [isModalOpen, product.pivot?.user_dimensions])

    const handleAddOpening = () => {
        setUserDimensions({
            ...userDimensions,
            curtains: [
                ...userDimensions.curtains,
                {
                    width: width,
                    height: height,
                    wallHeight: wallHeight,
                }
            ]
        })
        setWidth('')
        setHeight('')
        setWallHeight('')
    }

    const handleRemoveOpening = (index) => {
        const curtainsCopy = userDimensions.curtains.slice()
        curtainsCopy.splice(index, 1)
        setUserDimensions({
            ...userDimensions,
            curtains: curtainsCopy,
        })
    }

    const handleSubmit = () => {
        const curtainsQuantity = getCurtainsQuantity(product, userDimensions)
        setAppContext({
            ...appContext,
            products: appContext.products.map(p => {
                return p.id === product.id ? {...p, pivot: {...p.pivot, quantity: curtainsQuantity, user_dimensions: userDimensions}} : p
            }),
        })
        closeModal()
    }

    return (
        <Modal
            className="room-size-modal"
            isOpen={isModalOpen}
            onRequestClose={closeModal}
        >
            <div className="py-7.5 px-7.5 laptop:px-15 laptop:pb-15">
                <div className="flex items-start flex-wrap justify-between mb-5">
                    <H3 className="inline-block">
                        Calculate opening size
                    </H3>
                    <div className="w-full tablet:w-auto order-first tablet:order-last mb-4 tablet:mb-0 text-right">
                        <button
                            className="inline-block text-black hover:text-gray-dark focus:outline-none focus:ring-0"
                            onClick={closeModal}>
                            <CloseIcon className="stroke-current"/>
                        </button>
                    </div>
                </div>
                <p className="text-base mb-7.5">
                    Enter the size of the openings (windows or doors) that you wish to add curtains to, and the height of your wall. These measurements will be used to calculate an estimated price, but one of our partners will do a site visit to confirm the conditions and dimensions of your space. The final price will be adjusted accordingly.
                </p>

                <div className="flex flex-wrap gap-8 laptop:gap-x-14 items-end mb-4">
                    <TextInput
                        label="Opening width"
                        type="number"
                        min={1}
                        value={width}
                        className="w-32"
                        onChange={(e) => {
                            const value = Number(e.target.value) > 0 ? Number(e.target.value) : ''
                            setWidth(value)
                        }}
                        placeholder="cm"
                    />
                    <TextInput
                        label="Opening height*"
                        type="number"
                        min={1}
                        value={height}
                        className="w-32"
                        onChange={(e) => {
                            const value = Number(e.target.value) > 0 ? Number(e.target.value) : ''
                            setHeight(value)
                        }}
                        placeholder="cm"
                    />
                    <TextInput
                        label="Wall height"
                        type="number"
                        min={1}
                        value={wallHeight}
                        className="w-32"
                        onChange={(e) => {
                            const value = Number(e.target.value) > 0 ? Number(e.target.value) : ''
                            setWallHeight(value)
                        }}
                        placeholder="cm"
                    />
                    <ButtonGold
                        className=""
                        onClick={handleAddOpening}
                        disabled={isAddOpeningDisabled}
                    >
                        Add opening
                    </ButtonGold>
                </div>


                {userDimensions.curtains?.map((opening, index) => (
                    <div className="flex flex-wrap items-center gap-4 laptop:gap-x-14 mb-5" key={index}>
                        <p className="font-bold w-32">Opening {index + 1}</p>
                        <div className="w-[312px] flex items-center gap-x-2">
                            <span>W: {opening.width} cm</span>
                            <span>H: {opening.height} cm</span>
                            <span>WH: {opening.wallHeight} cm</span>
                        </div>
                        <Link as="button" onClick={() => handleRemoveOpening(index)}>
                            Remove
                        </Link>
                    </div>
                ))}


                <div className="flex flex-wrap items-center gap-4 mt-8">
                    <p className="w-full laptop:w-auto">
                        *Enter the height from the top of your window or door to the floor.
                    </p>
                    <div className="ml-auto flex items-center">
                        <button
                            onClick={closeModal}
                            className={`mr-7.5 font-bold text-base text-gray-dark p-2`}
                        >
                            Cancel
                        </button>
                        <Button onClick={handleSubmit}>
                            Submit
                        </Button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default CurtainsModal
