import React from 'react'
import Label from "@/Components/Form/Label"

const TextAreaInput = ({label, name, className, textAreaClassName = '', error, ...props}) => {
    return (
        <div className={className}>
            {label && (
                <Label name={name}>
                    {label}
                </Label>
            )}
            <textarea
                id={name}
                name={name}
                {...props}
                className={`px-2.5 h-8.5 min-h-37 leading-normal block w-full max-w-full tablet:max-w-xs desktop:max-w-input border border-gray-dark focus:border-teal focus:border focus:ring-0 placeholder-gray-dark text-black bg-white font-sans text-left appearance-none relative ${error ? 'border-red' : ''} ${textAreaClassName}`}
                style={{resize: 'none', ...props.style}}
            />
            {error}
        </div>
    )
}

export default TextAreaInput
