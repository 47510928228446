import React, { useContext, useEffect, useState } from 'react'
import Product from "@/Components/Vibeset/CustomisationTabs/Product"
import SelectInput from "@/Components/Form/SelectInput"
import { AppContext } from "@/Contexts/app-context"
import Button from "@/Components/UI/Button"
import { usePage } from "@inertiajs/inertia-react"
import {
    getCustomisationPanelContainerTopOffset,
    getProductSectionsAndCategories,
    getTotalData,
} from '@/Components/Vibeset/utils'
import ActionLink from '@/Components/UI/ActionLink'

const CategoryTab = ({product_category, isLastTab, showNavigation = true, handleUpdateOrder}) => {
    const {is_edit_order} = usePage().props
    const {appContext, setAppContext} = useContext(AppContext)

    // Selected product
    const selectedProduct = product_category.products.find(product => product.pivot.quantity > 0 && product.pivot.is_in_stock)

    const [quantity, setQuantity] = useState(selectedProduct?.pivot?.quantity || 1)
    const [productsShown, setProductsShown] = useState(product_category.products)

    // Get product sizes
    let sizes = product_category.products.map(product => product.size).filter(size => size?.length > 0)
    sizes = sizes.filter((size, index) => sizes.indexOf(size) === index)
    sizes = ['All sizes', ...sizes]
    const [selectedSize, setSelectedSize] = useState(sizes[0])

    // Only show sizes filter for furniture and if there are different sizes in the products
    const showSizesDropdown = product_category.product_section.name === 'Furniture' && sizes.length > 2

    // Disable prev button on first section, first category
    const isPrevItemDisabled = appContext.activeProductCategoryIndex === 0 && appContext.activeProductSectionIndex === 0

    // Filter products by size
    useEffect(() => {
        if (selectedSize === sizes[0]) { // All products
            setProductsShown(product_category.products)
        } else { // Products filtered by size
            setProductsShown(product_category.products.filter(product => product.size === selectedSize))
        }
    }, [selectedSize, product_category])

    // Update selected products quantity
    useEffect(() => {
        setAppContext({
            ...appContext,
            products: appContext.products.map(p => {
                if (p.id === selectedProduct?.id) {
                    return {...p, pivot: {...p.pivot, quantity: quantity}}
                } else {
                    return p
                }
            }),
        })
    }, [quantity])

    // Move to next tab
    const handleNextItem = () => {
        const product_sections = getProductSectionsAndCategories(appContext.products)
        const nextSectionIndex = appContext.activeProductSectionIndex + 1 === product_sections.length ? 0 : appContext.activeProductSectionIndex + 1
        setAppContext({
            ...appContext,
            activeProductCategoryIndex: isLastTab ? 0 : appContext.activeProductCategoryIndex + 1,
            activeProductSectionIndex: isLastTab ? nextSectionIndex : appContext.activeProductSectionIndex
        })

        window.scrollTo({
            top: getCustomisationPanelContainerTopOffset(),
            left: 0,
            behavior: 'smooth',
        })
    }

    // Move to prev tab
    const handlePrevItem = () => {
        const product_sections = getProductSectionsAndCategories(appContext.products)
        const prevSectionIndex = appContext.activeProductSectionIndex - 1 < 0 ? product_sections.length - 1 : appContext.activeProductSectionIndex - 1
        const isFirstCategory = appContext.activeProductCategoryIndex === 0
        setAppContext({
            ...appContext,
            activeProductCategoryIndex: isFirstCategory ? 0 : Math.max(appContext.activeProductCategoryIndex - 1, 0),
            activeProductSectionIndex: isFirstCategory ? prevSectionIndex : appContext.activeProductSectionIndex
        })

        window.scrollTo({
            top: getCustomisationPanelContainerTopOffset(),
            left: 0,
            behavior: 'smooth',
        })
    }

    return (
        <div>
            <div className="flex items-center mb-4 mr-4 flex-wrap justify-between">
                <h2 className="text-2xl font-bold">{product_category.name}</h2>
            </div>

            <div className={`relative`}>
                {product_category.description?.length > 0 && (
                    <div className="mb-10" dangerouslySetInnerHTML={{__html: product_category.description}}/>
                )}

                {showSizesDropdown && (
                    <div
                        className="mb-10 flex items-start tablet:justify-start tablet:items-center flex-wrap flex-col tablet:flex-row">
                        <div className="flex items-center flex-wrap">
                            <label htmlFor="size"
                                   className="w-full tablet:w-auto mr-2.5 mb-2 tablet:mb-0 select-none">Select
                                size</label>
                            <SelectInput
                                name="size"
                                className="w-full max-w-60 mb-4 tablet:mb-0 tablet:w-48 tablet:mr-13"
                                value={selectedSize}
                                onChange={(e) => setSelectedSize(e.target.value)}
                            >
                                {sizes.map((size, index) => (
                                    <option value={size} key={`size-${index}`}>{size}</option>
                                ))}
                            </SelectInput>
                        </div>
                    </div>
                )}

                <div className={`grid grid-cols-1 tablet:grid-cols-2 gap-x-16 gap-y-10 mb-7.5 relative`}>
                    {productsShown.map((product, index) => (
                            <Product
                                key={`product-${index}`}
                                product={product}
                                setQuantity={setQuantity}
                            />
                        ),
                    )}
                </div>
            </div>

            <div className="flex flex-wrap justify-end items-end">
                <div>
                    {showNavigation && (
                        <div className="flex items-center justify-end">
                            <>
                                <ActionLink
                                    as="button"
                                    onClick={handlePrevItem}
                                    className={`mr-7.5 font-bold text-base ${isPrevItemDisabled ? 'cursor-not-allowed hover:text-gray-dark' : ''}`}
                                    disabled={isPrevItemDisabled}
                                >
                                    Prev item
                                </ActionLink>
                                {(isLastTab && is_edit_order) && (
                                    <Button
                                        onClick={handleUpdateOrder}
                                    >
                                        Update order
                                    </Button>
                                )}

                                {!is_edit_order && (
                                    <Button onClick={handleNextItem}>Next item</Button>
                                )}
                            </>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default CategoryTab
