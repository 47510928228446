import React from 'react'
import Layout from "@/Components/Layouts/Layout"
import AccountMenu from "@/Components/AccountMenu"
import { Head } from "@inertiajs/inertia-react"

const Account = () => {
    return (
        <>
            <Head>
                <title>Account</title>
            </Head>
            <div className="mt-5 navbar:mt-15 flex flex-wrap">
                <div className="w-full laptop:w-59 flex-shrink-0">
                    <AccountMenu/>
                </div>
            </div>
        </>
    )
}

Account.layout = page => <Layout children={page}/>

export default Account
