import React from 'react'
import Label from "@/Components/Form/Label"

const TextInput = ({label, name, className, error, size = '', inputClassName = '', ...props}) => {
    return (
        <div className={className}>
            {label && (
                <Label name={name}>
                    {label}
                </Label>
            )}
            <input
                id={name}
                name={name}
                {...props}
                className={`px-2.5 h-8.5 leading-normal block ${size === 'small' ? 'w-17' : 'w-full'} tablet:max-w-xs desktop:max-w-input border border-gray-dark focus:border-teal focus:border focus:ring-0 placeholder-gray-dark text-black bg-white font-sans text-left appearance-none relative ${inputClassName} ${error ? 'border-red' : ''}`}
            />
            {error}
        </div>
    )
}

export default TextInput
