import React, { useState } from 'react'
import { usePage } from "@inertiajs/inertia-react"
import { StripeIcon } from "@/Components/Icons"
import { error } from "@/utils"
import CheckboxInput from "@/Components/Form/CheckboxInput"
import Button from "@/Components/UI/Button"
import ActionLink from '@/Components/UI/ActionLink'
import { loadStripe } from "@stripe/stripe-js"
import { getPartialPaymentCheckoutSession } from '@/api'
import FormError from "@/Components/Form/FormError"
import Accordion from "@/Components/Accordion"
import { TERMS } from "@/Pages/Terms"

const stripePromise = loadStripe(process.env.MIX_STRIPE_PUBLIC_KEY)

const PaymentStep = ({data, setData, errors, post, setStep}) => {
    const {vibeset, savedVibeset, site_settings} = usePage().props
    const [internalError, setInternalError] = useState(false)

    // Create order, get session id, then redirect to checkout page
    const handleSubmit = async (e) => {
        e.preventDefault()
        post('/order', {
            preserveScroll: true,
            onSuccess: async (data) => {
                try {
                    const order_number = data.props.flash?.success
                    const session_id = await getPartialPaymentCheckoutSession(
                        {
                            'order_number': order_number,
                            'saved_vibeset_id': savedVibeset.id,
                        })
                    const stripe = await stripePromise
                    const result = await stripe.redirectToCheckout({
                        sessionId: session_id,
                    })
                    if (result.error) {
                        console.error(result.error.message)
                    }
                } catch (e) {
                    setInternalError(true)
                }
            },
        })
    }

    const renderDownPaymentTitle = () => (
        <div className="flex items-center font-bold w-full text-base">
                        <span className="w-1/2">
                            Down payment
                        </span>
            <span className="ml-4">{vibeset.currency} {savedVibeset.partial_cost_incl_vat}</span>
        </div>
    )

    const renderAfterValidationTitle = () => (
        <div className="flex items-center font-bold w-full text-base">
                        <span className="w-1/2">
                            After validation
                        </span>
            <span
                className="ml-4">{vibeset.currency} {savedVibeset.total_cost_incl_vat - savedVibeset.partial_cost_incl_vat}</span>
        </div>
    )

    return (
        <div className="max-w-131">
            <h1 className="font-bold text-2xl mb-4">Payment</h1>
            <p className="text-base mb-10">Only 10% of the selected items is due now. The remaining 90% and delivery fees will be due within 24-48 hours after
                we’ve checked the stock availability, proposed alternatives if needed, and confirmed your
                order.<br/><br/> Here is a breakdown of your
                payment:</p>
            <div className="text-base max-w-[360px]">
                <div className="flex relative">
                    <span className="absolute h-full w-0.5 bg-gray-dark left-[13px] top-6.5"/>
                    <div className="relative mr-2.5">
                        <span
                            className="flex items-center justify-center w-6.5 h-6.5 bg-gray-dark text-white font-bold rounded-full">1</span>
                    </div>
                    <Accordion className="w-full">
                        <Accordion.Item title={renderDownPaymentTitle()} opened={true} className="mb-7">
                            <div className="-mt-4 text-sm">
                                <div
                                    className="mb-2.5">{Math.round(site_settings.partial_cost_upfront_percentage * 100)}%
                                    of selected items
                                </div>
                                <span
                                    className="inline-block bg-teal-dark font-semibold text-xs px-2 py-1.5 text-white">DUE NOW</span>
                            </div>
                        </Accordion.Item>
                    </Accordion>
                </div>
                <div className="flex">
                    <div className="relative mr-2.5">
                        <span
                            className="flex items-center justify-center w-6.5 h-6.5 bg-gray-dark text-white font-bold rounded-full">2</span>
                    </div>
                    <Accordion className="w-full">
                        <Accordion.Item title={renderAfterValidationTitle()} opened={true}>
                            <div className="-mt-4 text-sm">
                                <div
                                    className="mb-2.5">{100 - Math.round(site_settings.partial_cost_upfront_percentage * 100)}%
                                    of selected items and delivery fees
                                </div>
                                <div className="mb-2.5 flex items-center w-[calc(100%-33px)]">
                                    <span className="w-1/2">Delivery fees</span>
                                    <span className="ml-4">{vibeset.currency} {savedVibeset.total_delivery_fees}</span>
                                </div>
                                <div className="mb-2.5 flex items-center w-[calc(100%-33px)]">
                                    <span className="w-1/2">VAT</span>
                                    <span className="ml-4">{vibeset.currency} {savedVibeset.vat}</span>
                                </div>
                                <div className="mb-2.5 flex items-center w-[calc(100%-33px)]">
                                    <span className="w-1/2">Total</span>
                                    <span className="ml-4">{vibeset.currency} {savedVibeset.total_cost_incl_vat}</span>
                                </div>
                            </div>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </div>

            <p className="text-base mt-4 mb-15">
                Delivery fees include delivering all the ordered items from all the different suppliers.
            </p>

            <hr className="border-b border-gray-light mb-7.5"/>

            <form onSubmit={handleSubmit}>
                <h2 className="text-teal font-bold text-xl mb-4">Terms & conditions</h2>

                <div className="border border-gray-dark h-50 text-base overflow-auto p-2 mb-4">
                    {TERMS}
                </div>

                <CheckboxInput
                    name="terms"
                    checked={data.terms}
                    className="mb-7.5"
                    label="I have read and agreed to the terms and conditions"
                    onChange={() => setData('terms', !data.terms)}
                    error={error(errors, 'terms')}
                />

                {internalError && (
                    <div className="mb-4">
                        <FormError>An internal error has occurred. Please try again later.</FormError>
                    </div>
                )}

                <div
                    className="flex items-center flex-wrap laptop:justify-end mb-4">
                    <ActionLink as="button" className="mr-7.5 mb-4 tablet:mb-0" onClick={() => setStep(1)}>Go
                        back</ActionLink>
                    <Button className="mb-4 tablet:mb-0">Checkout</Button>
                </div>
                <div className="flex items-center laptop:justify-end text-gray-dark text-sm">
                    <span>Powered by</span>
                    <StripeIcon className="w-12"/>
                </div>
            </form>
        </div>
    )
}

export default PaymentStep
