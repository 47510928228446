import React from 'react'

const CheckboxInput = ({label, name, className, error, disabled, ...props}) => {
    return (
        <div className={className}>
            <div className={`flex items-center`}>
                <input
                    id={name}
                    name={name}
                    type="checkbox"
                    className="h-4.5 w-4.5 text-teal focus:ring-0 border-gray-dark focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed"
                    disabled={disabled}
                    {...props}
                />
                {label && (
                    <label htmlFor={name}
                           className={`ml-2.5 block text-sm text-black ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}>
                        {label}
                    </label>
                )}
            </div>
            {error}
        </div>
    )
}

export default CheckboxInput
