import React from 'react'
import Layout from "@/Components/Layouts/Layout"
import H1 from "@/Components/UI/H1"
import H2 from "@/Components/UI/H2"
import H5 from "@/Components/UI/H5"
import Img from "@/Components/UI/Img"
import about1 from "@/assets/about_us_page/image_1.jpg"
import about12x from "@/assets/about_us_page/image_1@2x.jpg"
import about1Webp from "@/assets/about_us_page/image_1.webp"
import about1Webp2x from "@/assets/about_us_page/image_1@2x.webp"
import about2 from "@/assets/about_us_page/image_2.jpg"
import about22x from "@/assets/about_us_page/image_2@2x.jpg"
import about2Webp from "@/assets/about_us_page/image_2.webp"
import about2Webp2x from "@/assets/about_us_page/image_2@2x.webp"
import about3 from "@/assets/about_us_page/image_3.jpg"
import about32x from "@/assets/about_us_page/image_3@2x.jpg"
import about3Webp from "@/assets/about_us_page/image_3.webp"
import about3Webp2x from "@/assets/about_us_page/image_3@2x.webp"
import founder1 from "@/assets/about_us_page/wendell_castle_title.jpg"
import founder12x from "@/assets/about_us_page/wendell_castle_title@2x.jpg"
import founder1Webp from "@/assets/about_us_page/wendell_castle_title.webp"
import founder1Webp2x from "@/assets/about_us_page/wendell_castle_title@2x.webp"
import founder2 from "@/assets/about_us_page/frank_gehry_title.jpg"
import founder22x from "@/assets/about_us_page/frank_gehry_title@2x.jpg"
import founder2Webp from "@/assets/about_us_page/frank_gehry_title.webp"
import founder2Webp2x from "@/assets/about_us_page/frank_gehry_title@2x.webp"
import founder3 from "@/assets/about_us_page/joseph_walsh_title.jpg"
import founder32x from "@/assets/about_us_page/joseph_walsh_title@2x.jpg"
import founder3Webp from "@/assets/about_us_page/joseph_walsh_title.webp"
import founder3Webp2x from "@/assets/about_us_page/joseph_walsh_title@2x.webp"
import founder4 from "@/assets/about_us_page/marcel_wanders_title.jpg"
import founder42x from "@/assets/about_us_page/marcel_wanders_title@2x.jpg"
import founder4Webp from "@/assets/about_us_page/marcel_wanders_title.webp"
import founder4Webp2x from "@/assets/about_us_page/marcel_wanders_title@2x.webp"
import { Head } from "@inertiajs/inertia-react"

const About = () => {
    return (
        <>
            <Head>
                <title>About our house</title>
            </Head>
            <div className="max-w-5.5xl w-full mx-auto mt-5 laptop:mt-24">
                <div className="flex flex-wrap mb-20 laptop:mb-37 laptop:items-center">
                    <div className="w-full laptop:w-1/2 mb-5 laptop:mb-0">
                        <Img
                            src={about1}
                            x2={about12x}
                            webp={about1Webp}
                            webp2x={about1Webp2x}
                            alt="lamp, roses and dining room"
                            className="max-w-full laptop:max-w-90% mx-auto laptop:ml-0"
                        />
                    </div>
                    <div className="w-full laptop:w-1/2">
                        <div className="max-w-131 mx-auto laptop:mr-0">
                            <H1 className="mb-5">Interiors for everyone</H1>
                            <p>How many times have you checked magazines for inspiration, pinned pictures up, excited to
                                design your room—only to abandon it all, frustrated with the struggle of execution?
                                Design
                                mishaps are no joke. Home pieces are often costly and can’t be changed
                                frequently.<br/><br/>
                                At Vibetray, we believe that interior design should not be limited to the privileged
                                few.
                                Our aim is to open up that funnel so everyone can indulge in beautiful interiors without
                                having to appoint an interior designer.<br/><br/>
                                Sitting at the intersection of curation and tech, we deliver creative styling and
                                thorough
                                execution through our network of professional partners.</p>
                        </div>
                    </div>
                </div>
                <div className="flex flex-wrap mb-20 laptop:mb-37 laptop:items-center">
                    <div className="w-full laptop:w-1/2 mb-5 order-first laptop:order-last">
                        <Img
                            src={about2}
                            x2={about22x}
                            webp={about2Webp}
                            webp2x={about2Webp2x}
                            alt="bed, woman drinking tea"
                            className="max-w-full laptop:max-w-90% mx-auto laptop:mr-0"
                        />
                    </div>

                    <div className="w-full laptop:w-1/2">
                        <div className="max-w-input mx-auto laptop:ml-0">
                            <H2 className="mb-5">We do the legwork</H2>
                            <p>Designed in a variety of styles, our looks are made to match all tastes and moods. Our
                                interior styling experts make sure to source each piece, whilst giving you the
                                flexibility of choice according to your needs and budget requirements.<br/><br/>
                                We constantly update our collections, giving you fresh new looks for you to customise
                                and adapt to your room. Sip your coffee, order the sets from the comfort of your home
                                and leave the rest to our partners.</p>
                        </div>
                    </div>
                </div>
                <div className="flex flex-wrap mb-20 laptop:mb-37 laptop:items-center">
                    <div className="w-full laptop:w-1/2 mb-5">
                        <Img
                            src={about3}
                            x2={about32x}
                            webp={about3Webp}
                            webp2x={about3Webp2x}
                            alt="craft"
                            className="max-w-full laptop:max-w-90% mx-auto laptop:ml-0"
                        />
                    </div>
                    <div className="w-full laptop:w-1/2">
                        <div className="mx-auto laptop:mr-0 max-w-input">
                            <H2 className="mb-5">The off-piste</H2>
                            <p>Vibetray supports local designers, artisans and small businesses by promoting their
                                unique
                                handmade pieces.<br/><br/>
                                They are key to us, as they greatly contribute and influence the world of interiors by
                                bringing in ideas, cultural heritage and crafting excellence.<br/><br/>
                                Our off-piste pieces are sure to make any head turn with that creative flair and
                                originality
                                they bring to any room they sit in.</p>
                        </div>
                    </div>
                </div>
                <div className="mb-20 text-center max-w-131 mx-auto">
                    <H2 className="mb-5">The story of Vibetray</H2>
                    <p>The passion of styling had owned the soul of the founder for many years, before she decided to
                        drop a 16-year career in Strategy and Planning and head to Milan to indulge into her interior
                        styling learnings. She built her knowledge in this domain by herself and was eager to change
                        every space she steps into…<br/><br/>
                        She had the idea to reinvent the myth of interior design through a catalog of stylish curated
                        interiors ready made for people to just pick and get hold of them just like a dress you order
                        online. Templates were drawn and the platform kicks off.<br/><br/>
                        Fresh, warm, cool or elegant, the interiors of Vibetray are full of style. Inspired by that
                        romantic and elegant « je-ne-sais-quoi » of Milan and Paris the founder loves most, she imagines
                        interiors with a sophisticated flair that uplift your mood and brighten your space with grace.
                    </p>
                </div>
            </div>

            <div className="mb-5">
                <H2 className="text-center mb-7.5 laptop:mb-15">The founders</H2>
                <div className="flex flex-wrap">
                    <div className="w-full tablet:w-1/2 laptop:w-1/4 mb-15 tablet:px-5">
                        <Img
                            src={founder1}
                            x2={founder12x}
                            webp={founder1Webp}
                            webp2x={founder1Webp2x}
                            alt="wendell castle"
                            className="max-w-full mx-auto mb-2.5"
                        />
                        <H5 className="mb-1 text-center">Nancy Munzer</H5>
                        <p className="text-center">Head of Interior Styling</p>
                    </div>
                    <div className="w-full tablet:w-1/2 laptop:w-1/4 mb-15 tablet:px-5">
                        <Img
                            src={founder2}
                            x2={founder22x}
                            webp={founder2Webp}
                            webp2x={founder2Webp2x}
                            alt="frank gehry"
                            className="max-w-full mx-auto mb-2.5"
                        />
                        <H5 className="mb-1 text-center">Arno Simon</H5>
                        <p className="text-center">Technical Lead</p>
                    </div>
                    <div className="w-full tablet:w-1/2 laptop:w-1/4 mb-15 tablet:px-5">
                        <Img
                            src={founder3}
                            x2={founder32x}
                            webp={founder3Webp}
                            webp2x={founder3Webp2x}
                            alt="joseph walsh"
                            className="max-w-full mx-auto mb-2.5"
                        />
                        <H5 className="mb-1 text-center">Léa Pruvost</H5>
                        <p className="text-center">Designer</p>
                    </div>
                    <div className="w-full tablet:w-1/2 laptop:w-1/4 mb-15 tablet:px-5">
                        <Img
                            src={founder4}
                            x2={founder42x}
                            webp={founder4Webp}
                            webp2x={founder4Webp2x}
                            alt="marcel wanders"
                            className="max-w-full mx-auto mb-2.5"
                        />
                        <H5 className="mb-1 text-center">Mariana Crespo Allen</H5>
                        <p className="text-center">Designer</p>
                    </div>
                </div>
            </div>

            {/*<AsFeaturedIn/>*/}
        </>
    )
}

About.layout = page => <Layout children={page}/>

export default About
