import React, { useEffect, useRef, useState } from 'react'
import Layout from "@/Components/Layouts/Layout"
import H1 from "@/Components/UI/H1"
import { ConfirmationIcon, CustomisationIcon, DeliveryIcon, PaymentIcon, ReturnsIcon } from "@/Components/Icons"
import Accordion from "@/Components/Accordion"
import Button from "@/Components/UI/Button"
import Link from "@/Components/UI/Link"
import { Head } from "@inertiajs/inertia-react"

const Faq = () => {
    const [activeSection, setActiveSection] = useState(1)
    const section1 = useRef()
    const section2 = useRef()
    const section3 = useRef()
    const section4 = useRef()
    const section5 = useRef()

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    const handleScroll = () => {
        if (section4?.current?.getBoundingClientRect().top < 0) {
            setActiveSection(5)
        } else if (section3?.current?.getBoundingClientRect().top < 0) {
            setActiveSection(4)
        } else if (section2?.current?.getBoundingClientRect().top < 0) {
            setActiveSection(3)
        } else if (section1?.current?.getBoundingClientRect().top < 0) {
            setActiveSection(2)
        } else {
            setActiveSection(1)
        }
    }

    const handleNavigationClick = (section) => {
        const bodyRect = document.body.getBoundingClientRect()
        let elemRect = section1.current?.getBoundingClientRect()

        switch (section) {
            case 1:
                elemRect = section1.current?.getBoundingClientRect()
                break
            case 2:
                elemRect = section2.current?.getBoundingClientRect()
                break
            case 3:
                elemRect = section3.current?.getBoundingClientRect()
                break
            case 4:
                elemRect = section4.current?.getBoundingClientRect()
                break
            case 5:
                elemRect = section5.current?.getBoundingClientRect()
                break
        }

        const menuHeight = window.innerWidth > 1023 ? 88 : 48

        const offset = elemRect.top - bodyRect.top - menuHeight

        window.scrollTo({
            top: offset,
            left: 0,
            behavior: 'smooth',
        })
    }

    const NavigationButton = ({children, section}) => (
        <button
            onClick={() => handleNavigationClick(section)}
            className={`focus:outline-none focus:ring-0 flex items-center mb-6 w-full hover:text-teal text-gray-dark ${section === activeSection ? 'laptop:text-teal' : 'laptop:text-gray-dark'}`}>
            {children}
        </button>
    )

    return (
        <>
            <Head>
                <title>FAQs</title>
            </Head>
            <div className="my-13 laptop:mb-5">
                <H1>FAQs</H1>
            </div>

            <div className="flex flex-wrap">
                <div className="w-full mb-2.5 laptop:mb-0 laptop:w-1/3 desktop:w-31%">
                    <div className="laptop:pt-7.5 laptop:sticky laptop:top-23">
                        <NavigationButton section={1}>
                            <CustomisationIcon className="stroke-current mr-4"/>
                            <span className="font-semibold text-base leading-normal">Product and process</span>
                        </NavigationButton>
                        <NavigationButton section={2}>
                            <ConfirmationIcon className="stroke-current mr-4"/>
                            <span className="font-semibold text-base leading-normal">Orders</span>
                        </NavigationButton>
                        <NavigationButton section={3}>
                            <DeliveryIcon className="stroke-current mr-4"/>
                            <span className="font-semibold text-base leading-normal">Delivery</span>
                        </NavigationButton>
                        <NavigationButton section={4}>
                            <ReturnsIcon className="stroke-current mr-4"/>
                            <span className="font-semibold text-base leading-normal">Returns and refunds</span>
                        </NavigationButton>
                        <NavigationButton section={5}>
                            <PaymentIcon className="stroke-current mr-4"/>
                            <span className="font-semibold text-base leading-normal">Payment</span>
                        </NavigationButton>
                    </div>
                </div>

                <div className="w-full laptop:w-2/3 desktop:w-69%">
                    <div className="laptop:max-w-2xl laptop:ml-0 mb-13">
                        <div className="py-7.5" ref={section1}>
                            <div className="flex">
                                <div className="hidden laptop:block text-teal">
                                    <CustomisationIcon className="stroke-current mr-4"/>
                                </div>
                                <div>
                                    <div className="flex items-center text-teal mb-1">
                                        <CustomisationIcon className="stroke-current mr-4 laptop:hidden"/>
                                        <span
                                            className="font-bold text-xl leading-8 leading-normal">Product and process</span>
                                    </div>
                                    <Accordion>
                                        <Accordion.Item title="Is Vibetray a furniture shop?"
                                                        className="py-4 border-b border-gray-light">
                                            <p>
                                                We are not a furniture shop or a reseller, we are an interior styling
                                                platform that helps you decorate your room by sourcing curated items
                                                from different brands and artisans without any design fees. Some items
                                                are custom made, some are local, and some are imported. Each product has
                                                its own Terms and Conditions which are applied by the original seller.
                                                Please make sure to check those in the spec before placing your order.
                                            </p>
                                        </Accordion.Item>
                                        <Accordion.Item title="Can I mix and match between rooms?"
                                                        className="py-4 border-b border-gray-light">
                                            <p>Yes, you can, you have room for self-expression and customization. Each
                                                room is curated as a whole look. However, you might like the major items
                                                of a room but prefer some from another set. All you need to do is select
                                                the items you need from the first room, place your order, and redo the
                                                same for the items from the second set.</p>
                                        </Accordion.Item>
                                        <Accordion.Item title="Do I have the flexibility of the budget?"
                                                        className="py-4 border-b border-gray-light">
                                            <p>
                                                You have full control over your budget. You can make your room as budget
                                                friendly or as luxurious as you want. Each look comes with a variety of
                                                similar items at different price ranges. Pick and choose as you see fit.
                                            </p>
                                        </Accordion.Item>
                                        <Accordion.Item
                                            title="I am not sure if I have taken the measurements properly, is there anybody to cross check them?"
                                            className="py-4 border-b border-gray-light">
                                            <p>
                                                If you requested wall painting, curtains or flooring, a representative
                                                from the related supplier will contact you for a visit to confirm the
                                                dimensions and to take your confirmation on the color sample. After
                                                finetuning the dimensions, the bill will be adjusted accordingly.
                                            </p>
                                        </Accordion.Item>
                                        <Accordion.Item
                                            title="How can I be sure if the colors shown on screen for the paint, curtains and flooring are the same in reality?"
                                            className="py-4 border-b border-gray-light">
                                            <p>
                                                Product imagery may vary from the finished product due to lighting,
                                                screen color, device used etc. and we can’t guarantee that the image
                                                matches the exact color. We try our best to reflect the real view of the
                                                product through different images/angles, but we cannot guarantee the
                                                view the human eye procures. However, if related to paint, curtains
                                                fabric and flooring colors, you don’t need to worry as our partner will
                                                be visiting you to show you the samples in your space.
                                            </p>
                                        </Accordion.Item>
                                        <Accordion.Item
                                            title="Are all the items available in stock?"
                                            className="py-4 border-b border-gray-light">
                                            <p>
                                                Once you select your items and place an order on Vibetray, you will only
                                                pay 10% of your bill at this point. Reason being, that within a 24-48h
                                                window Vibetray will confirm the stock availability of all items ordered
                                                and should an item be out of stock, we will propose alternatives by
                                                email for you to pick from. You will only be asked to settle your bill
                                                once everything is confirmed, i.e items are in stock and you’re happy
                                                with the alternatives proposed when and if applicable.
                                            </p>
                                        </Accordion.Item>
                                        <Accordion.Item
                                            title="What does the price include?"
                                            className="py-4 border-b border-gray-light">
                                            <p>
                                                All prices have been translated into AED and are inclusive of shipping,
                                                taxes, custom charges, and VAT. For everything that requires contracting
                                                like walls, floors and curtains, the prices are inclusive of site
                                                visits, installation and fixing. However, any alteration to the scope of
                                                work in the form of an addition, substitution or omission based on site
                                                conditions shall be considered on top.<br/><br/>
                                                Since the prices charged by the suppliers are in constant evolution, the
                                                prices posted on Vibetray are also likely to be modified constantly. We
                                                may update our prices at any time.<br/><br/>
                                                Despite our best effort, a small number of our products might be
                                                mispriced from time to time. If this happens, we will contact you and
                                                ask whether you wish to continue with the order at the correct price. We
                                                will then either cancel the order and refund the money that was paid or
                                                correct the price. If we are unable to contact you, we will cancel the
                                                item and process a refund.
                                            </p>
                                        </Accordion.Item>
                                        <Accordion.Item
                                            title="What if the main supplier of the item goes on sale?"
                                            className="py-4 border-b border-gray-light">
                                            <p>
                                                While rechecking the availability and finetuning the cost, if the
                                                supplier puts the item picked on sale during that period, we will notify
                                                you and show the discounted price on the bill, similarly if there is a
                                                price increase, we will also reflect this.<br/><br/>
                                                Prices at the supplier side might be subject to review at any point in
                                                time. Vibetray will reflect those changes to the prices on the bill at
                                                the time of placing the order so that the customer can benefit from the
                                                offers and discounts. Please note that this might impact the exchange
                                                and refund policy, as items on sale may not allow for exchanges or
                                                refunds. Please make sure to read the supplier’s T&Cs on the product’s
                                                spec sheet.
                                            </p>
                                        </Accordion.Item>
                                        <Accordion.Item
                                            title="Do I need to set up an account to place an order? "
                                            className="py-4 border-b border-gray-light">
                                            <p>
                                                To complete your online purchase, you will be required to create an
                                                online account with us. For your convenience, you can create an account
                                                prior to placing an order or at checkout. By registering with us you’ll
                                                be able to enjoy the following benefits:<br/><br/>
                                                <ul>
                                                    <li>
                                                        Review past purchases
                                                    </li>
                                                    <li>
                                                        Save your address and card details so you can shop even quicker
                                                        next time
                                                    </li>
                                                    <li>
                                                        Manage your account details, address book and email preferences
                                                    </li>
                                                </ul>
                                            </p>
                                        </Accordion.Item>
                                        <Accordion.Item
                                            title="Is my personal information kept private?"
                                            className="py-4 border-b border-gray-light">
                                            <p>
                                                We don’t use your personal details for anything other than for the
                                                purpose of completing your order. For more information regarding how we
                                                handle your personal information, please refer to our <Link
                                                href="/privacy" className="!text-base">Privacy Policy</Link>.
                                            </p>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                            </div>
                        </div>

                        <div className="py-7.5" ref={section2}>
                            <div className="flex">
                                <div className="hidden laptop:block text-teal">
                                    <ConfirmationIcon className="stroke-current mr-4"/>
                                </div>
                                <div>
                                    <div className="flex items-center text-teal mb-1">
                                        <ConfirmationIcon className="stroke-current mr-4 laptop:hidden"/>
                                        <span className="font-bold text-xl leading-8 leading-normal">Orders</span>
                                    </div>
                                    <Accordion>
                                        <Accordion.Item title="How do I place an order?"
                                                        className="py-4 border-b border-gray-light">
                                            <p>
                                                You can place an order online from the comfort of your home, this is how
                                                it goes:<br/><br/>
                                                Browse the different rooms within the category you need and click on the
                                                design you like. You can either shop the look as it is or select
                                                specific items – for each item you have many options, you can pick
                                                whatever fits your budget and your space without getting far from the
                                                overall mood set for the room. Don’t forget to check the specs, T&Cs,
                                                and the price so that your selection satisfies your need. Everything is
                                                then sourced and delivered to you.<br/><br/>
                                                You might need to paint your walls or cover the ugly ceramics or adorn
                                                the windows with some curtains, you can also add those services to your
                                                order; the curtain fabric, the wall paint or wallpaper and the LVT
                                                flooring will be delivered and installed into your space.
                                            </p>
                                        </Accordion.Item>
                                        <Accordion.Item title="What will I receive once my order is placed?"
                                                        className="py-4 border-b border-gray-light">
                                            <p>
                                                Once you have successfully placed your order online, you will receive an
                                                order confirmation to the email address you have provided assuming the
                                                email address indicated in the registration form doesn’t contain errors.
                                                The order confirmation includes a summary of the purchased items, a
                                                price breakdown, an invoice, and the delivery address. You must check
                                                the completeness and conformity of the information you provided. We
                                                cannot be held responsible for possible errors of data-entry and the
                                                consequences in terms of delay or delivery error. In this context, all
                                                the expenses engaged for resending will be your
                                                responsibility.<br/><br/>
                                                You can at any moment check the status of your order by consulting the
                                                heading "My account". If you don’t receive the order confirmation within
                                                the hour or wish to check anything related to your order, you can write
                                                to us at <a
                                                href="mailto:customerservice@vibetray.com">customerservice@vibetray.com</a>.
                                            </p>
                                        </Accordion.Item>
                                        <Accordion.Item title="What happens after I place the order?"
                                                        className="py-4 border-b border-gray-light">
                                            <p>
                                                For Furniture & Accessories, Vibetray will go through the details of
                                                your order to check the stock availability & price changes of the
                                                articles you ordered within 24-48 hours. If the items are in stock, you
                                                will receive an order confirmation, if not, we will be sending you an
                                                email with other alternatives. You will then have the choice of either
                                                going with the alternative proposed or remove the item from your
                                                order.<br/><br/>
                                                For Walls, Curtains & Floors, our fit-out partners will contact you
                                                within the same time frame to schedule an appointment for a visit. They
                                                will be showing you the color/fabric samples for confirmation and
                                                checking the measurements you added when placing the order so that you
                                                are not billed more than you should. They will also inspect the space in
                                                case something additional is required like adjusting cracks in the wall.
                                                Any adjustments required will be reflected in the bill accordingly.<br/><br/>
                                                To guarantee you’re not charged for something that is not in stock or
                                                for dimensions that might not be accurate, we only charge you 10% at the
                                                beginning to kick off the order. You will only be asked to settle your
                                                bill once everything is checked.
                                            </p>
                                        </Accordion.Item>
                                        <Accordion.Item title="Can I edit my order?"
                                                        className="py-4 border-b border-gray-light">
                                            <p>
                                                You can edit and customize your room as much as you want before placing
                                                the order. You can save your choices and continue editing your order
                                                later. However, once the order is placed it cannot be changed.
                                            </p>
                                        </Accordion.Item>
                                        <Accordion.Item title="Can I order a partial room, just the items I like?"
                                                        className="py-4 border-b border-gray-light">
                                            <p>
                                                Yes. When selecting a room, you can buy it as a whole or pick and choose
                                                the items you need. You are not forced to take everything you see; you
                                                can just order the items you want or simply order paint or flooring and
                                                have them installed for you.
                                            </p>
                                        </Accordion.Item>
                                        <Accordion.Item title="Can I place an order over the phone?"
                                                        className="py-4 border-b border-gray-light">
                                            <p>
                                                Orders can only be placed through our website. If you’re having a
                                                difficulty, you can always write to <a
                                                href="mailto:customerservice@vibetray.com">customerservice@vibetray.com</a> for
                                                any help or assistance you may require.
                                            </p>
                                        </Accordion.Item>
                                        <Accordion.Item title="How can I check the status of my order?"
                                                        className="py-4 border-b border-gray-light">
                                            <p>
                                                You can check the status of your order by consulting the heading "My
                                                account”. You can also contact us on <a
                                                href="mailto:customerservice@vibetray.com">customerservice@vibetray.com</a>.
                                            </p>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                            </div>
                        </div>

                        <div className="py-7.5" ref={section3}>
                            <div className="flex">
                                <div className="hidden laptop:block text-teal">
                                    <DeliveryIcon className="stroke-current mr-4"/>
                                </div>
                                <div>
                                    <div className="flex items-center text-teal mb-1">
                                        <DeliveryIcon className="stroke-current mr-4 laptop:hidden"/>
                                        <span className="font-bold text-xl leading-8 leading-normal">Delivery</span>
                                    </div>
                                    <Accordion>
                                        <Accordion.Item title="How is my order implemented and how long it takes?"
                                                        className="py-4 border-b border-gray-light">
                                            <p>
                                                The items of your room will be ordered from different brands to be
                                                delivered to your home right after the order confirmation is sent and as
                                                per the lead times and t&cs mentioned for each product.<br/><br/>
                                                Deliveries are made by the brands we have curated our interiors from: If
                                                the supplier is local, they will send it to you directly or contact you
                                                to arrange a delivery; If the supplier is international, the product
                                                will be shipped to the customer through Vibetray via Aramex. For walls &
                                                floors, the suppliers will be contacting you for a first visit to
                                                inspect the space, check the measurements and show you the samples &
                                                colors. Once you validate everything, they will come back for a second
                                                visit to implement.<br/><br/>
                                                Please contact us regarding delayed shipments so that we can help you as
                                                soon as possible. Delays caused by you not being present to receive the
                                                shipment is out of our control and not subject to a refund. Orders made
                                                during weekends and holidays are processed the following working day.
                                            </p>
                                        </Accordion.Item>
                                        <Accordion.Item
                                            title="Can these rooms be implemented in multiple addresses or in another country?"
                                            className="py-4 border-b border-gray-light">
                                            <p>
                                                We are only able to deliver to one address per order. If you would like
                                                to send your purchases to multiple addresses, we suggest that you place
                                                a separate order for each destination.<br/><br/>
                                                The rooms are curated, sourced and implemented per city. At the moment
                                                we are only operating in Dubai.
                                            </p>
                                        </Accordion.Item>
                                        <Accordion.Item title="Where can I find tracking information?"
                                                        className="py-4 border-b border-gray-light">
                                            <p>
                                                Order Tracking information will be visible in your Order confirmation
                                                email and in your Order History. For walls & floors, our fit our partner
                                                will contact you and you can then liaise with them on the schedule.
                                            </p>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                            </div>
                        </div>

                        <div className="py-7.5" ref={section4}>
                            <div className="flex">
                                <div className="hidden laptop:block text-teal">
                                    <ReturnsIcon className="stroke-current mr-4"/>
                                </div>
                                <div>
                                    <div className="flex items-center text-teal mb-1">
                                        <ReturnsIcon className="stroke-current mr-4 laptop:hidden"/>
                                        <span
                                            className="font-bold text-xl leading-8 leading-normal">Returns and refunds</span>
                                    </div>
                                    <Accordion>
                                        <Accordion.Item title="What is your exchange and refund policy?"
                                                        className="py-4 border-b border-gray-light">
                                            <p>
                                                Our room looks are curated from different suppliers. Therefore, policies
                                                vary from an item to another. Our selling partner’s policy can be found
                                                on each product specification page. Please make sure you read the T&Cs
                                                of the items you are buying before placing your order.<br/><br/>
                                                We want your shopping experience to be as enjoyable as possible, so
                                                we’re here to help you if you need. Please write to us on <a
                                                href="mailto:customerservice@vibetray.com">customerservice@vibetray.com</a> and
                                                will advise you on how to manage your returns.
                                            </p>
                                        </Accordion.Item>
                                        <Accordion.Item title="Who handles the exchange and refund?"
                                                        className="py-4 border-b border-gray-light">
                                            <p>
                                                ‘After sales services’ are handled by the suppliers directly through
                                                Vibetray. If you are not satisfied with the product, please contact us
                                                at customerservice@vibetray.com by mentioning your order number and the
                                                product you wish to return, and we will provide you with what is needed
                                                to handle the case with the supplier directly.<br/><br/>
                                                In general, suppliers do a refund if the item has a defect or is sent
                                                wrong and most suppliers accept returns if the item is still in its
                                                original condition/packaging and with the original receipt. Shipping
                                                costs, delivery costs and taxes however won’t be refundable. For all
                                                items that are tailor made or made to fit the customer’s space like the
                                                curtains, wallpaper, and flooring, returns eventually are not
                                                accepted.<br/><br/>
                                                Depending on the supplier, refund payouts might be paid back to the
                                                customer’s card when they visit them in the shop or processed back to
                                                Vibetray. If the refund is paid back to Vibetray the customer must send
                                                an email to customerservice@vibetray.com with a proof of return and we
                                                will process the refund back once we have received the amount from the
                                                supplier.
                                            </p>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                            </div>
                        </div>

                        <div className="pt-7.5 mb-25" ref={section5}>
                            <div className="flex">
                                <div className="hidden laptop:block text-teal">
                                    <PaymentIcon className="stroke-current mr-4"/>
                                </div>
                                <div>
                                    <div className="flex items-center text-teal mb-1">
                                        <PaymentIcon className="stroke-current mr-4 laptop:hidden"/>
                                        <span className="font-bold text-xl leading-8 leading-normal">Payment</span>
                                    </div>
                                    <Accordion>
                                        <Accordion.Item title="How is the payment split?"
                                                        className="py-4 border-b border-gray-light">
                                            <p>
                                                As detailed in section 2 – Orders, Vibetray conducts a stock
                                                availability check and provides you with alternatives if something is
                                                out of stock. We also adjust the bill if you are ordering wall painting,
                                                curtains or flooring based on the visit of our partner to your place to
                                                make sure you’re not being charged more than you should. This is why we
                                                only charge 10% when placing the order with the remaining 90% being
                                                charged after the 24-48 hours window when everything is confirmed, and
                                                the order is ready to be kicked off.
                                            </p>
                                        </Accordion.Item>
                                        <Accordion.Item
                                            title="What happens if the amount related to walls & floors is different than the bill shown during the order?"
                                            className="py-4 border-b border-gray-light">
                                            <p>
                                                Ideally, if the contractor was able to visit you within the 24-48 hours
                                                window, your bill will be adjusted along with the furniture &
                                                accessories in one go. If the visit is not possible during this
                                                timeframe, you will be charged as per the initial bill because we don’t
                                                want to hold the order of the other items. However, you will be refunded
                                                post visit if the amended bill is lower, or you will be sent a new
                                                payment link to be settled if there is anything additional work that was
                                                not considered in the initial bill.
                                            </p>
                                        </Accordion.Item>
                                        <Accordion.Item title="How do I know if my payment was successful?"
                                                        className="py-4 border-b border-gray-light">
                                            <p>
                                                If your order is successful, you will be taken immediately to a
                                                confirmation page. You will also receive a confirmation email from us,
                                                provided your email address is accurate.
                                            </p>
                                        </Accordion.Item>
                                        <Accordion.Item
                                            title="What payment methods does Vibetray accept and is it safe to use my credit card online?"
                                            className="py-4 border-b border-gray-light">
                                            <p>
                                                You can place your order with any major credit card (Visa or
                                                Mastercard). We use the payment gate Stripe that allows you to check out
                                                with your credit or debit card. Stripe applies 3D Secure verified by
                                                Visa which is a global security standard that we use to ensure the
                                                security of our customers. All transactions are protected by
                                                SSL-encryption. This means that nobody other than you, will be able to
                                                access the card details.
                                            </p>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                            </div>
                        </div>

                        <div className="flex items-center justify-center flex-col">
                            <span className="text-base text-black font-bold text-center mb-7.5">Can’t find what you’re looking for?</span>
                            <Button as="link" href="/get-in-touch">
                                Contact us
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

Faq.layout = page => <Layout children={page}/>

export default Faq
