import React from 'react'
import Layout from '@/Components/Layouts/Layout'
import H3 from "@/Components/UI/H3"
import VibesetsCarousel from "@/Components/VibesetsCarousel"
import { Head, usePage } from "@inertiajs/inertia-react"
import H1 from '@/Components/UI/H1'

const SearchResults = () => {
    const {rooms, accessorySets, query} = usePage().props

    const vibesetsCount = rooms.reduce((sum, room) => {
        return sum + room.vibesets?.length
    }, 0)

    const resultsCount = vibesetsCount + accessorySets.length

    return (
        <div className="pt-0 laptop:mb-8">
            <Head>
                <title>Search results</title>
            </Head>
            <div className="mt-13 laptop:mt-15 mb-10">
                <H1 className="mb-7.5">Search results</H1>
                {query !== '' && (
                    <p className="font-bold text-base">{resultsCount} result{resultsCount > 1 ? 's' : ''} for
                        "{query}"</p>
                )}
            </div>
            {resultsCount === 0 ? (
                <div className="mt-13 laptop:mt-28 w-full max-w-80 mx-auto text-base text-gray-dark">
                    Sorry, no results found!<br/>
                    – Make sure all words are spelled correctly.<br/>
                    – Try different keywords.<br/>
                    – Try using less specific keywords.
                </div>
            ) : (
                <div>
                    {rooms.map((room, index) => (
                        <div key={`room-${index}`}>
                            <div className="flex items-center mb-4">
                                <H3>{room.name}</H3>
                            </div>
                            <VibesetsCarousel items={room.vibesets}/>
                        </div>
                    ))}

                    {accessorySets.length > 0 && (
                        <div className="mb-13">
                            <div className="flex items-center mb-4">
                                <H3>Accessory sets</H3>
                            </div>
                            <VibesetsCarousel items={accessorySets}/>
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

SearchResults.layout = page => <Layout children={page}/>

export default SearchResults
