import React, { useContext, useEffect, useState } from 'react'
import Layout from "@/Components/Layouts/Layout"
import { Head, useForm, usePage } from '@inertiajs/inertia-react';
import CheckoutSteps from "@/Components/Checkout/CheckoutSteps"
import DeliveryStep from "@/Components/Checkout/DeliveryStep"
import { AppContext } from "@/Contexts/app-context"
import VibesetBill from "@/Components/Vibeset/VibesetBill/VibesetBill"
import PaymentStep from "@/Components/Checkout/PaymentStep"
import SmallModal from '@/Components/Modals/SmallModal'
import FormError from '@/Components/Form/FormError'
import Button from '@/Components/UI/Button'
import MobileVibesetBill from "@/Components/Vibeset/VibesetBill/MobileVibesetBill"
import { emirates } from "@/constants"

const Checkout = () => {
    const {vibeset, savedVibeset, auth} = usePage().props
    const {appContext, setAppContext} = useContext(AppContext)
    const [step, setStep] = useState(1)
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false)
    const {data, setData, post, errors} = useForm({
        'saved_vibeset_id': savedVibeset.id,
        delivery_address: {
            line1: auth.user?.delivery_address?.line1 || '',
            line2: auth.user?.delivery_address?.line2 || '',
            town: auth.user?.delivery_address?.town || '',
            emirate: emirates.find(e => e === 'Dubai')
        },
        use_delivery_address_on_account: !!auth.user?.delivery_address?.line1,
        phone: auth.user?.phone || '',
        notes: '',
        terms: true,
    })

    // The bill uses the context so need to load it with vibeset and products
    useEffect(() => {
        setAppContext({
            ...appContext,
            vibeset,
            products: savedVibeset.products,
        })
    }, [savedVibeset, vibeset])

    return (
        <>
            <Head>
                <title>Checkout</title>
            </Head>
            <div className="flex flex-col navbar:flex-row">
                <div className="flex-1 mt-5 tablet:mt-10 laptop:px-15 mb-16">
                    <div className="mb-7.5 tablet:mb-15">
                        <CheckoutSteps currentStep={step}/>
                    </div>
                    <div className="navbar:hidden mb-7.5">
                        <MobileVibesetBill
                            products={savedVibeset.products}
                            is_summary={true}
                            is_read_only={true}
                        />
                    </div>
                    <div className="flex flex-wrap laptop:px-13">
                        <div className="">
                            {step === 1 && (
                                <DeliveryStep
                                    data={data}
                                    setData={setData}
                                    errors={errors}
                                    post={post}
                                    setStep={setStep}
                                />
                            )}
                            {step === 2 && (
                                <PaymentStep
                                    data={data}
                                    setData={setData}
                                    errors={errors}
                                    post={post}
                                    setStep={setStep}
                                />
                            )}
                        </div>
                    </div>
                </div>

                {/*Bill*/}
                <div className="hidden navbar:block w-full max-w-[389px] flex-none">
                    <div className="sticky top-[70px] h-[calc(100vh-70px)] border-l-2 border-gray-medium">
                        <VibesetBill
                            products={savedVibeset.products}
                            is_summary={true}
                            is_read_only={true}
                        />
                    </div>
                </div>
            </div>

            {/*Errors modal*/}
            <SmallModal
                isModalOpen={isErrorModalOpen}
                closeModal={() => setIsErrorModalOpen(false)}
            >
                <div className="mb-7.5">
                    <FormError>Something went wrong but not to worry our service will resume
                        shortly.</FormError>
                </div>
                <div className="text-right">
                    <Button onClick={() => setIsErrorModalOpen(false)}>Take me back</Button>
                </div>
            </SmallModal>
        </>
    )
}

Checkout.layout = page => <Layout children={page} showFooter={false}/>

export default Checkout
