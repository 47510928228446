import React from 'react'
import PropTypes from 'prop-types'

const Tag = ({className, children, size, color}) => {
    return (
        <div
            className={`
                inline-flex
                items-center
                uppercase
                ${color === 'white' ? 'text-black bg-white border-2 border-black' : ''}
                ${color === 'black' ? 'text-white bg-black border-2 border-black' : ''}
                ${color === 'teal' ? 'text-white bg-teal border-2 border-teal' : ''}
                ${color === 'teal-dark' ? 'text-white bg-teal-dark border-2 border-teal-dark' : ''}
                ${size === 'normal' ? 'px-2.5 py-1 text-xs' : ''}
                ${size === 'small' ? 'px-2 py-0.5 text-xxs' : ''}
                ${className}
            `}
        >
            {children}
        </div>
    )
}

Tag.propTypes = {
    size: PropTypes.oneOf(['normal', 'small']),
    color: PropTypes.oneOf(['black', 'white', 'teal', 'teal-dark']),
}

Tag.defaultProps = {
    size: 'normal',
    color: 'black',
}

export default Tag
