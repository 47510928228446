import React, { useEffect, useRef, useState } from 'react'
import { Inertia } from '@inertiajs/inertia'
import Layout from '@/Components/Layouts/Layout'
import H1 from "@/Components/UI/H1"
import Button from "@/Components/UI/Button"
import H2 from "@/Components/UI/H2"
import H5 from "@/Components/UI/H5"
import { FittedIcon, PlayIcon, ShopItIcon, SpotItIcon } from "@/Components/Icons";
import H3 from "@/Components/UI/H3"
import VibesetsCarousel from "@/Components/VibesetsCarousel"
import Img from "@/Components/UI/Img"
import homeHeader from '@/assets/homepage/homepage_header_png@2x.png'
import homeHeaderWebp from '@/assets/homepage/homepage_header_png@2x.webp'
import BrandsCarousel from "@/Components/BrandsCarousel"
import { Head, usePage } from "@inertiajs/inertia-react"
import SmallModal from '@/Components/Modals/SmallModal'
import HowItWorksModal from "@/Components/Modals/HowItWorksModal";

const Home = () => {
    const {rooms, accessorySets, flash} = usePage().props
    const [showDeletedAccountConfirmationModal, setShowDeletedAccountConfirmationModal] = useState(false)
    const [showHowItWorksModal, setShowHowItWorksModal] = useState(false)

    useEffect(() => {
        if (flash.success === 'account_deleted') {
            setShowDeletedAccountConfirmationModal(true)
        }
    }, [flash])

    const scrollToDiv = useRef()

    const handleSort = (e) => {
        const value = e.target.value
        Inertia.visit('/', {data: {sort: value}, preserveScroll: true})
    }

    const handleShowHowItWorksModal = () => {
        setShowHowItWorksModal(true)
    }

    const handleCloseHowItWorksModal = () => {
        setShowHowItWorksModal(false)
    }

    return (
        <div className="pt-0 laptop:mb-8">
            <Head title="Home"/>
            <div
                className="flex justify-center laptop:items-center flex-col laptop:flex-row bg-gray-light w-screen tablet:w-full -mx-4 tablet:mx-0 px-4 pt-4 pb-7.5 tablet:px-4.5 desktop:py-0 mb-20 laptop:mb-18">
                <div
                    className="w-full mx-auto laptop:w-1/2 laptop:mr-8 desktop:mr-32"
                    style={{
                        maxWidth: 410,
                    }}
                >
                    <H1 className="mb-7.5 text-center laptop:text-left">Pick a new look for your space</H1>
                    <p className="mb-4 text-center laptop:text-left">
                        Ditch the interior designer fees and pick from our beautifully designed rooms curated with items from different brands.
                    </p>
                    <p className="mb-10 text-center laptop:text-left">Spot it, Love it, Fitted!</p>
                    <div className="flex items-center flex-wrap justify-center gap-6 text-center laptop:text-left">
                        <Button onClick={() => {
                            // Scroll above first carousel of vibesets
                            const bodyRect = document.body.getBoundingClientRect(),
                                elemRect = scrollToDiv.current?.getBoundingClientRect(),
                                offset = elemRect.top - bodyRect.top
                            window.scrollTo({
                                top: offset,
                                left: 0,
                                behavior: 'smooth',
                            })
                        }}>Browse rooms</Button>
                        <Button
                            type="tertiary"
                            className="flex items-center gap-x-2 group"
                            onClick={handleShowHowItWorksModal}
                        >
                            How does it work?
                            <PlayIcon className="w-[22px] h-[22px] stroke-black fill-white group-hover:fill-black group-hover:stroke-white"/>
                        </Button>
                    </div>
                </div>
                <div
                    className="w-full mb-4 order-first laptop:order-last laptop:w-1/2 laptop:ml-8 laptop:mb-0 desktop:ml-0  desktop:pl-25 desktop:py-6">
                    <Img
                        src={homeHeader}
                        webp={homeHeaderWebp}
                        alt="marble decoration"
                        className="w-full max-w-full mx-auto tablet:max-w-80% laptop:ml-0 laptop:max-w-90%"
                    />
                </div>
            </div>
            <div className="mb-25">
                <H2 className="text-center mb-7.5 laptop:mb-16">What you'll get</H2>
                <div
                    className="flex flex-col laptop:flex-row items-center justify-start laptop:justify-between laptop:items-start desktop:justify-center mb-10 laptop:mb-13">
                    <div className="text-center mb-15 laptop:mb-0" style={{maxWidth: 265}}>
                        <SpotItIcon className="mb-6.5 mx-auto"/>
                        <H5 className="mb-4">Curated rooms</H5>
                        <p className="text-base leading-normal">Browse our interiors curated from various brands, and spot the one that speaks to you.</p>
                    </div>
                    <div className="text-center mb-15 laptop:mb-0 desktop:mx-44" style={{maxWidth: 265}}>
                        <ShopItIcon className="mb-6.5 mx-auto"/>
                        <H5 className="mb-4">Range of budgets</H5>
                        <p className="text-base leading-normal">Customise your room to your needs, budget and room size.</p>
                    </div>
                    <div className="text-center" style={{maxWidth: 265}}>
                        <FittedIcon className="mb-6.5 mx-auto"/>
                        <H5 className="mb-4">Hassle-free installation</H5>
                        <p className="text-base leading-normal">Get it fitted by our fit out partners. We do the legwork for you so you can get on with other things.</p>
                    </div>
                </div>
                <div className="text-center">
                    <Button as="link" type="secondary" href="/how-it-works">How it works</Button>
                </div>
            </div>
            <div ref={scrollToDiv} id="browse" className="pt-25">
                {/*<SelectInput*/}
                {/*    name="sort"*/}
                {/*    className="laptop:w-48 laptop:ml-auto w-full mb-7.5 laptop:mb-0"*/}
                {/*    value={sort}*/}
                {/*    onChange={handleSort}*/}
                {/*    required*/}
                {/*>*/}
                {/*    <option value="" disabled hidden>Sort by</option>*/}
                {/*    <option value="new">New vibesets</option>*/}
                {/*    <option value="-price">Price high to low</option>*/}
                {/*    <option value="price">Price low to high</option>*/}
                {/*</SelectInput>*/}

                {rooms.map((room, index) => (
                    <div key={`room-${index}`}>
                        <div className="flex items-center mb-4">
                            <H3>{room.name}</H3>
                            {/*<ActionLink href="#" className="ml-auto">See all</ActionLink>*/}
                        </div>
                        <VibesetsCarousel items={room.vibesets}/>
                    </div>
                ))}

                {accessorySets.length > 0 && (
                    <div className="mb-13">
                        <div className="flex items-center mb-4">
                            <H3>Accessory sets</H3>
                            {/*<ActionLink href="#" className="ml-auto">See all</ActionLink>*/}
                        </div>
                        <VibesetsCarousel items={accessorySets}/>
                    </div>
                )}
                {/*<AsFeaturedIn/>*/}
                <div className="mt-10">
                    <BrandsCarousel/>
                </div>
            </div>

            {/*Deleted account confirmation*/}
            <SmallModal
                isModalOpen={showDeletedAccountConfirmationModal}
                closeModal={() => setShowDeletedAccountConfirmationModal(false)}
            >
                <p className="mb-7.5 text-base">
                    Your account has been deleted. To shop with us again, you will need to register a new account.
                </p>
                <div className="flex items-center justify-end">
                    <Button onClick={() => setShowDeletedAccountConfirmationModal(false)}>Got it</Button>
                </div>
            </SmallModal>

            <HowItWorksModal
                isModalOpen={showHowItWorksModal}
                closeModal={handleCloseHowItWorksModal}
            />
        </div>
    )
}

Home.layout = page => <Layout children={page}/>

export default Home
