import React, { useContext } from 'react'
import Modal from "react-modal"
import { CloseIcon } from "@/Components/Icons"
import H3 from "@/Components/UI/H3"
import { AppContext } from "@/Contexts/app-context"
import Img from "@/Components/UI/Img"

Modal.setAppElement('#app')

const ProductSpecificationsModal = (props) => {
    const {appContext} = useContext(AppContext)
    const {isModalOpen, closeModal, product} = props
    const comfortDots = Array.from(Array(5).keys())

    return (
        <Modal
            isOpen={isModalOpen}
            onRequestClose={closeModal}
            contentLabel="Vibeset images"
        >
            <div className="p-7.5">
                <div className="flex items-start flex-wrap justify-between mb-2">
                    <H3 className="inline-block">{product.name}</H3>
                    <div className="w-full tablet:w-auto order-first tablet:order-last mb-4 tablet:mb-0 text-right">
                        <button
                            className="inline-block text-black hover:text-gray-dark focus:outline-none focus:ring-0"
                            onClick={closeModal}>
                            <CloseIcon className="stroke-current"/>
                        </button>
                    </div>
                </div>
                <div className="mb-2">
                    {product.material?.length > 0 ? `${product.material}, ` : ''}
                    {appContext.vibeset?.currency} {product.price_including_vat} <span dangerouslySetInnerHTML={{__html: product.price_suffix}}/>
                </div>
                <div className="mb-2">
                    Quality:&nbsp;
                    <span className="text-gray-dark">
                        <span
                            className={`${product.quality === 'Budget' ? 'text-teal font-bold' : ''}`}>Budget</span> | <span
                        className={`${product.quality === 'Mid-range' ? 'text-teal font-bold' : ''}`}>Mid-range</span> | <span
                        className={`${product.quality === 'Premium' ? 'text-teal font-bold' : ''}`}>Premium</span>
                    </span>
                </div>
                {product.comfort_level && (
                    <div className="mb-2">
                        Comfort:&nbsp;
                        {comfortDots.map((d, index) => (
                            <span key={`comfort-dot-${index}`}
                                  className={`inline-block w-2.5 h-2.5 rounded-full border-2 border-teal border-solid mr-1.5 ${index <= (product.comfort_level - 1) ? 'bg-teal' : ''}`}/>
                        ))}
                    </div>
                )}
                <div className="mb-5">
                    Dispatch time:&nbsp;<span className="text-teal font-bold">{product.delivery_range}</span>
                </div>
                <div className="flex flex-wrap tablet:-mx-3 mb-7.5">
                    {product.images.map((image, index) => (
                        <div key={`product-image-${index}`}
                             className="tablet:px-1.5 w-full tablet:w-1/2 mb-3">
                            <Img src={image.src} alt={image.alt}/>
                        </div>
                    ))}
                </div>
                {product.description?.length > 0 && (
                    <div>
                        <h4 className="font-bold text-2xl leading-normal mb-5">Product description</h4>
                        <div className="laptop:max-w-50% text-base"
                             dangerouslySetInnerHTML={{__html: product.description}}/>
                    </div>
                )}
                {product.terms?.length > 0 && (
                    <div className="mt-10">
                        <h4 className="font-bold text-lg leading-normal mb-5">Terms and conditions</h4>
                        <div className="laptop:max-w-50% text-base"
                             dangerouslySetInnerHTML={{__html: product.terms}}/>
                    </div>
                )}
            </div>
        </Modal>
    )
}

export default ProductSpecificationsModal
