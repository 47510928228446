import React, { useEffect } from "react";
import Layout from "@/Components/Layouts/Layout"
import H1 from "@/Components/UI/H1"
import H5 from "@/Components/UI/H5"
import H2 from "@/Components/UI/H2"
import Accordion from "@/Components/Accordion"
import {
    FittedIcon,
    OneStopShopIcon,
    OnlinePurchaseIcon, PhoneIcon, PlayIcon,
    SaveTimeIcon,
    ShopItIcon,
    SpotItIcon,
} from "@/Components/Icons";
import H6 from "@/Components/UI/H6"
import Img from "@/Components/UI/Img"
import step1 from "@/assets/how_it_works_page/step_1_image.jpg"
import step12x from "@/assets/how_it_works_page/step_1_image@2x.jpg"
import step1Webp from "@/assets/how_it_works_page/step_1_image.webp"
import step1Webp2x from "@/assets/how_it_works_page/step_1_image@2x.webp"
import step2 from "@/assets/how_it_works_page/step_2_image.jpg"
import step22x from "@/assets/how_it_works_page/step_2_image@2x.jpg"
import step2Webp from "@/assets/how_it_works_page/step_2_image.webp"
import step2Webp2x from "@/assets/how_it_works_page/step_2_image@2x.webp"
import step4 from "@/assets/how_it_works_page/step_4_image.jpg"
import step42x from "@/assets/how_it_works_page/step_4_image@2x.jpg"
import step4Webp from "@/assets/how_it_works_page/step_4_image.webp"
import step4Webp2x from "@/assets/how_it_works_page/step_4_image@2x.webp"
import step5 from "@/assets/how_it_works_page/step_5_image.jpg"
import step52x from "@/assets/how_it_works_page/step_5_image@2x.jpg"
import step5Webp from "@/assets/how_it_works_page/step_5_image.webp"
import step5Webp2x from "@/assets/how_it_works_page/step_5_image@2x.webp"
import getStartedLeft from "@/assets/how_it_works_page/get_started_left_image.png"
import getStartedLeft2x from "@/assets/how_it_works_page/get_started_left_image@2x.png"
import getStartedLeftWebp from "@/assets/how_it_works_page/get_started_left_image.webp"
import getStartedLeftWebp2x from "@/assets/how_it_works_page/get_started_left_image@2x.webp"
import getStartedRight from "@/assets/how_it_works_page/get_started_right_image.png"
import getStartedRight2x from "@/assets/how_it_works_page/get_started_right_image@2x.png"
import getStartedRightWebp from "@/assets/how_it_works_page/get_started_right_image.webp"
import getStartedRightWebp2x from "@/assets/how_it_works_page/get_started_right_image@2x.webp"
import Button from "@/Components/UI/Button"
import { Head } from "@inertiajs/inertia-react"
import H3 from "@/Components/UI/H3";
import FreeConsultation from "@/Components/FreeConsultation";

const HowItWorks = () => {
    const Step = ({children, className}) => (
        <div className={`flex flex-wrap laptop:mb-26 desktop:mb-52 mb-7.5 ${className}`}>
            {children}
        </div>
    )

    const StepColumn = ({children, className}) => (
        <div className={`w-full laptop:w-1/2 mb-7.5 laptop:mb-0 ${className}`}>
            {children}
        </div>
    )

    return (
      <>
          <Head>
              <title>How it works</title>
          </Head>
          <div className="text-center pt-13 laptop:pb-2 mb-[80px]">
              <H1>How it works</H1>
          </div>

          <div className="max-w-5.5xl w-full mx-auto">
              <div className="mb-[80px] relative">
                  <video
                    src="./how-it-works.mp4"
                    poster="./how-it-works-poster.jpg"
                    controls={true}
                    playsInline={true}
                    className="w-full h-auto border border-black"
                  />
              </div>
          </div>

          <div className="mb-[80px]">
              <FreeConsultation/>
          </div>

          <div className="max-w-5.5xl w-full mx-auto">
              <Step>
                  <StepColumn>
                      <div className="laptop:ml-4">
                          <Img
                            src={step1}
                            x2={step12x}
                            webp={step1Webp}
                            webp2x={step1Webp2x}
                            alt="browse vibesets"
                            className="max-w-full mx-auto laptop:ml-0"
                          />
                      </div>
                  </StepColumn>
                  <StepColumn>
                      <div className="max-w-104 mx-auto laptop:mr-0">
                          <H5>Step 1 of 4</H5>
                          <H2 className="mb-5">Browse the looks</H2>
                          <p className="mb-6.5">Browse our room looks and spot the one that suits you best. Whether
                              you are into wooden floors, brushed brass or silk cushions—there’s something for
                              you.</p>
                          <Accordion>
                              <Accordion.Item title="What can I find in a pre designed look?"
                                              className="py-4 border-b border-gray-light">
                                  <p>
                                      We’re glad you asked! Each look include wall covering, paint, parquet, curtains,
                                      furniture, lighting items and accessories. All carefully curated and sourced
                                      from different suppliers and brands, each with a different look and feel. You
                                      can either ordered the look as a whole or just select the items you need.
                                  </p>
                              </Accordion.Item>
                              <Accordion.Item title="Can I pick items from different room looks?"
                                              className="py-4 border-b border-gray-light">
                                  <p>Yes, you can. Bear in mind that you will need to place separate orders for items
                                      selected from other rooms as each order is placed by room.</p>
                              </Accordion.Item>
                          </Accordion>
                      </div>
                  </StepColumn>
              </Step>

              <Step>
                  <StepColumn>
                      <div className="max-w-104 mx-auto laptop:ml-0">
                          <H5>Step 2 of 4</H5>
                          <H2 className="mb-5">Customise your room</H2>
                          <p className="mb-6.5">Every item in a room comes with alternate choices.<br/><br/>Simply
                              pick the ones that fit within your budget and room size. You decide how affordable or
                              expensive your room is.</p>
                          <Accordion>
                              <Accordion.Item title="How much flexibility do I have in the customisation?"
                                              className="py-4 border-b border-gray-light">
                                  <p>
                                      Plenty! You can select one or multiple items from a room and order the
                                      quantities you need. You can pick fancier or more budget-friendly alternatives,
                                      or choose only pieces you think match what you already have at home. All
                                      combinations are possible.
                                  </p>
                              </Accordion.Item>
                              <Accordion.Item title="Can you assemble large items for me?"
                                              className="py-4 border-b border-gray-light">
                                  <p>Absolutely. Just tick that option on the items you want us to assemble.</p>
                              </Accordion.Item>
                              <Accordion.Item title="I’m not sure what items to pick!"
                                              className="py-4 border-b border-gray-light">
                                  <p>No problem! Look for the VIBETRAY CHOICE tag on the items we recommend.</p>
                              </Accordion.Item>
                          </Accordion>
                      </div>
                  </StepColumn>
                  <StepColumn className="order-first laptop:order-last">
                      <div>
                          <Img
                            src={step2}
                            x2={step22x}
                            webp={step2Webp}
                            webp2x={step2Webp2x}
                            alt="customisation panel"
                            className="max-w-full mx-auto laptop:mr-0"
                          />
                      </div>
                  </StepColumn>
              </Step>
              <Step>
                  <StepColumn>
                      <div className="">
                          <Img
                            src={step4}
                            x2={step42x}
                            webp={step4Webp}
                            webp2x={step4Webp2x}
                            alt="payment process"
                            className="max-w-full mx-auto laptop:mr-0"
                          />
                      </div>
                  </StepColumn>
                  <StepColumn>
                      <div className="max-w-104 mx-auto laptop:mr-0">
                          <H5>Step 3 of 4</H5>
                          <H2 className="mb-5">Payment and validation</H2>
                          <p className="mb-6.5">Once you’re happy with your customisation, you can either save it for
                              later or order it directly. On order placement, 10% of the order has to be paid.
                              <br/><br/>
                              At this point, we have a “validation window” where
                              measurements and stock availability are checked.<br/><br/>
                              Once this is done and your order is confirmed, the remaining payment can be paid.
                          </p>
                          <Accordion>
                              <Accordion.Item title="What happens during the validation window?"
                                              className="py-4 border-b border-gray-light">
                                  <p>We aim to validate your order within 24-48 hours. The team will check the stock
                                      availability and the prices of the items you ordered with the different
                                      suppliers. In case of shortages, we will propose alternatives which you can
                                      either validate or remove. In case of a sale or price increase, we will reflect
                                      the changes on the bill accordingly. There will also be an inspection visit to
                                      your place if you’ve ordered walls, floors or curtains so that dimensions are
                                      taken again and samples are shown to you for confirmation.
                                      <br/><br/>When all is approved, the order will be validated and ready for kick
                                      off.</p>
                              </Accordion.Item>
                              <Accordion.Item title="How many payment are there?"
                                              className="py-4 border-b border-gray-light">
                                  <p>There are 2 payments. 10% when placing the order and the rest is due when the
                                      stock is validated and the walls & floors dimensions have been crosschecked by
                                      our fit out partner.</p>
                              </Accordion.Item>
                              <Accordion.Item title="How are the remaining payments settled?"
                                              className="py-4 border-b border-gray-light">
                                  <p>The order is kicked off for implementation once both payments are settled. So,
                                      they should happen right when the measurements & the stock are validated. You
                                      will be notified of any differences needed in one of those scenarios:<br/>A) If
                                      there is a difference in the measurements that has an impact on your bill after
                                      the site visit.<br/>B) If your site requires any alteration to the scope of
                                      works in the form of an addition, substitution or removal based on site
                                      conditions, which is eventually not included in the communicated price.</p>
                              </Accordion.Item>
                          </Accordion>
                      </div>
                  </StepColumn>
              </Step>

              <Step className="!mb-[80px]">
                  <StepColumn>
                      <div className="max-w-104 mx-auto laptop:ml-0">
                          <H5>Step 4 of 4</H5>
                          <H2 className="mb-5">Delivery & fitting</H2>
                          <p className="mb-6.5">If you have selected Walls & Floors treatments, our fit-out partner
                              will be getting in touch with you at every stage to schedule a suitable date for your
                              delivery and fitting. For Furniture & Accessories, those will be delivered by the
                              relevant supplier or via Vibetray if they are imported.<br/><br/>Once everything is
                              delivered and fitted, you’re all done! Time to kick
                              back and indulge in your new room.</p>
                          <Accordion>
                              <Accordion.Item title="How long does the delivery take?"
                                              className="py-4 border-b border-gray-light">
                                  <p>Delivery times vary according to the items you pick and your delivery address. We
                                      give you an estimate when you place your order. Delivery includes all furniture
                                      and accessories delivery with paint/wallpaper, parquet and curtains
                                      installation.</p>
                              </Accordion.Item>
                              <Accordion.Item title="What do I need to do on the delivery and fitting dates?"
                                              className="py-4 border-b border-gray-light">
                                  <p>Our suppliers and fit out partners will be taking care of everything. Just make
                                      sure there’s someone at home to let them in.</p>
                              </Accordion.Item>
                              <Accordion.Item title="What about after sales services?"
                                              className="py-4 border-b border-gray-light">
                                  <p>Since you room items are sourced from multiple suppliers, each item has different
                                      T&Cs. Please make sure you read those before ordering the item. If you need any
                                      assistance with any of our suppliers please contact us on&nbsp;
                                      <a href="mailto:customerservice@vibetray.com">customerservice@vibetray.com</a>.
                                  </p>
                              </Accordion.Item>
                          </Accordion>
                      </div>
                  </StepColumn>
                  <StepColumn className="order-first laptop:order-last">
                      <div className="laptop:ml-4">
                          <Img
                            src={step5}
                            x2={step52x}
                            webp={step5Webp}
                            webp2x={step5Webp2x}
                            alt="delivery & fitting"
                            className="max-w-full mx-auto laptop:ml-0"
                          />
                      </div>
                  </StepColumn>
              </Step>
          </div>

          <div className="mb-[80px]">
              <FreeConsultation/>
          </div>

          <div className="max-w-5.5xl w-full mx-auto">
              <div className="laptop:mb-20 mb-10">
                  <H2 className="text-center mb-7.5 laptop:mb-15">Make your home beautiful</H2>
                  <div className="flex flex-wrap">
                      <div className="w-full tablet:w-1/2 laptop:w-1/3 laptop:mb-17 mb-15">
                          <div className="text-center mx-auto laptop:ml-0" style={{maxWidth: 265}}>
                              <SpotItIcon className="mb-6.5 mx-auto"/>
                              <H5 className="mb-4">Ready-made templates</H5>
                              <p className="text-base leading-normal">See what your home can look like before placing
                                  an
                                  order.</p>
                          </div>
                      </div>
                      <div className="w-full tablet:w-1/2 laptop:w-1/3 laptop:mb-17 mb-15">
                          <div className="text-center mx-auto" style={{maxWidth: 265}}>
                              <FittedIcon className="mb-6.5 mx-auto"/>
                              <H5 className="mb-4">Walls & flooring included</H5>
                              <p className="text-base leading-normal">Don’t limit yourself to the furniture. Customise
                                  your walls and floors while you’re at it.</p>
                          </div>
                      </div>
                      <div className="w-full tablet:w-1/2 laptop:w-1/3 laptop:mb-17 mb-15">
                          <div className="text-center mx-auto laptop:mr-0" style={{maxWidth: 265}}>
                              <ShopItIcon className="mb-6.5 mx-auto"/>
                              <H5 className="mb-4">Range of budgets</H5>
                              <p className="text-base leading-normal">Customise your room. Make it as affordable or
                                  as
                                  expensive as you choose.</p>
                          </div>
                      </div>
                      <div className="w-full tablet:w-1/2 laptop:w-1/3 laptop:mb-17 mb-15">
                          <div className="text-center mx-auto laptop:ml-0" style={{maxWidth: 265}}>
                              <OnlinePurchaseIcon className="mb-6.5 mx-auto"/>
                              <H5 className="mb-4">Online purchase</H5>
                              <p className="text-base leading-normal">Do it all from the comfort of your home and in
                                  your
                                  own time.</p>
                          </div>
                      </div>
                      <div className="w-full tablet:w-1/2 laptop:w-1/3 laptop:mb-17 mb-15">
                          <div className="text-center mx-auto" style={{maxWidth: 265}}>
                              <OneStopShopIcon className="mb-6.5 mx-auto"/>
                              <H5 className="mb-4">One-stop-shop</H5>
                              <p className="text-base leading-normal">Purchase all your items in one place, and have
                                  them
                                  delivered, assembled and fitted at your convenience.</p>
                          </div>
                      </div>
                      <div className="w-full tablet:w-1/2 laptop:w-1/3 laptop:mb-17 mb-15">
                          <div className="text-center mx-auto laptop:mr-0" style={{maxWidth: 265}}>
                              <SaveTimeIcon className="mb-6.5 mx-auto"/>
                              <H5 className="mb-4">Save time and stress</H5>
                              <p className="text-base leading-normal">We do the legwork for you so you can get on with
                                  other things.</p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>

          <div
            className="w-full bg-gray-light px-4 laptop:px-0 pt-15 laptop:py-15 relative flex flex-col flex-wrap laptop:flex-row laptop:items-center overflow-hidden">
              <div
                className="hidden tablet:block tablet:absolute tablet:bottom-0 tablet:left-0 laptop:inset-y-0 laptop:-left-15 desktop:left-0">
                  <Img
                    src={getStartedLeft}
                    x2={getStartedLeft2x}
                    webp={getStartedLeftWebp}
                    webp2x={getStartedLeftWebp2x}
                    alt="set of keys"
                    className="max-w-full h-72 laptop:h-auto"
                  />
              </div>
              <div className="w-full text-center mx-auto" style={{maxWidth: 526}}>
                  <H2 className="mb-5">Pick a new look for your space</H2>
                  <H6 className="mb-10">Ditch the interior designer fees and pick from our beautifully designed rooms
                      curated with items from different brands.<br/>Spot it, Love it, Fitted!</H6>
                  <Button as="link" href="/#browse">Get started</Button>
              </div>
              <div
                className="laptop:absolute laptop:inset-y-0 laptop:-right-15 desktop:right-0 laptop:bottom-0 mt-5 laptop:mt-0">
                  <Img
                    src={getStartedRight}
                    x2={getStartedRight2x}
                    webp={getStartedRightWebp}
                    webp2x={getStartedRightWebp2x}
                    alt="journal"
                    className="max-w-full h-72 laptop:h-auto relative ml-auto -right-4 laptop:-right-0"
                  />
              </div>
          </div>
      </>
    )
}

HowItWorks.layout = page => <Layout children={page}/>;

export default HowItWorks
