import React from 'react'
import Label from "@/Components/Form/Label"

const SelectInput = ({
                         label,
                         name,
                         className,
                         children,
                         error,
                         selectClasses = '',
                         ...props
                     }) => {
    return (
        <div className={className}>
            {label && (
                <Label name={name}>
                    {label}
                </Label>
            )}
            <select
                id={name}
                name={name}
                {...props}
                className={`pl-3 pr-10 py-2 leading-5 block w-full placeholder-gray-dark text-base border border-gray-dark text-black appearance-none relative focus:outline-none focus:ring-0 focus:border-gray-dark ${selectClasses} ${error ? 'border-red' : ''}`}
            >
                {children}
            </select>
            {error}
        </div>
    )
}

export default SelectInput
