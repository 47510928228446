import React, { useContext, useState } from 'react'
import Layout from "@/Components/Layouts/Layout"
import H1 from "@/Components/UI/H1"
import TextInput from "@/Components/Form/TextInput"
import PasswordInput from "@/Components/Form/PasswordInput"
import Link from "@/Components/UI/Link"
import Button from "@/Components/UI/Button"
import { Head, usePage } from "@inertiajs/inertia-react"
import { error, getParameterByName } from "@/utils"
import { Inertia } from '@inertiajs/inertia'
import H3 from "@/Components/UI/H3"
import Img from "@/Components/UI/Img"
import registerImg from "@/assets/register_page/register_page.jpg"
import registerImg2x from "@/assets/register_page/register_page@2x.jpg"
import registerImgWebp from "@/assets/register_page/register_page.webp"
import registerImgWebp2x from "@/assets/register_page/register_page@2x.webp"
import { AppContext } from "@/Contexts/app-context"
import { saveVibeset, saveVibesetFiles } from "@/api"

const Register = () => {
    const {flash, errors} = usePage().props
    const {appContext} = useContext(AppContext)

    const [values, setValues] = useState({
        first_name: '',
        last_name: '',
        email: '',
        password: '',
    })

    const handleChange = (e) => {
        const key = e.target.id
        const value = e.target.value
        setValues(values => ({
            ...values,
            [key]: value,
        }))
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        let saved_vibeset_id

        // Save vibeset and redirect if necessary
        if (getParameterByName('redirect') && appContext.products.length > 0 && appContext.vibeset?.id) {
            saved_vibeset_id = await saveVibeset(appContext.vibeset?.id, appContext.products)
        }

        // Prepare headers
        let headers = {}
        if (saved_vibeset_id) {
            headers = {
                'sv': saved_vibeset_id,
                'redirect': getParameterByName('redirect'),
            }
        }

        // Register
        Inertia.post('/register', values, {headers})
    }

    return (
        <>
            <Head>
                <title>Register</title>
            </Head>
            <div className="text-center desktop:text-left py-13">
                <H1>Register</H1>
            </div>

            <div className="w-screen laptop:w-full flex flex-wrap -mx-4 laptop:mx-0">
                <div className="bg-gray-light py-7.5 px-4 w-full laptop:w-1/2 laptop:py-8 desktop:py-15 flex items-center">
                    {flash.success ? (
                        <div className="max-w-88 mx-auto h-full flex flex-col justify-center">
                            <H3 className="mb-5">You're all done!</H3>
                            <p className="leading-normal">A confirmation email has been sent to <span
                                className="font-bold">{flash.success}</span>. Please check your inbox.</p>
                        </div>
                    ) : (
                        <form className="mx-auto tablet:max-w-xs desktop:max-w-input" onSubmit={handleSubmit}>
                            <TextInput
                                label="First name*"
                                name="first_name"
                                type="text"
                                className="mb-7.5"
                                error={error(errors, 'first_name')}
                                onChange={handleChange}
                            />
                            <TextInput
                                label="Last name*"
                                name="last_name"
                                type="text"
                                className="mb-7.5"
                                error={error(errors, 'last_name')}
                                onChange={handleChange}
                            />
                            <TextInput
                                label="Email address*"
                                name="email"
                                type="email"
                                className="mb-7.5"
                                error={error(errors, 'email')}
                                onChange={handleChange}
                            />
                            <PasswordInput
                                label="Create password*"
                                name="password"
                                className="mb-7.5"
                                error={error(errors, 'password')}
                                onChange={handleChange}
                            />
                            <p className="mb-7.5 text-sm">By signing up you agree to our <Link href="/terms">terms
                                and
                                conditions</Link>.</p>
                            <Button className="mb-7.5">Register</Button>
                            <div>
                                <p className="text-sm">
                                    Already have an account? <Link href="/login">Login</Link>.
                                </p>
                            </div>
                        </form>
                    )}
                </div>
                <div className="w-full laptop:w-1/2">
                    <Img
                        src={registerImg}
                        x2={registerImg2x}
                        webp={registerImgWebp}
                        webp2x={registerImgWebp2x}
                        alt="cushions"
                        className="w-full desktop:max-w-full object-cover h-full"
                    />
                </div>
            </div>
        </>

    )
}

Register.layout = page => <Layout children={page}/>

export default Register
