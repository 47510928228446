import React from 'react'
import { InertiaProgress } from '@inertiajs/progress'
import smoothscroll from 'smoothscroll-polyfill'
import { render } from 'react-dom'
import { createInertiaApp } from '@inertiajs/inertia-react'

smoothscroll.polyfill()
InertiaProgress.init()

createInertiaApp({
    resolve: name => require(`./Pages/${name}`),
    setup({ el, App, props }) {
        render(<App {...props} />, el)
    },
    title: title => `${title} - Vibetray`
})
