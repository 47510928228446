import React from 'react'
import Layout from "@/Components/Layouts/Layout"
import H1 from "@/Components/UI/H1"
import H5 from "@/Components/UI/H5"
import { Head } from "@inertiajs/inertia-react"

const Privacy = () => {
    return (
        <>
            <Head>
                <title>Privacy policy</title>
            </Head>
            <div className="tablet:text-center my-13">
                <H1>Privacy policy</H1>
            </div>

            <div className="max-w-160 mx-auto">
                <p className="mb-8">
                    VIBETRAY returns the trust you show in providing some of your personal data by striving to protect
                    it and keep it confidential. This Privacy Policy describes the principles followed by VIBETRAY in
                    the collection, processing and storage of your personal data and the guarantees of your rights
                    regarding this data.
                </p>
                <div className="mb-15">
                    <H5 className="mb-5">
                        1. Who is responsible for processing your data?
                    </H5>
                    <p>
                        The company VIBETRAY PORTAL EST, with registered office at W202- AL SAAHA CWing, Downtown Dubai,
                        registered with the government of Dubai under license number 1075113, is responsible for the
                        processing of personal data collected on the website www.vibetray.com.
                    </p>
                </div>
                <div className="mb-15">
                    <H5 className="mb-5">
                        2. What data do we collect?
                    </H5>
                    <p className="mb-4">
                        The term “Data” refers to any personal information that may be used to identify you as an
                        individual and that you provide to the Processing Manager to register on the Website and create
                        an account, receive information or proceed with an online order.
                        Only the data necessary for the successful execution of our activity, involving the items and
                        services offered on the Website vibetray.com.
                        The personal data we collect includes:
                    </p>
                    <ul className="mb-4">
                        <li>your details (title, surname, first name, email, telephone number, full address, etc.),</li>
                        <li>your delivery information (surname/first name, street, postcode, town/city, country,
                            delivery comments, etc.)
                        </li>
                        <li>information about the products you have viewed,</li>
                        <li>bank payment data,</li>
                        <li>your order history,</li>
                        <li>a record of emails exchanged with customer service.</li>
                    </ul>
                    <p>
                        The creation of an account on the Website requires the creation of a password. This password is
                        encrypted and is known only by you. We are not able to access it. Should you forget your
                        password, a
                        link to set a new password will be sent to your contact email.
                    </p>
                </div>

                <div className="mb-15">
                    <H5 className="mb-5">
                        3. Why do we collect your data?
                    </H5>
                    <p className="mb-4">
                        The processing of your personal data allows us to provide you with the Website’s services, to
                        improve
                        our services and to maintain a secure environment.
                        Your data is processed for the following main purpose: to carry out the transactions relating to
                        our
                        commercial relationship (orders, payment, deliveries, invoices, accounting, satisfaction
                        surveys,
                        customer service, loyalty program, etc.); And more specifically for :
                    </p>
                    <ul>
                        <li> the management of your Account, your registration and your connection to our site, as well
                            as the
                            provision of the services to which they give access respectively (online ordering, private
                            sales,
                            sending of newsletters, etc.);
                        </li>
                        <li> the management of your orders and the delivery of your orders;</li>
                        <li> the management of our customer relationship and your complaints;</li>
                        <li> the creation of a customer database for customer loyalty, prospection, promotion and market
                            research
                            activities (improving your user experience and our services), in accordance with the
                            regulations in
                            force;
                        </li>
                        <li> ensuring a personalized browsing experience on the Website: making our Website easier to
                            use and
                            adapting our products and services to your interests and needs (proposal of related products
                            based on
                            gender, record of last products viewed, personalized recommendations);
                        </li>
                        <li> statistical purposes;</li>
                        <li> Vibetray’s compliance with its legal obligations as a publisher of electronic communication
                            services
                            for the public online (preventing or detecting fraud or abuse, handling security incidents,
                            uses and
                            violations of our general terms of use, improving browsing conditions, etc.).
                        </li>
                    </ul>
                </div>

                <div className="mb-15">
                    <H5 className="mb-5">
                        4. For whom is your data intended?
                    </H5>
                    <p className="mb-4">
                        The personal data collected is processed by the in-house staff at VIBETRAY to provide you with
                        the
                        services you request and in accordance with the defined purposes.
                        VIBETRAY agrees not to sell your personal data to third parties.
                        VIBETRAY may need to transfer your personal data to certain service providers and partners that
                        help
                        VIBETRAY provide and improve its services, such as:
                    </p>
                    <ul className="mb-4">
                        <li> Logistics and delivery provider (carriers, couriers),</li>
                        <li> Technical suppliers (providers of our virtual infrastructure in the cloud)</li>
                        <li> Marketing provider (companies engaging in marketing activities [including social media
                            organizations],
                            companies specializing in market research and data processing).
                            The website never logs payment details. We don’t store or have access to your cards. We use
                            Stripe
                            checkouts that encrypts and protect your details from anyone except yourself. Please don’t
                            send your
                            payment details to us.
                        </li>
                    </ul>
                    <p>
                        When you surf and use our website. This is anonymous information that can’t be used to identify
                        you and is collected through cookies and stored in Segment.io and Hotjar. We use this
                        information to better understand how our website is being used and to discover issues and faults
                        with the website’s infrastructure. If you do not want these cookies to collect data from your
                        visit you can choose to block them in your web browser. Read more about cookies in point 8.
                    </p>
                </div>

                <div className="mb-15">
                    <H5 className="mb-5">
                        5. For how long do we keep your data?
                    </H5>
                    <p>
                        In accordance with the legislation and regulations in force, we do not keep your data beyond the
                        period
                        strictly necessary for the purposes set out above. This period varies depending on the purpose
                        of the
                        processing.
                        At the end of this retention period, your data will be permanently erased or irreversibly
                        anonymized.
                    </p>
                </div>

                <div className="mb-15">
                    <H5 className="mb-5">
                        6. What are your rights?
                    </H5>
                    <p>
                        In accordance with the applicable regulations, you have the right to query, access, modify,
                        limit,
                        rectify, delete and oppose your personal data, as well as you have the right to find out what
                        personal
                        data relating to you we have saved. You have the right to demand that incomplete, erroneous,
                        unnecessary, or out-of-date personal data be deleted or updated. You have the right to cancel
                        subscriptions for direct advertising and ask that we cease using your personal data for the
                        purpose of
                        direct marketing or on other compelling legal grounds. If you decide to cancel your subscription
                        for
                        marketing material and other communication from Vibetray, any important messages will still be
                        sent to
                        you.
                        In some cases, particularly if you want us to delete or cease processing your personal data, we
                        may no
                        longer be able to provide you with services.
                    </p>
                </div>

                <div className="mb-15">
                    <H5 className="mb-5">
                        7. How do we protect your data?
                    </H5>
                    <p>
                        We ensure the security of your personal data by providing enhanced protection, through the use
                        of
                        specific technical and organizational security measures, to prevent your personal data from
                        being used
                        illegally or fraudulently.
                        We make sure that your personal data is not disclosed, misrepresented, damaged or altered and
                        that
                        unauthorized third parties do not have access to it.
                        We train our employees and collaborators in the importance of confidentiality and personal data
                        protection. We limit the number of employees with access to your data to those who directly
                        contribute
                        to providing you with quality services.
                    </p>
                </div>

                <div className="mb-15">
                    <H5 className="mb-5">
                        8. Our cookie policy
                    </H5>
                    <p className="mb-4">
                        We might use various cookies on the Website to improve its interactivity and our services.
                    </p>
                    <H5 className="mb-5">
                        8.1 What is a cookie?
                    </H5>
                    <p className="mb-4">
                        A cookie is a file of limited size, generally consisting of letters and numbers, sent by the
                        internet
                        server to the browser’s cookie folder, located on your computer’s hard drive.
                    </p>
                    <H5 className="mb-5">
                        8.2 Which cookies do we use?
                    </H5>
                    <p className="mb-4">
                        There are various types of cookies, the purposes of each are described below.
                        Cookies strictly necessary for the site to function
                        These cookies are essential to browse our website allowing you to use its main features and
                        secure your
                        connection. Without these cookies, you will not be able to use the website normally; we advise
                        you not
                        to delete them.
                        Functionality cookies
                        These cookies are not essential for browsing but they do optimize the website operation and give
                        you
                        access to specific features. They also allow the adaptation of the site layout to your device’s
                        display
                        preferences. These cookies ensure a more customized browsing experience.
                        Analytical cookies
                        These cookies allow us to improve the relevance and ergonomics of our services through usage and
                        performance analysis of our site, to establish statistics, visit numbers and volume of use of
                        the
                        various elements of our site (content visited, routes etc…).
                    </p>
                </div>
            </div>
        </>
    )
}

Privacy.layout = page => <Layout children={page}/>

export default Privacy
