import { createContext } from "react"

export const AppContextInitialValue = {
    vibeset: undefined,
    products: [],
    activeProductSectionIndex: 0,
    activeProductCategoryIndex: 0,
    isGuestModalOpen: false,
    isErrorModalOpen: false,
    isSavedVibesetModalOpen: false,
    redirect: '',
    isMobileBillModalOpened: false,
}

export const AppContext = createContext(AppContextInitialValue)
