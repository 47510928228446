import React, { useState } from 'react'
import TextInput from "@/Components/Form/TextInput"
import { error } from "@/utils"
import Button from "@/Components/UI/Button"
import { useForm, usePage } from "@inertiajs/inertia-react"
import ActionLink from '@/Components/UI/ActionLink'
import SmallModal from '@/Components/Modals/SmallModal'
import SelectInput from "@/Components/Form/SelectInput"
import { delete_account_reasons, emirates } from "@/constants"

const DeliveryAddressForm = () => {
    const {auth} = usePage().props
    const [isSuccess, setIsSuccess] = useState(false)

    const INITIAL_VALUES = {
        delivery_address: {
            line1: auth.user.delivery_address?.line1 || '',
            line2: auth.user.delivery_address?.line2 || '',
            town: auth.user.delivery_address?.town || '',
            emirate: emirates.find(e => e === 'Dubai'),
        },
    }

    const {data, setData, post, errors, clearErrors} = useForm({...INITIAL_VALUES})

    const handleSubmit = (e) => {
        e.preventDefault()
        post('/account/profile/delivery-address', {
            preserveScroll: (page) => Object.keys(page.props.errors).length > 0,
            onSuccess: () => {
                setIsSuccess(true)
            }
        })
    }

    const reset = () => {
        setData({...INITIAL_VALUES})
        clearErrors()
    }

    const isFormEdited = data.delivery_address.line1 !== (auth.user.delivery_address?.line1 || '') ||
        data.delivery_address.line2 !== (auth.user.delivery_address?.line2 || '') ||
        data.delivery_address.town !== (auth.user.delivery_address?.town || '')

    return (
        <div className="mb-7.5">
            <h1 className="font-bold text-xl text-teal leading-normal mb-4">My delivery address</h1>
            <p className="text-base mb-5">This will be saved for your next order.</p>
            <form className="max-w-160" onSubmit={handleSubmit}>
                <div className="flex items-start flex-wrap justify-start w-full">
                    <TextInput
                        name="delivery_address.line1"
                        type="text"
                        label="Address"
                        error={error(errors, 'delivery_address.line1')}
                        className="mb-7.5 tablet:mr-7.5 w-full tablet:max-w-76 desktop:max-w-76"
                        value={data.delivery_address.line1}
                        onChange={(e) => setData('delivery_address', {
                            ...data.delivery_address,
                            line1: e.target.value
                        })}
                    />
                    <TextInput
                        name="delivery_address.line2"
                        type="text"
                        label="Apartment, suite, etc"
                        error={error(errors, 'delivery_address.line2')}
                        className="mb-7.5 w-full tablet:max-w-76 desktop:max-w-76"
                        value={data.delivery_address.line2}
                        onChange={(e) => setData('delivery_address', {
                            ...data.delivery_address,
                            line2: e.target.value
                        })}
                    />
                    <TextInput
                        name="delivery_address.town"
                        type="text"
                        label="City"
                        error={error(errors, 'delivery_address.town')}
                        className="mb-7.5 tablet:mr-7.5 w-full tablet:max-w-76 desktop:max-w-76"
                        value={data.delivery_address.town}
                        onChange={(e) => setData('delivery_address', {
                            ...data.delivery_address,
                            town: e.target.value
                        })}
                    />
                    <SelectInput
                        name="emirate"
                        type="text"
                        label="Emirate"
                        error={error(errors, 'delivery_address.emirate')}
                        className="mb-7.5 w-full tablet:max-w-76 desktop:max-w-76"
                        value={data.delivery_address.emirate}
                        disabled={true}
                        onChange={(e) => setData('delivery_address', {
                            ...data.delivery_address,
                            emirate: e.target.value
                        })}
                    >
                        <option value="" disabled hidden>Select one</option>
                        {emirates.map((emirate, index) => (
                            <option value={emirate} key={index}>{emirate}</option>
                        ))}
                    </SelectInput>
                </div>
                <div className="flex items-center justify-end">
                    {isFormEdited && (
                        <ActionLink as="button" className="mr-7.5" onClick={reset} type="button">Cancel</ActionLink>
                    )}
                    <Button
                        type="secondary"
                        buttonType="submit"
                        onClick={handleSubmit}
                        disabled={!isFormEdited}
                    >
                        Save changes
                    </Button>
                </div>
            </form>

            {/*Success modal*/}
            <SmallModal
                isModalOpen={isSuccess}
                closeModal={() => setIsSuccess(false)}
            >
                <>
                    <div className="mb-7.5">
                        <div className="text-base">
                            Your delivery address has been updated successfully.
                        </div>
                    </div>
                    <div className="flex items-center justify-end flex-wrap -mb-4">
                        <Button onClick={() => setIsSuccess(false)} className="mb-4">Got it</Button>
                    </div>
                </>
            </SmallModal>
        </div>
    )
}

export default DeliveryAddressForm
