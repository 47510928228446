// Products grouped by product sections grouped by product categories
export function getProductSectionsAndCategories(products) {
    if (!Array.isArray(products)) return []
    let product_sections = []
    for (let i = 0; i < products.length; i++) {
        // Add the product section if not already added
        if (!product_sections.some(section => section.id === products[i].product_section?.id)) {
            product_sections.push({
                ...products[i].product_section,
                product_categories: [],
            })
        }

        // Add product section categories
        product_sections.map(section => {
            // Skip section if current section is different than product section
            if (section.id !== products[i].product_section.id) {
                return section
            } else {
                // If category is already in the current section, add the product to its list of products
                if (section.product_categories.some(category => category.id === products[i].product_category.id)) {
                    section.product_categories.map(category => {
                        if (category.id === products[i].product_category.id) {
                            category.products.push(products[i])
                        }
                    })
                } else { // Add category and product to current section
                    section.product_categories.push({
                        ...products[i].product_category,
                        product_section: products[i].product_section,
                        products: [products[i]],
                    })
                }
            }
        })
    }

    // Sort product categories by ranking
    product_sections.map(section => {
        return section.product_categories.sort((a, b) => a.ranking - b.ranking)
    })

    // Sort sections by ranking
    return product_sections.sort((a, b) => a.ranking - b.ranking)
}

export function getProductCategories(products) {
    if (!Array.isArray(products)) return []
    let product_categories = []


    for (let i = 0; i < products.length; i++) {
        let category = products[i].product_category

        // Add the product category if not already added
        if (!product_categories.some(cat => cat.name === category?.name)) {
            product_categories.push({
                ...category,
                product_section: products[i].product_section,
                products: [products[i]],
            })
        } else { // Add product to existing category
            product_categories.map(c => {
                if (c.name === category.name) {
                    c.products.push(products[i])
                }
            })
        }
    }

    return product_categories
}

// From the product sections given, return the cheapest ones for each category
export function getCheapestProductsBySection(products, product_section) {
    const product_sections = getProductSectionsAndCategories(products)
    let cheapest_products = []
    for (let i = 0; i < product_sections.length; i++) {
        if (product_sections[i].id !== product_section.id) continue
        for (let j = 0; j < product_sections[i].product_categories.length; j++) {
            const cheapest_product = product_sections[i].product_categories[j].products.reduce(function(prev, curr) {
                return prev.price_including_vat * prev.pivot.is_in_stock <= curr.price_including_vat * prev.pivot.is_in_stock ? prev : curr
            })
            cheapest_products.push(cheapest_product)
        }
    }

    return cheapest_products
}

/**
 * Calculate the following totals given the products grouped by product sections and product categories:
 * totalSelectedItems: total cost for the selected items
 totalDeliveryFees: total delivery fees
 totalDispatchTime: walls and floor dispatch time + maximum of furniture and accessories dispatch time
 totalCost: totalSelectedItems + totalDeliveryFees
 * @param product_sections
 * @param site_settings
 * @return {{totalSelectedItems: number, totalDeliveryFees: number, totalDispatchTime: number, totalCost: number}}
 */
export function getTotalData(product_sections, site_settings) {
    let totalSelectedItems = 0 // Total cost of selected items (price x quantity + assembly fees)
    let totalDeliveryFees = 0 // Total cost of delivery fees
    let wallsAndFloorsDispatchTime = 0,
        furnitureDispatchTime = 0,
        accessoriesDispatchTime = 0
    let importedProductsMaxDeliveryFees = 0
    let wallPaintDeliveryFees = 0

    // Calculate costs and dispatch time
    product_sections.forEach(product_section => {
        product_section.product_categories.forEach(category => {
            const {
                totalCategory,
                totalCategoryDeliveryFees,
                maximumDispatchTimeCategory,
            } = getCategoryData(category)
            totalSelectedItems += totalCategory
            totalDeliveryFees += totalCategoryDeliveryFees
            const categoryImportedProductMaxDeliveryFees = getMaximumDeliveryFeesFromImportedProducts(category.products)
            if(categoryImportedProductMaxDeliveryFees > importedProductsMaxDeliveryFees){
                importedProductsMaxDeliveryFees = categoryImportedProductMaxDeliveryFees
            }
            if(category.name === 'Wall paint'){
                const categoryWallPaintDeliveryFees = getMaximumWallPaintDeliveryFees(category.products)
                if(categoryWallPaintDeliveryFees > wallPaintDeliveryFees){
                    wallPaintDeliveryFees = categoryWallPaintDeliveryFees
                }
            }

            if (product_section.name === 'Walls & Floors') {
                wallsAndFloorsDispatchTime = Math.max(wallsAndFloorsDispatchTime, maximumDispatchTimeCategory)
            } else if (product_section.name === 'Furniture') {
                furnitureDispatchTime = Math.max(furnitureDispatchTime, maximumDispatchTimeCategory)
            } else if (product_section.name === 'Accessories') {
                accessoriesDispatchTime = Math.max(accessoriesDispatchTime, maximumDispatchTimeCategory)
            }
        })
    })

    // Add imported products' max delivery fees
    totalDeliveryFees += importedProductsMaxDeliveryFees + wallPaintDeliveryFees

    // Maximum dispatch time walls and floors + maximum dispatch time furniture / accessories
    let totalDispatchTime = wallsAndFloorsDispatchTime + Math.max(furnitureDispatchTime, accessoriesDispatchTime)
    let totalCost = totalSelectedItems + totalDeliveryFees

    return {
        totalSelectedItems,
        totalDeliveryFees,
        totalDispatchTime,
        totalCost,
    }
}

// Return totals for given category
export function getCategoryData(category) {
    let totalCategory = 0,
        totalCategoryDeliveryFees = 0,
        maximumDispatchTimeCategory = 0,
        totalCategoryAssemblyQuantities = 0,
        totalCategoryAssemblyPrice = 0,
        totalQuantity = 0

    // Get total price
    totalCategory = category.products.reduce((sum, product) => {
        let productPrice = product.price_including_vat * product.pivot.is_in_stock
        return sum + ((Math.round(product.pivot.quantity * productPrice)) + (product.assembly_price * product.pivot.is_assembly))
    }, 0)

    // Skip delivery fees from imported products as we add them later + skip wall paint delivery fees
    totalCategoryDeliveryFees = category.products.reduce((sum, product) => {
        const productDeliveryFees = (Math.min(product.pivot.quantity, 1) * product.service_delivery_fees * product.pivot.is_in_stock)
        return (product.local_import_flag === 'I' || product.product_category.name === 'Wall paint') ? sum : sum + productDeliveryFees
    }, 0)
    maximumDispatchTimeCategory = Math.max.apply(Math, category.products.map(function(product) {
        return product.total_readiness_time * Math.min(product.pivot.quantity, 1) * product.pivot.is_in_stock
    }))

    totalCategoryAssemblyQuantities = category.products.reduce((sum, product) => sum + (product.pivot.is_assembly), 0)
    totalCategoryAssemblyPrice = category.products.reduce((sum, product) => sum + (product.pivot.is_assembly * product.assembly_price), 0)
    totalQuantity = category.products.reduce((sum, product) => sum + (product.pivot.quantity * product.pivot.is_in_stock), 0)

    return {
        totalCategory,
        totalCategoryDeliveryFees,
        maximumDispatchTimeCategory,
        totalCategoryAssemblyQuantities,
        totalCategoryAssemblyPrice,
        totalQuantity,
    }
}

// Get maximum delivery fees from imported products
export function getMaximumDeliveryFeesFromImportedProducts(products) {
    let max = 0
    for(const product of products){
        const productDeliveryFees = (Math.min(product.pivot.quantity, 1) * product.service_delivery_fees * product.pivot.is_in_stock)
        if(product.local_import_flag === 'I' && productDeliveryFees > max){
            max = productDeliveryFees
        }
    }

    return max
}

// Get maximum delivery fees wall paint products
export function getMaximumWallPaintDeliveryFees(products) {
    let max = 0
    for(const product of products){
        const productDeliveryFees = (Math.min(product.pivot.quantity, 1) * product.service_delivery_fees * product.pivot.is_in_stock)
        if(product.product_category.name === 'Wall paint' && productDeliveryFees > max){
            max = productDeliveryFees
        }
    }

    return max
}

// Get product quantity given product type (can be sold by piece, sqm, roll), room, site_settings
// export function getProductQuantity(products, product, room, site_settings) {
//     let product_quantity = 1
//     const wasteAmount = 1.1
//
//     const {
//         wallPaintSurface,
//         wallpaperSurface,
//         floorSurface,
//         windowsSurface,
//     } = getSurfaces(products, room, site_settings)
//
//     if (product.product_category.name === "Wallpaper") {
//         // Wallpaper per sqm
//         if (product.price_selling_unit === 'sqm') {
//             product_quantity = wallpaperSurface * wasteAmount
//         }
//
//         // Wallpaper per roll
//         if (product.price_selling_unit === 'roll') {
//             product_quantity = Math.ceil((wallpaperSurface * wasteAmount) / ((product.width * product.height) / 10000))
//         }
//     } else if (product.product_category.name === "Wall paint") {
//         product_quantity = wallPaintSurface * wasteAmount
//     } else if (product.product_category.name === "Ceiling" || product.product_category.name === "Floors") {
//         product_quantity = floorSurface * wasteAmount
//     } else if (product.product_category.name === "Curtains" && product.price_selling_unit === 'sqm') {
//         product_quantity = windowsSurface
//     }
//
//     return Number(product_quantity.toFixed(2))
// }

// Calculate wall paint surface, wallpaper surface, floor surface, windows surface
// export function getSurfaces(products, room) {
//     let wallPaintSurface = 0, wallpaperSurface = 0, floorSurface = 0, windowsSurface = 0
//
//     wallPaintSurface = getWallPaintSurface(room)
//     wallpaperSurface = getWallPaperSurface(room)
//     floorSurface = getFloorSurface(room)
//     windowsSurface = getWindowsSurface(room)
//
//     return {
//         wallpaperSurface,
//         wallPaintSurface,
//         floorSurface,
//         windowsSurface,
//     }
// }

export function getFloorSurface(room) {
    let floorSurface = 0

    if (room?.shapes?.length === 0) return null

    for (let i = 0; i < room?.shapes.length; i++) {
        switch (room?.shapes[i].type) {
            case 'rectangle':
                floorSurface += room?.shapes[i].width * room?.shapes[i].height
                break
            case 'triangle':
                floorSurface += (room?.shapes[i].base * room?.shapes[i].hauteur) / 2
                break
            case 'trapezium':
                floorSurface += ((room?.shapes[i].top + room?.shapes[i].base) * room?.shapes[i].hauteur) / 2
                break
            case 'wedge':
                floorSurface += (Math.PI * room?.shapes[i].rayon * (room?.shapes[i].diametre / 2)) / 2
        }
    }

    return floorSurface === 0 ? null : floorSurface / 10000 // Convert cm2 to m2
}

export function getWallPaintSurface(room) {
    let wallPaintSurface = 0

    if (room?.wallHeight === '' || room?.shapes?.length === 0) return null

    // Surface of all walls
    for (let i = 0; i < room?.shapes.length; i++) {
        switch (room?.shapes[i].type) {
            case 'rectangle':
                wallPaintSurface += (room?.shapes[i].width * room?.wallHeight * 2) + (room?.shapes[i].height * room?.wallHeight * 2)
                break
            case 'triangle':
                wallPaintSurface += (room?.shapes[i].base * room?.wallHeight) + (room?.shapes[i].sidesLength * room?.wallHeight * 2)
                break
            case 'trapezium':
                wallPaintSurface += (room?.shapes[i].base * room?.wallHeight) + (room?.shapes[i].sidesLength * room?.wallHeight * 2) + (room?.shapes[i].top * room?.wallHeight)
                break
            case 'wedge':
                wallPaintSurface += (room?.shapes[i].diametre * room?.wallHeight) + (Math.PI * Math.sqrt((Math.pow(room?.shapes[i].rayon, 2) + Math.pow(room?.shapes[i].diametre / 2, 2)) / 2)) * room?.wallHeight
        }
    }

    // Minus wallpaper surface
    const wallPaperSurface = getWallPaperSurface(room, false)
    wallPaintSurface -= wallPaperSurface

    wallPaintSurface = Math.max(wallPaintSurface, 0)

    return wallPaintSurface === 0 ? null : wallPaintSurface / 10000 // Convert cm2 to m2
}

// Convert cm2 to m2 if convert is true
export function getWallPaperSurface(room, convert = true) {
    let wallPaperSurface = 0
    if (room?.wallpapers?.length === 0) return null
    for (let i = 0; i < room?.wallpapers.length; i++) {
        wallPaperSurface += room?.wallpapers[i].width * room?.wallpapers[i].height
    }
    return convert ? wallPaperSurface / 10000 : wallPaperSurface
}

// Convert cm2 to m2 if convert is true
export function getWindowsSurface(room, convert = true) {
    let windowsSurface = 0
    if (room?.windows?.length === 0) return null
    for (let i = 0; i < room?.windows.length; i++) {
        windowsSurface += room?.windows[i].width * room?.windows[i].height
    }
    return convert ? windowsSurface / 10000 : windowsSurface
}


export function getWallsCategory(product_section) {
    let wallsCategory, totalWallsCategory = 0

    const wallPaintCategory = product_section.product_categories.find(c => c.name === 'Wall paint')
    const wallpaperCategory = product_section.product_categories.find(c => c.name === 'Wallpaper')

    wallsCategory = (wallpaperCategory || wallPaintCategory) ? {
        name: 'Walls',
    } : null

    if (wallPaintCategory) {
        const {totalCategory} = getCategoryData(wallPaintCategory)
        totalWallsCategory += totalCategory
    }

    if (wallpaperCategory) {
        const {totalCategory} = getCategoryData(wallpaperCategory)
        totalWallsCategory += totalCategory
    }

    return {wallsCategory, totalWallsCategory}
}

// Get total data for given section
export function getSectionData(product_section, site_settings, room) {
    let totalSection = 0 // Total cost for given section
    let totalAssemblyQuantities = 0 // Number of items to assemble
    let totalAssemblyPrice = 0 // Total cost of assembly fees
    let isWallsSelected = false

    if (!product_section) {
        return {
            totalSection,
            totalAssemblyPrice,
            totalAssemblyQuantities,
            isWallsSelected,
        }
    }

    const {wallsCategory, totalWallsCategory} = getWallsCategory(product_section, site_settings, room)

    isWallsSelected = totalWallsCategory > 0

    // Remove Wall paint and wallpaper categories
    const categories = product_section.product_categories.slice().filter(c => c.name !== 'Wall paint' && c.name !== 'Wallpaper')

    categories.forEach(category => {
        const {
            totalCategory,
            totalCategoryAssemblyPrice,
            totalCategoryAssemblyQuantities,
        } = getCategoryData(category)
        totalSection += totalCategory
        totalAssemblyQuantities += totalCategoryAssemblyQuantities
        totalAssemblyPrice += totalCategoryAssemblyPrice
    })

    totalSection += totalWallsCategory

    return {
        totalSection,
        totalAssemblyPrice,
        totalAssemblyQuantities,
        isWallsSelected,
        wallsCategory,
        totalWallsCategory,
        categories,
    }
}

export const DEFAULT_USER_DIMENSIONS = {
    room: '',
    wallpapers: [],
    walls_painted: [],
    curtains: [],
    floors: [],
}

// Get wallpaper quantity on m2 or number of roll if product is sold per roll
// When selecting a room size, we assume there are 4 walls with a height of 3 meters
export function getWallpaperQuantity(product, userDimensions) {
    let quantity = 0

    let wallpaperSurface = 0
    if (userDimensions.room === 'small') { // Select a room size (small) (10m2)
        wallpaperSurface = Math.sqrt(10) * 4 * 3 * 10000
    } else if (userDimensions.room === 'medium') { // Select a room size (medium) (15m2)
        wallpaperSurface = Math.sqrt(15) * 4 * 3 * 10000
    } else if (userDimensions.room === 'large') { // Select a room size (large) (25m2)
        wallpaperSurface = Math.sqrt(25) * 4 * 3 * 10000
    } else { // User entered walls manually (in cm)
        for (let i = 0; i < userDimensions?.wallpapers.length; i++) {
            wallpaperSurface += userDimensions?.wallpapers[i].width * userDimensions?.wallpapers[i].height
        }
    }

    // Wallpaper per SQM
    if (product.price_selling_unit === 'sqm') {
        quantity = Math.ceil(wallpaperSurface / 10000) // surface in m2 rounded up
    }

    // Wallpaper per roll
    if (product.price_selling_unit === 'roll' && product.width && product.height) {
        quantity = Math.ceil(wallpaperSurface / (product.width * product.height))
    }

    return Math.ceil(quantity)
}

// Get floor quantity on m2
// When selecting a room size, we assume there are 4 walls with a height of 3 meters
export function getFloorQuantity(product, userDimensions) {
    let quantity = 0

    let floorSurface = 0
    if (userDimensions.room === 'small') { // Select a room size (small) (10m2)
        floorSurface = 10 * 10000
    } else if (userDimensions.room === 'medium') { // Select a room size (medium) (15m2)
        floorSurface = 15 * 10000
    } else if (userDimensions.room === 'large') { // Select a room size (large) (25m2)
        floorSurface = 25 * 10000
    } else { // User entered floors manually (in cm)
        for (let i = 0; i < userDimensions?.floors.length; i++) {
            floorSurface += userDimensions?.floors[i].width * userDimensions?.floors[i].length
        }
    }

    // Wallpaper per SQM
    quantity = Math.ceil(floorSurface / 10000) // surface in m2 rounded up

    return Math.ceil(quantity)
}

// Returns wall paint quantity in m2
export function getWallPaintQuantity(userDimensions) {
    let quantity = 0

    let wallsSurface = 0
    if (userDimensions.room === 'small') { // Select a room size (small)
        wallsSurface = Math.sqrt(10) * 4 * 3 * 10000
    } else if (userDimensions.room === 'medium') { // Select a room size (medium)
        wallsSurface = Math.sqrt(15) * 4 * 3 * 10000
    } else if (userDimensions.room === 'large') { // Select a room size (large)
        wallsSurface = Math.sqrt(25) * 4 * 3 * 10000
    } else { // User entered walls manually
        for (let i = 0; i < userDimensions?.walls_painted.length; i++) {
            wallsSurface += userDimensions?.walls_painted[i].width * userDimensions?.walls_painted[i].height
        }
    }

    quantity = Math.ceil(wallsSurface / 10000) // surface in m2 rounded up
    return Math.ceil(quantity)
}

// Returns curtains quantity in m2
export function getCurtainsQuantity(product, userDimensions) {
    let quantity = 0

    for(const curtain of userDimensions.curtains){
        // Add 40 cm for sides for each opening
        const openingWidth = Number(curtain.width) + 40;
        const productWidth = Math.ceil((openingWidth * 2.2) / product.width) * 100

        // Add 20 cm on top on opening, unless ceiling is too close to opening
        const openingHeight = curtain.wallHeight - curtain.height < 30 ? curtain.wallHeight : curtain.height + 20
        const productHeight = Number(openingHeight)

        quantity += (productWidth * productHeight)
    }

    // Curtains surface in m2
    quantity = Math.ceil(quantity / 10000)
    return quantity
}

export function getProductPrice(product) {
    const productPrice = product.price_including_vat * product.pivot.is_in_stock
    return ((Math.round(product.pivot.quantity * productPrice)) + (product.assembly_price * product.pivot.is_assembly))
}

export function getQuantitySuffix(price_selling_unit) {
    if (price_selling_unit === 'sqm') return 'm<sup>2</sup>'
    if (price_selling_unit === 'roll') return ' rolls'
    return ''
}

export function getCustomisationPanelContainerTopOffset() {
    const bodyRect = document.body.getBoundingClientRect()
    const customisationPanelContainerRect = document.getElementById('customisationPanelContainer')?.getBoundingClientRect()
    const navHeight = document.getElementById('nav')?.clientHeight
    return customisationPanelContainerRect?.top - bodyRect?.top - navHeight
}
