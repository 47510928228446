import React from 'react'
import AccountMenu from "@/Components/AccountMenu"
import { InertiaLink } from "@inertiajs/inertia-react"
import { LeftArrowIcon } from "@/Components/Icons"

const AccountLayout = ({children}) => {
    return (
        <div className="mt-5 navbar:mt-15 flex flex-wrap">
            <div
                className="w-full hidden laptop:block laptop:w-52 navbar:w-59 flex-shrink-0 laptop:mr-8 navbar:mr-18 laptop:mb-0 mb-5 relative">
                <AccountMenu/>
            </div>
            <div className="flex-1">
                <InertiaLink href="/account"
                             className={`inline-flex items-center text-base text-gray-dark hover:text-teal laptop:hidden mb-5`}>
                    <LeftArrowIcon className="stroke-current mr-2.5"/>
                    Go back
                </InertiaLink>
                {children}
            </div>
        </div>
    )
}

export default AccountLayout
