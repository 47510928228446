import React from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import VibesetBill from "@/Components/Vibeset/VibesetBill/VibesetBill"

Modal.setAppElement('#app')

const BillSummaryModal = (props) => {
    const {isModalOpen, closeModal, set} = props

    if (!set) return null

    return (
        <Modal
            id="bill-summary-modal"
            overlayClassName="justify-end"
            isOpen={isModalOpen}
            onRequestClose={closeModal}
            contentLabel="Bill summary"
        >
            <div className="relative h-full">
                <VibesetBill
                    products={set.products}
                    is_summary={true}
                    is_read_only={true}
                />
            </div>
        </Modal>
    )
}


BillSummaryModal.propTypes = {
    isModalOpen: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    set: PropTypes.shape({}), // Saved vibeset or order (containing products)
}

export default BillSummaryModal
