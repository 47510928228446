import React from 'react'
import Layout from "@/Components/Layouts/Layout"
import { usePage } from "@inertiajs/inertia-react"
import VibesetTemplate from "@/Components/Vibeset/VibesetTemplate"

const SavedVibeset = () => {
    const {vibeset, savedVibeset} = usePage().props

    return (
        <VibesetTemplate
            vibeset={vibeset}
            products={savedVibeset.products}
        />
    )
}

SavedVibeset.layout = page => <Layout children={page} showFooter={false}/>

export default SavedVibeset
