import React, { useRef, useState } from 'react'
import Label from "@/Components/Form/Label"
import { HidePasswordIcon, ShowPasswordInputIcon } from "@/Components/Icons"

const PasswordInput = ({label, name, className, error, ...props}) => {
    const input = useRef()
    const [isPasswordShown, setIsPasswordShown] = useState(false)

    // Show / hide password
    const handleIconClick = (e) => {
        e.preventDefault()
        if (!input.current) return
        setIsPasswordShown(!isPasswordShown)
    }

    return (
        <div className={className}>
            {label && (
                <Label name={name}>
                    {label}
                </Label>
            )}
            <div className="relative tablet:max-w-xs desktop:max-w-input">
                <input
                    ref={input}
                    id={name}
                    name={name}
                    type={isPasswordShown ? 'text' : 'password'}
                    {...props}
                    className={`px-2.5 h-8.5 leading-normal block w-full border border-gray-dark focus:border-teal focus:border focus:ring-0 placeholder-gray-dark text-black bg-white font-sans text-left appearance-none relative ${error ? 'border-red' : ''}`}
                />
                <button
                    className="absolute inset-y-0 right-0 pr-4 flex items-center text-gray-dark focus:outline-none focus:ring-0"
                    tabIndex={-1}
                    onClick={handleIconClick}
                    type="button"
                >
                    {isPasswordShown ? (
                        <HidePasswordIcon className="pointer-events-none"/>
                    ) : (
                        <ShowPasswordInputIcon className="pointer-events-none"/>
                    )}
                </button>
            </div>
            {error}
        </div>
    )
}

export default PasswordInput
