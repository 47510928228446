import React, { useEffect, useState } from 'react'
import Layout from "@/Components/Layouts/Layout"
import H1 from "@/Components/UI/H1"
import TextInput from "@/Components/Form/TextInput"
import Button from "@/Components/UI/Button"
import { Head, usePage } from "@inertiajs/inertia-react"
import { error } from "@/utils"
import { Inertia } from '@inertiajs/inertia'
import Img from "@/Components/UI/Img"
import getInTouchImage from "@/assets/get_in_touch_page/get_in_touch_page.jpg"
import getInTouchImage2x from "@/assets/get_in_touch_page/get_in_touch_page@2x.jpg"
import getInTouchImageWebp from "@/assets/get_in_touch_page/get_in_touch_page.webp"
import getInTouchImageWebp2x from "@/assets/get_in_touch_page/get_in_touch_page@2x.webp"
import Link from "@/Components/UI/Link"
import { AddressIcon, EmailIcon, PhoneIcon } from '@/Components/Icons';
import TextAreaInput from "@/Components/Form/TextAreaInput"
import FileInput from "@/Components/Form/FileInput"
import FormError from "@/Components/Form/FormError"

const GetInTouch = () => {
    const {flash, errors} = usePage().props

    const initialFormValues = {
        name: '',
        email: '',
        subject: '',
        order: '',
        message: '',
        files: [],
    }

    const [values, setValues] = useState(initialFormValues)

    // Reset form on success
    useEffect(() => {
        if (flash.success) {
            setValues(initialFormValues)
        }
    }, [flash])

    const handleChange = (e) => {
        const key = e.target.id
        const value = e.target.value
        setValues(values => ({
            ...values,
            [key]: value,
        }))
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const data = new FormData()
        data.append('name', values.name || '')
        data.append('email', values.email || '')
        data.append('subject', values.subject || '')
        data.append('order', values.order || '')
        data.append('message', values.message || '')
        for (let x = 0; x < values.files.length; x++) {
            data.append("files[]", values.files[x])
        }
        Inertia.post('/get-in-touch', data, {preserveScroll: true})
    }

    return (
        <>
            <Head>
                <title>Get in touch</title>
            </Head>
            <div className="text-center pt-13">
                <H1 className="mb-10">Get in touch</H1>
                <p className="text-center max-w-input mx-auto mb-15">Already checked our <Link href="/faq"
                                                                                               className="text-base">FAQs
                    page</Link> but still can’t find the
                    answer you’re looking for? Drop us a line and we will get back to you as soon as possible.</p>
                <div className="flex flex-wrap justify-center mb-15">
                    <div
                        className="flex justify-center w-full laptop:w-1/2 laptop:flex laptop:flex-col laptop:items-end laptop:justify-start laptop:pr-19">
                        <div className="flex flex-col items-start text-gold">
                            <div>
                                <div className="flex items-center">
                                    <EmailIcon className="mr-2.5"/>
                                    <a href="mailto:customerservice@vibetray.com"
                                       className="text-gold text-base leading-normal font-semibold" target="_blank"
                                       rel="noopener">customerservice@vibetray.com</a>
                                </div>
                                <div className="flex items-center">
                                    <PhoneIcon className="mr-2.5"/>
                                    <a href="tel:0559532920"
                                       className="text-gold text-base leading-normal font-semibold" target="_blank"
                                       rel="noopener">055-9532920</a>
                                </div>
                            </div>
                            <div className="flex laptop:hidden">
                                <AddressIcon className="mr-2.5"/>
                                <address
                                    className="text-gold text-base leading-normal font-semibold not-italic text-left">
                                    Downtown Dubai,<br/>
                                    AL SAAHA CWing-W202
                                </address>
                            </div>
                        </div>
                    </div>
                    <div className="w-full laptop:w-1/2 laptop:pl-19 hidden laptop:block">
                        <div className="flex">
                            <AddressIcon className="mr-2.5"/>
                            <address className="text-gold text-base leading-normal font-semibold not-italic text-left">
                                Downtown Dubai,<br/>
                                AL SAAHA CWing-W202
                            </address>
                        </div>
                    </div>
                </div>
            </div>

            <div className="w-screen laptop:w-full flex flex-wrap -mx-4 tablet:-mx-8 laptop:mx-0">
                <div
                    className="bg-gray-light py-7.5 px-4 w-full laptop:w-1/2 laptop:py-8 desktop:py-15 flex items-center">
                    <form className="mx-auto tablet:max-w-xs desktop:max-w-input w-full" onSubmit={handleSubmit}>
                        <TextInput
                            label="Name*"
                            name="name"
                            type="text"
                            className="mb-7.5"
                            value={values.name}
                            error={error(errors, 'name')}
                            onChange={handleChange}
                        />
                        <TextInput
                            label="Email address*"
                            name="email"
                            type="email"
                            className="mb-7.5"
                            value={values.email}
                            error={error(errors, 'email')}
                            onChange={handleChange}
                        />
                        <TextInput
                            label="Subject*"
                            name="subject"
                            type="text"
                            className="mb-7.5"
                            value={values.subject}
                            error={error(errors, 'subject')}
                            onChange={handleChange}
                        />
                        <TextInput
                            label="Order number (optional)"
                            name="order"
                            type="text"
                            className="mb-7.5"
                            value={values.order}
                            error={error(errors, 'order')}
                            onChange={handleChange}
                        />
                        <TextAreaInput
                            label="Message*"
                            name="message"
                            className="mb-7.5"
                            value={values.message}
                            error={error(errors, 'message')}
                            onChange={handleChange}
                        />

                        <FileInput
                            label="Attach files"
                            name="files"
                            className="mb-7.5"
                            files={values.files}
                            setFiles={files => {
                                setValues({
                                    ...values,
                                    files: files,
                                })
                            }}
                            error={error(errors, 'files') || error(errors, 'files.0') || error(errors, 'files.1') || error(errors, 'files.2') || error(errors, 'files.3') || error(errors, 'files.4')}
                            accept="image/*"
                            multiple
                        />
                        {!flash.success && !flash.error && (
                            <Button onClick={handleSubmit}>Send enquiry</Button>
                        )}
                        {flash.success && (
                            <p className="mt-7.5">{flash.success}</p>
                        )}

                        {flash.error && (
                            <FormError>{flash.error}</FormError>
                        )}
                    </form>
                </div>
                <div className="w-full laptop:w-1/2">
                    <Img
                        src={getInTouchImage}
                        x2={getInTouchImage2x}
                        webp={getInTouchImageWebp}
                        webp2x={getInTouchImageWebp2x}
                        alt="fashion watch and keyboard"
                        className="w-full desktop:max-w-full object-cover h-full"
                    />
                </div>
            </div>
        </>

    )
}

GetInTouch.layout = page => <Layout children={page}/>

export default GetInTouch
