import React, { useContext } from 'react'
import { AppContext } from '@/Contexts/app-context'
import Link from '@/Components/UI/Link'
import { getCustomisationPanelContainerTopOffset, getProductSectionsAndCategories } from '@/Components/Vibeset/utils'
import BillProduct from '@/Components/Vibeset/VibesetBill/BillProduct'
import ActionLink from "@/Components/UI/ActionLink"

const BillProductSection = ({product_section, products, is_summary, is_read_only, handleSaveVibeset}) => {
    const {appContext, setAppContext} = useContext(AppContext)
    const product_sections = getProductSectionsAndCategories(products)
    const selectedProducts = products.filter(p => p.pivot.quantity > 0 && p.pivot.is_in_stock && p.product_section.id === product_section.id)
    const unselectedCategories = product_section.product_categories.filter(category => {
        return !category.products.some(p => p.pivot.quantity > 0)
    })

    const handleSelectProductCategory = (product_category_id) => {
        const product_section_index = product_sections.findIndex(p => p.id === product_section.id)
        let product_category_index = product_section.product_categories.findIndex(c => c.id === product_category_id)

        setAppContext({
            ...appContext,
            activeProductSectionIndex: product_section_index,
            activeProductCategoryIndex: product_category_index,
            isMobileBillModalOpened: false,
        })

        window.scrollTo({
            top: getCustomisationPanelContainerTopOffset(),
            left: 0,
            behavior: 'smooth',
        })
    }

    const handleSelectProductionSection = () => {
        const product_section_index = product_sections.findIndex(p => p.id === product_section.id)
        setAppContext({
            ...appContext,
            activeProductSectionIndex: product_section_index,
            activeProductCategoryIndex: 0,
            isMobileBillModalOpened: false,
        })

        window.scrollTo({
            top: getCustomisationPanelContainerTopOffset(),
            left: 0,
            behavior: 'smooth',
        })
    }

    return (
        <div className="mb-5 border-b border-gray-medium">
            <ActionLink
                className={`text-lg font-bold flex-grow mb-1 !text-teal-dark ${!is_summary ? 'underline' : ''}`}
                as="button"
                onClick={handleSelectProductionSection}
                disabled={is_summary}
            >
                {product_section.name}
            </ActionLink>
            <div className="grid grid-cols-1 divide-y divide-gray-medium">
                {selectedProducts.map((product) => (
                    <BillProduct
                        key={`bill-product-${product.id}`}
                        product={product}
                        is_summary={is_summary}
                        is_read_only={is_read_only}
                        handleSaveVibeset={handleSaveVibeset}
                    />
                ))}
                {!is_summary && unselectedCategories.map((category) => (
                    <div className="flex items-center justify-between py-4" key={`bill-category-${category.id}`}>
                        <span className="text-gray-dark text-sm font-bold">{category.name}</span>
                        <Link as="button" onClick={() => handleSelectProductCategory(category.id)}>Select items</Link>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default BillProductSection
