import React from 'react'
import { InertiaLink } from "@inertiajs/inertia-react"
import { ConfirmationIcon, CustomisationIcon, ProfileIcon, RightArrowIcon } from "@/Components/Icons"

const AccountMenu = () => {

    const NavigationButton = ({children, href, isActive}) => (
        <InertiaLink
            href={href}
            className={`flex items-center w-full hover:text-teal font-semibold py-4 border-b border-gray-light ${isActive ? 'text-teal' : 'text-gray-dark'}`}>
            {children}
        </InertiaLink>
    )

    return (
        <div className="laptop:sticky laptop:top-24 navbar:top-34">
            <div className="font-bold text-base pb-4 border-b border-gray-light">Your account</div>
            <NavigationButton
                href="/account/orders"
                isActive={location.pathname === '/account/orders'}
            >
                <ConfirmationIcon className="stroke-current mr-4"/>
                <div>Orders</div>
                <RightArrowIcon className="stroke-current ml-auto"/>
            </NavigationButton>
            <NavigationButton
                href="/account/saved-vibesets"
                isActive={location.pathname === '/account/saved-vibesets'}
            >
                <CustomisationIcon className="stroke-current mr-4"/>
                <div>Saved vibesets</div>
                <RightArrowIcon className="stroke-current ml-auto"/>
            </NavigationButton>
            <NavigationButton
                href="/account/profile"
                isActive={location.pathname === '/account/profile'}
            >
                <ProfileIcon className="stroke-current mr-4"/>
                <div>Profile</div>
                <RightArrowIcon className="stroke-current ml-auto"/>
            </NavigationButton>
            <InertiaLink
                href="/logout"
                method="post"
                as="button"
                className={`mt-2 inline-block text-sm underline text-black hover:text-teal`}
            >
                Sign out
            </InertiaLink>
        </div>
    )
}

export default AccountMenu
