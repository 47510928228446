import React from "react";
import Modal from "react-modal";
import { CloseIcon } from "@/Components/Icons";

Modal.setAppElement('#app')

const HowItWorksModal = (props) => {
    const {isModalOpen, closeModal} = props

    return (
        <Modal
            id="how-it-works-modal"
            isOpen={isModalOpen}
            onRequestClose={closeModal}
            contentLabel="Small modal"
        >
            {closeModal && (
                <button
                    className="inline-block text-black z-10 hover:text-gray-dark  focus:ring-0 absolute"
                    onClick={closeModal}
                    style={{
                        top: 15,
                        right: 15,
                    }}
                >
                    <CloseIcon className="stroke-current"/>
                </button>
            )}
            <video
                autoPlay
                src="./how-it-works-home.mp4"
                controls
                className="w-full h-auto"
            />
        </Modal>
    )
}

export default HowItWorksModal
